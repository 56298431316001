import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Price } from 'dd-client/site/common/components/Price';
import { Tooltip } from 'dd-client/site/common/components/Tooltip';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './Variant.scss';

const VARIANT_IMAGE_SIZE = {
  height: 0,
  width: 96,
};

const Variant: Component = ({
  calculatedDiscount,
  className,
  image,
  isReserved,
  isSelected,
  isSoldOut,
  onClick,
  originalPrice,
  specialPrice,
  name,
}: Props): ReactElement => {
  const isSoldOutOrReserved = isSoldOut || isReserved;
  const { t } = useTranslation();
  const urlCdn = getConfig('url.cdn');

  const rootClassName = classNames(
    'Variant',
    {
      'Variant--Selected': isSelected,
      'Variant--SoldOutOrReserved': isSoldOutOrReserved,
      'Variant--WithoutImage': !image,
    },
    className,
  );

  const text = useMemo(
    () => ({
      reserved: t('Reserved'),
      soldOut: t('Sold out'),
    }),
    [t],
  );

  return (
    <>
      <div
        className={rootClassName}
        data-testid="variant-item"
        data-tooltip-id={isSoldOutOrReserved ? name : undefined}
        onClick={!isSoldOutOrReserved ? onClick : undefined}
      >
        {image && (
          <div className="Variant-Image">
            <img
              alt={name}
              src={`${urlCdn}/${VARIANT_IMAGE_SIZE.width}x${VARIANT_IMAGE_SIZE.height}/${image}`}
              srcSet={`${urlCdn}/${VARIANT_IMAGE_SIZE.width * 2}x${VARIANT_IMAGE_SIZE.height * 2}/${image} 2x`}
            />
          </div>
        )}

        <div className="Variant-Name">
          {name}
        </div>

        <Price
          className="Variant-Price"
          discount={calculatedDiscount}
          oldPrice={originalPrice}
          price={specialPrice}
          styleType={Price.StyleType.VARIANT}
        />
      </div>

      {isSoldOutOrReserved && (
        <Tooltip
          id={name}
          place={Tooltip.Place.BOTTOM}
          text={
            isSoldOut
              ? text.soldOut
              : text.reserved
          }
        />
      )}
    </>
  );
};

export {
  Variant,
};
