import { Control, useController } from 'react-hook-form';
import { UseControllerReturn } from 'react-hook-form/dist/types';

interface UseFormFieldObjectArgument {
  control: Control;
  defaultValue?: unknown;
  isRequired?: boolean;
  maxLength?: number;
  name: string;
  validationPattern?: RegExp;
}

const useFormField = ({
  control,
  defaultValue,
  isRequired,
  maxLength,
  name,
  validationPattern,
}: UseFormFieldObjectArgument): UseControllerReturn => (
  useController({
    control,
    defaultValue: defaultValue || '',
    name,
    rules: {
      maxLength: maxLength,
      pattern: validationPattern,
      required: isRequired,
    },
  })
);

export {
  useFormField,
};
