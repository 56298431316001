import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertWarningIcon from 'dd-client/site/common/components/Alert/assets/alert-warning.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { Component } from './types';
import '../common.scss';
import './NewsletterErrorPage.scss';

const NewsletterErrorPage: Component = (): ReactElement => {
  const { t } = useTranslation();
  const goToHomePage = useGoToPage();

  const text = useMemo(
    () => ({
      errorSubmitting: t('Error submitting the form'),
      goToHomepage: t('Go to homepage'),
      pleaseCheck: t('Please check your entry, do not use special characters or try again later.'),
    }),
    [t],
  );

  return (
    <div className="container NewsletterPage">
      <AlertWarningIcon className="NewsletterPage-Icon NewsletterErrorPage-Icon"/>

      <h1 className="NewsletterErrorPage-Title">
        {text.errorSubmitting}
      </h1>

      <p className="NewsletterErrorPage-Text">
        {text.pleaseCheck}
      </p>

      <Button
        className="NewsletterPage-Button"
        onClick={goToHomePage}
      >
        {text.goToHomepage}
      </Button>
    </div>
  );
};

export {
  NewsletterErrorPage,
};
