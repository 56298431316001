import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { isExternalUrl } from 'dd-client/site/routes/utils/url';
import { Component, Props } from './types';
import './Link.scss';

const Link: Component = ({
  children,
  className,
  isSelected,
  onClick,
  rel,
  target,
  url,
}: Props) => {
  const rootClassName = classNames(
    'Link',
    {
      'Link--Selected': isSelected,
    },
    className,
  );
  const history = useHistory();
  const { i18n: { language } } = useTranslation();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    onClick?.();
    if (!isExternalUrl(url)) {
      e.preventDefault();
      window.scrollTo(0, 0);
      history.push(`/${language}${url}`);
    }
  };

  return (
    <a
      href={(
        isExternalUrl(url)
          ? url
          : `/${language}${url}`
      )}
      className={rootClassName}
      onClick={handleClick}
      suppressHydrationWarning
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};

export {
  Link,
};
