import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { FooterTop } from 'dd-client/site/app/components/App/Footer/FooterTop';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { isErrorPage as isErrorPageAtom } from 'dd-client/site/common/utils/global';
import { FooterBottom } from './FooterBottom';
import { Component } from './types';
import './Footer.scss';

const Footer: Component = (): ReactElement => {
  const {
    isHomePage,
    isNewsPage,
    isPastDealsPage,
  } = usePageMatch();
  const isErrorPage = useRecoilValue(isErrorPageAtom);

  const rootClassName = classNames(
    'Footer',
    {
      'Footer--HomePage': isHomePage,
      'Footer--White': isPastDealsPage || isNewsPage || isErrorPage,
    },
  );

  return (
    <footer
      className={rootClassName}
      data-testid="footer-wrapper"
    >
      <div className="container">
        <FooterTop />
        <FooterBottom />
      </div>
    </footer>
  );
};

export {
  Footer,
};
