const getVideoImage = (videoId: string): string => (
  `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
);

const getVideoLink = (videoId: string): string => (
  `https://www.youtube.com/embed/${videoId}`
);

export {
  getVideoImage,
  getVideoLink,
};
