import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { Slider } from 'dd-client/site/common/components/Slider';
import { BREAKPOINTS } from 'dd-client/site/common/utils/breakpoints';
import { ItemSkeleton } from 'dd-client/site/deal/components/DealPage/News/ItemSkeleton';
import { NewsType, useNews } from 'dd-client/site/news/hooks/useNews';
import { Item } from './Item';
import { Component, Props } from './types';
import './News.scss';

const News: Component = ({
  className,
  categoryId,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const news = useNews(NewsType.CATEGORY, categoryId);

  const text = useMemo(
    () => ({
      couldNotLoadNews: t('Couldn\'t load news content'),
      news: t('News'),
      retry: t('Retry'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'News',
    className,
  );

  const emptySlides = useMemo(
    () => (
      [0, 1, 2].map(
        item => (
          <ItemSkeleton key={item} />
        ),
      )
    ),
    [],
  );

  const handleRetryButtonClick = useCallback(
    () => news.refetch(),
    [news],
  );

  if (news.isSuccess && news.data.pages[0].totalCount === 0) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <div className="container">
        <div className="SectionTitle News-Title">
          {text.news}
        </div>

        {news.isError && (
          <Alert
            className="News-Alert"
            isVisible
            styleType={Alert.StyleType.WARNING}
          >
            {text.couldNotLoadNews}

            <Button
              className="News-RetryButton"
              styleType={Button.StyleType.GHOST}
              onClick={handleRetryButtonClick}
            >
              {text.retry}
            </Button>
          </Alert>
        )}

        {(news.isSuccess || news.isPending) && (
          <Slider
            breakpoints={{
              [BREAKPOINTS.md]: {
                slidesPerView: 'auto',
                spaceBetween: 24,
              },
              [BREAKPOINTS.lg]: {
                slidesPerView: 2,
                spaceBetween: 32,
              },
            }}
            slides={news.isSuccess
              ? news.data.pages.map(
                newsGroup => (
                  newsGroup.results.map(
                    ({ imageUrl, title, headline, url, id }) => (
                      <Item
                        imageUrl={imageUrl}
                        key={id}
                        title={title}
                        headline={headline}
                        url={url}
                      />
                    ),
                  )
                ),
              ).flat()
              : emptySlides}
          />
        )}
      </div>
    </div>
  );
};

export {
  News,
};
