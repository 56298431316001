import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { ANALYTICS_META_TITLE } from 'dd-client/site/analytics/hooks/usePageViewAnalytics';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './';

const Head: Component = ({
  analytics,
  description,
  ogTags,
  title,
}: Props): ReactElement => (
  // @ts-ignore - bug in react helmet types: https://github.com/staylor/react-helmet-async/issues/139
  <Helmet>
    <title>{title}</title>

    {description && (
      <meta
        content={description}
        name="description"
      />
    )}

    {ogTags && (
      // array added specially instead of fragment because of issue in react-helmet-async: https://github.com/staylor/react-helmet-async/issues/76*/
      [
        <meta property="og:title" content={ogTags.title} key={ogTags.title} />,
        <meta property="og:url" content={ogTags.url} key={ogTags.url} />,
        ogTags.image && <meta property="og:image" content={`${getConfig('url.cdn')}/${ogTags.image}`} key={ogTags.image} />,
        <meta property="og:description" content={ogTags.description} key={ogTags.description} />,
        <meta property="og:type" content={ogTags.type} key={ogTags.type} />,
      ]
    )}

    <meta
      content={analytics?.title}
      name={ANALYTICS_META_TITLE}
    />

    <meta
      content={`${getConfig('version')}`}
      name="app-version"
    />
  </Helmet>
);

export {
  Head,
};
