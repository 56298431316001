import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Head } from 'dd-client/site/app/components/App/Head';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { TextInput } from 'dd-client/site/common/components/Form/TextInput';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useActiveCategories } from 'dd-client/site/pastDeals/hooks/useActiveCategories';
import { usePastDeals } from 'dd-client/site/pastDeals/hooks/usePastDeals';
import SearchIcon from './assets/search-icon.svg';
import { PastDealsPanel } from './PastDealsPanel';
import { Component } from './types';
import './PastDealsPage.scss';

enum FormField {
  SEARCH = 'search',
}

const PastDealsPage: Component = (): ReactElement | null => {
  const { t, i18n } = useTranslation();
  const de = i18n.getFixedT(LanguageCode.DE);
  const [categoryId, setCategoryId] = useState<null | number>(null);
  const [searchText, setSearchText] = useState<null | string>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const pastDeals = usePastDeals(categoryId, searchText);
  const activeCategories = useActiveCategories();

  const {
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const text = useMemo(
    () => ({
      allDeals: t('All deals'),
      clearSearch: t('Clear search'),
      dealsFoundFor: t('deals found for'),
      pastDeals: t('Past Deals'),
      pastDealsPageMetaDescription: t('Past deals page meta description'),
      pastDealsPageMetaTitle: t('Past deals page meta title'),
      pastDealsPageMetaTitleDE: de('Past deals page meta title'),
      searchProduct: t('Search product...'),
    }),
    [t, de],
  );

  const handleTabClick = useCallback(
    (categoryId: number | null) => () => {
      setCategoryId(categoryId);
    },
    [],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    (data): void  => {
      if (data[FormField.SEARCH]) {
        setSearchText(data[FormField.SEARCH]);
      }
    },
    [],
  );

  const handleClearSearchClick = useCallback(
    () => {
      setSearchText(null);
      setCategoryId(null);
      setTabIndex(0);

      reset({
        [FormField.SEARCH]: '',
      });
    },
    [reset],
  );

  const handleSearchCloseButtonClick = useCallback(
    () => reset({
      [FormField.SEARCH]: '',
    }),
    [reset],
  );

  const handleTabsSelect = useCallback(
    (index: number) => setTabIndex(index),
    [],
  );

  return (
    <div className="PastDealsPage">
      <Head
        analytics={{
          title: text.pastDealsPageMetaTitleDE,
        }}
        description={text.pastDealsPageMetaDescription}
        title={text.pastDealsPageMetaTitle}
      />

      <div className="container">
        <h1>
          {text.pastDeals}
        </h1>

        <form
          className="PastDealsPage-SearchForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            className="PastDealsPage-TextInput"
            control={control}
            name={FormField.SEARCH}
            placeholder={text.searchProduct}
          />

          {watch(FormField.SEARCH) && (
            <ButtonIcon
              className="PastDealsPage-SearchCloseButton"
              icon={<CloseIcon />}
              isFormSubmitPrevented={true}
              onClick={handleSearchCloseButtonClick}
              size={ButtonIcon.Size.SMALL}
              styleType={Button.StyleType.GHOST}
            />
          )}

          <ButtonIcon
            className="PastDealsPage-SearchButton"
            icon={<SearchIcon />}
            size={ButtonIcon.Size.SMALL}
            styleType={Button.StyleType.GHOST}
          />
        </form>

        {searchText && pastDeals.isSuccess && (
          <div className="PastDealsPage-SearchResult">
            <span className="PastDealsPage-SearchResultText">
              {pastDeals.data.pages[0].totalCount} {text.dealsFoundFor} &quot;{searchText}&quot;
            </span>

            <Button
              className="PastDealsPage-ClearButton"
              iconPosition={Button.IconPosition.LEFT}
              onClick={handleClearSearchClick}
              size={Button.Size.SMALL}
              styleType={Button.StyleType.GHOST}
            >
              <CloseIcon />
              {text.clearSearch}
            </Button>
          </div>
        )}

        {searchText && pastDeals.isPending && (
          <div className="PastDealsPage-SearchResult">
            <span className="PastDealsPage-SearchResultText">
              &nbsp;
            </span>
          </div>
        )}
      </div>

      <Tabs
        className="Tabs PastDealsPage-Tabs"
        selectedTabClassName="Tabs-Tab--Selected"
        selectedIndex={tabIndex}
        onSelect={handleTabsSelect}
      >
        <div className="container PastDealsPage-TabListWrapper">
          <TabList className="Tabs-TabList PastDealsPage-TabList">
            <Tab
              className="Tabs-Tab"
              onClick={handleTabClick(null)}
            >
              {text.allDeals}
            </Tab>

            {activeCategories.data?.map(
              category => (
                <Tab
                  key={category.id}
                  className="Tabs-Tab"
                  onClick={handleTabClick(category.id)}
                >
                  {category.name}
                </Tab>
              ),
            )}
          </TabList>
        </div>

        <div className="PastDealsPage-TabPanel">
          <div className="container">
            {/* TabPanel for 'all Deals' Tab below. The same number of TabPanel and Tab components are required */}
            <TabPanel>
              <PastDealsPanel pastDeals={pastDeals} />
            </TabPanel>

            {activeCategories.data?.map(
              category => (
                <TabPanel key={category.id}>
                  <PastDealsPanel pastDeals={pastDeals} />
                </TabPanel>
              ),
            )}
          </div>
        </div>

      </Tabs>
    </div>
  );
};

export {
  PastDealsPage,
};
