import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BrackLogo from 'dd-client/site/app/components/App/Header/assets/brack.svg';
import { LinkGroup } from 'dd-client/site/app/components/App/Header/LinkGroup';
import { Link } from 'dd-client/site/common/components/Link';
import { Modal } from 'dd-client/site/common/components/Modal';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePrevious } from 'dd-client/site/common/hooks/usePrevious';
import { LanguageSelector } from 'dd-client/site/i18n/components/LanguageSelector';
import { Component, Props } from './types';
import './ModalMenu.scss';

const ModalMenu: Component = ({
  brackLogoLink,
  headerLinks,
  isModalMenuVisible,
  linkGroup,
  setIsModalMenuVisible,
}: Props): ReactElement => {
  const { isDesktop, isMobile } = useDevice();
  const { t } = useTranslation();
  const prevIsMobile = usePrevious(isMobile);

  const text = useMemo(
    () => ({
      offerFrom: t('An offer from'),
    }),
    [t],
  );

  const handleHamburgerModalClose = useCallback(
    () => setIsModalMenuVisible(false),
    [setIsModalMenuVisible],
  );

  useEffect(
    () => {
      if (isDesktop && isModalMenuVisible) {
        setIsModalMenuVisible(false);
      }
    },
    [isModalMenuVisible, isDesktop, setIsModalMenuVisible],
  );

  useEffect(
    () => {
      //closing open modal menu when pass from mobile to tablet
      if (prevIsMobile && !isMobile && isModalMenuVisible) {
        setIsModalMenuVisible(false);
      }
    },
    [isModalMenuVisible, isMobile, prevIsMobile, setIsModalMenuVisible],
  );

  return (
    <Modal
      animationType={Modal.AnimationType.SLIDE_RIGHT}
      classNameOverlay="ModalMenu-Overlay"
      className="ModalMenu-Modal"
      hasCloseButton={true}
      isOpen={isModalMenuVisible}
      onClose={handleHamburgerModalClose}
    >
      {() => (
        <div>
          <ul className="ModalMenu-Links">
            <LinkGroup
              links={linkGroup[0]}
              onClick={handleHamburgerModalClose}
            />
            <LinkGroup
              links={headerLinks}
              onClick={handleHamburgerModalClose}
            />
            <LinkGroup
              links={linkGroup[1]}
              onClick={handleHamburgerModalClose}
            />
          </ul>

          <div className="ModalMenu-LanguageWrapper">
            <LanguageSelector onClick={handleHamburgerModalClose} />
          </div>

          <ul className="ModalMenu-Links">
            <LinkGroup
              links={linkGroup[2]}
              onClick={handleHamburgerModalClose}
            />
          </ul>

          {isMobile && (
            <div className="ModalMenu-BrackWrapper">
              <span className="ModalMenu-BrackText">
                {text.offerFrom}
              </span>

              <Link
                className="ModalMenu-BrackLogoLink"
                url={brackLogoLink}
                target="_blank"
                rel="noreferrer"
              >
                <BrackLogo />
              </Link>
            </div>
          )}
        </div>)}
    </Modal>
  );
};

export {
  ModalMenu,
};
