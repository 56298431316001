import React, { ChangeEvent, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import CheckmarkIcon from 'dd-client/site/common/assets/icons/checkmark.svg';
import { Error } from 'dd-client/site/common/components/Form/common/Error';
import { useFormField } from 'dd-client/site/common/hooks/useFormField';
import { Component, Props } from './types';
import './Checkbox.scss';

const Checkbox: Component = ({
  className,
  control,
  error,
  isChecked,
  isDefaultChecked,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
}: Props) => {
  const rootClassName = classNames(
    'Checkbox',
    className,
  );

  const { field } = useFormField({ control, defaultValue: isChecked || isDefaultChecked, isRequired, name });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      isDefaultChecked !== undefined && field.onChange(e.target.checked);
    },
    [field, isDefaultChecked, onChange],
  );

  useEffect(
    () => {
      isChecked !== undefined && field.onChange(isChecked);
    },
    [field, isChecked],
  );

  return (
    <>
      <label className={rootClassName}>
        <input
          {...field}
          onChange={handleChange}
          className="Checkbox-Input"
          checked={field.value}
          disabled={isDisabled}
          type="checkbox"
        />

        <div className="Checkbox-Field">
          <CheckmarkIcon className="Checkbox-Icon" />
        </div>
        {(label || isRequired) && (
          <span className="Checkbox-Label">
            {isRequired && (
              <span className="Checkbox-LabelRequired">*</span>
            )}

            {label || ''}
          </span>
        )}
      </label>

      {error && (
        <Error>
          {error}
        </Error>
      )}
    </>
  );
};

export {
  Checkbox,
};
