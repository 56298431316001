import { getConfig } from 'dd-client/site/config/utils/config';
import { Deal, MediaType } from 'dd-client/site/deal/hooks/api/types';
import { ImageSource } from './types';

const IMAGE_SIZE = {
  desktop: {
    height: 91,
    width: 100,
  },
  mobile: {
    height: 131,
    width: 144,
  },
};

const getImageSource = (deal: Deal, isForMobiles: boolean): ImageSource | undefined => {
  const size = isForMobiles
    ? IMAGE_SIZE.mobile
    : IMAGE_SIZE.desktop;

  const imageSource = (
    deal.description.teaser
    || (
      deal.media.filter(
        (mediaItem) => mediaItem.type === MediaType.PHOTO,
      )[0]?.source
    )
  );

  const urlCdn = getConfig('url.cdn');

  return imageSource
    ? {
      src: `${urlCdn}/${size.width}x${size.height}/${imageSource}`,
      srcSet: `${urlCdn}/${size.width * 2}x${size.height * 2}/${imageSource} 2x`,
    }
    : undefined;
};

export {
  getImageSource,
  IMAGE_SIZE,
};
