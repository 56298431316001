import { AxiosResponse } from 'axios';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { Theme as ResponseTheme } from './connector.types';
import { Theme } from './';

const getThemes = (): Promise<Array<Theme>> => (
  getApiRequest({
    url: `${getApiUrl('static')}/theme/active`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<Array<ResponseTheme>>,
): Array<Theme> => {
  const {
    data,
  } = response;

  return Array.isArray(data)
    ? data
      .map((theme: ResponseTheme) => ({
        id: theme.id,
        logo: theme.logo,
        manifest: theme.manifest,
        previewToken: theme.previewToken,
      }))
    : [];
};

export {
  getThemes,
};
