import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tooltip } from 'dd-client/site/common/components/Tooltip';
import { capitalizeFirstLetter } from 'dd-client/site/common/utils/capitalizeFirstLetter';
import { formatPrice } from 'dd-client/site/common/utils/price/price';
import InfoIcon from './assets/info.svg';
import { Component, Props } from './types';
import './Price.scss';

enum StyleType {
  MAIN = 'main',
  VARIANT = 'variant',
  SMALL = 'small',
}

const Price: Component = ({
  className,
  comparison,
  discount,
  hasPriceRange = false,
  isFromInlined = false,
  oldPrice,
  price,
  styleType = StyleType.MAIN,
  tooltipId,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const rootClassName = classNames(
    'Price',
    {
      [`Price--${capitalizeFirstLetter(styleType)}`]: styleType !== StyleType.MAIN,
    },
    className,
  );

  const text = useMemo(
    () => ({
      //NOTE: Items from CMS added manually to force translation
      comparisonIntroductory: t('Introductory price'),
      comparisonSelf: t('Self-comparison Brack.ch'),
      comparisonWithCompetitors: t('Comparison with competitors'),
      // -------
      from: t('From'),
    }),
    [t],
  );

  return (
    <div className={rootClassName}>
      {hasPriceRange && !isFromInlined && (
        <div className="Price-From">
          {text.from}
        </div>
      )}

      <div className="Price-PriceAndDiscount">
        <div className="Price-Price">
          {hasPriceRange && isFromInlined && (
            <span className="Price-From">
              {text.from}
            </span>
          )}

          {formatPrice(price)}
        </div>

        {discount && oldPrice && (
          <div className="Price-Discount">
            -{discount}
            <span className="Price-DiscountPercentage">%</span>
          </div>
        )}
      </div>

      {oldPrice && (
        <div className="Price-OldPrice">
          <div className="Price-OldPriceValue">
            {formatPrice(oldPrice)}
          </div>
          {comparison &&  (
            <>
              <InfoIcon
                className="Price-InfoIcon"
                data-tooltip-id={tooltipId || 'price-info'}
              />

              <Tooltip
                id={tooltipId || 'price-info'}
                text={t(comparison)}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

Price.StyleType = StyleType;

export {
  Price,
  StyleType,
};
