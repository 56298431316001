import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getContentWithSpecialComponents } from 'dd-client/site/common/utils/contentWithSpecialComponents/contentWithSpecialComponents';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Media } from './Media';
import { Component, Props } from './';
import './Description.scss';

const USER_AVATAR_SIZE = {
  height: 48,
  width: 48,
};

const TEASER_IMAGE_SIZE = {
  height: 509,
  width: 560,
};

const Description: Component = ({
  description: { content, teaser, user },
  media,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      author: t('Author'),
      from: t('from'),
      mainImage: t('Main image'),
      tenant: t(getConfig('tenant')),
      text: t('from'),
      your: t('Your'),
    }),
    [t],
  );
  const urlCdn = getConfig('url.cdn');

  return (
    <>
      <div className="Description">
        <div className="row">
          <div className="col-md-6 Description-ContentWrapper">
            <div className="Description-Content">
              {getContentWithSpecialComponents(content || '')}
            </div>

            {user?.name && (
              <div className="Description-Author">
                {user.avatar && (
                  <img
                    alt={text.author}
                    className="Description-AuthorImage"
                    src={`${urlCdn}/${USER_AVATAR_SIZE.width}x${USER_AVATAR_SIZE.height}/${user.avatar}`}
                    srcSet={`${urlCdn}/${USER_AVATAR_SIZE.width * 2}x${USER_AVATAR_SIZE.height * 2}/${user.avatar} 2x`}
                  />
                )}

                <span className="Description-AuthorName">
                  {text.your} {user.name}
                  {user.label && ` ${text.from} ${user.label}`}
                </span>
              </div>
            )}
          </div>

          <div className="col-md-6 Description-TeaserImageWrapper">
            {teaser && (
              <img
                alt={text.mainImage}
                className="Description-TeaserImage"
                src={`${urlCdn}/${TEASER_IMAGE_SIZE.width}x${TEASER_IMAGE_SIZE.height}/${teaser}`}
                srcSet={`${urlCdn}/${TEASER_IMAGE_SIZE.width * 2}x${TEASER_IMAGE_SIZE.height * 2}/${teaser} 2x`}
              />
            )}
          </div>
        </div>
      </div>

      <Media media={media} />
    </>
  );
};

export {
  Description,
};
