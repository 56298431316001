import { getConfig } from 'dd-client/site/config/utils/config';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

interface Language {
  code: LanguageCode;
  label: string;
}

const getAvailableLanguages = (): Array<Language> => getConfig('languages.available', []);

export {
  getAvailableLanguages,
};
