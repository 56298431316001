import React, { ReactElement } from 'react';
import { Link } from 'dd-client/site/common/components/Link';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './Apps.scss';

const Apps: Component = ({
  apps,
}: Props): ReactElement => {
  return (
    <div>
      <span className="Apps-Description">
        {apps.description}
      </span>
      <div className="Apps-LinkImageContainer">
        {apps.links.map(({
          image,
          url,
        }) => (
          <Link
            key={image}
            className="Apps-Link"
            url={url}
            rel="noreferrer"
            target="_blank"
          >
            <img
              className='Apps-Image'
              alt={image}
              src={`${getConfig('url.cdn')}/${image}`}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export {
  Apps,
};
