import { LoadDataFunctionType, LoadDataType } from 'dd-server/site/types';
import { metricsModule } from 'dd-client/site/common/utils/metrics/metrics';
import { QUERY } from 'dd-client/site/common/utils/query/query';

const APP_MODULE = 'static_page';

const loadData: LoadDataFunctionType = (match, language): LoadDataType => {
  metricsModule.run(({ metrics }) => {
    metrics.increment(`${APP_MODULE}.request`);
  });

  const queries = [
    {
      isMainQuery: true,
      queryFunction: QUERY.staticPage.queryFunction,
      queryKey: [QUERY.staticPage.queryName, language, match.params.page],
    },
  ];

  return {
    appModule: APP_MODULE,
    queries,
  };
};

export {
  loadData,
};
