import { getComments } from 'dd-client/site/comments/hooks/useComments/connector';
import { getThemes } from 'dd-client/site/common/hooks/useThemes/connector';
import { getActiveDeals } from 'dd-client/site/deal/hooks/api/useActiveDeals/connector';
import { getCategoryDeal } from 'dd-client/site/deal/hooks/api/useCategoryDeal/connector';
import { getDeal } from 'dd-client/site/deal/hooks/api/useDeal/connector';
import { getNews } from 'dd-client/site/news/hooks/useNews/connector';
import { getSubscriptionSettings } from 'dd-client/site/newsletter/hooks/useSubscriptionSettings/connector';
import { getActiveCategories } from 'dd-client/site/pastDeals/hooks/useActiveCategories/connector';
import { getPastDeals } from 'dd-client/site/pastDeals/hooks/usePastDeals/connector';
import { getPromotions } from 'dd-client/site/promotions/hooks/usePromotions/connector';
import { getStaticPage } from 'dd-client/site/static/hooks/useStaticPage/connector';
import { getStaticPages } from 'dd-client/site/static/hooks/useStaticPages/connector';
import { QueryType } from './types';

const QUERY: QueryType = {
  activeCategories: {
    queryFunction: getActiveCategories,
    queryName: 'activeCategories',
  },
  activeDeals: {
    queryFunction: getActiveDeals,
    queryName: 'activeDeals',
  },
  categoryDeal: {
    queryFunction: getCategoryDeal,
    queryName: 'categoryDeal',
  },
  comments: {
    queryFunction: getComments,
    queryName: 'comments',
  },
  deal: {
    queryFunction: getDeal,
    queryName: 'deal',
  },
  news: {
    //@ts-ignore - bug in react query types: https://github.com/TanStack/query/issues/6715
    queryFunction: getNews,
    queryName: 'news',
  },
  pastDeals: {
    //@ts-ignore - bug in react query types: https://github.com/TanStack/query/issues/6715
    queryFunction: getPastDeals,
    queryName: 'pastDeals',
  },
  promotions: {
    queryFunction: getPromotions,
    queryName: 'promotions',
  },
  staticPage: {
    queryFunction: getStaticPage,
    queryName: 'staticPage',
  },
  staticPages: {
    queryFunction: getStaticPages,
    queryName: 'staticPages',
  },
  subscriptionSettings: {
    queryFunction: getSubscriptionSettings,
    queryName: 'subscriptionSettings',
  },
  themes: {
    queryFunction: getThemes,
    queryName: 'themes',
  },
};

export {
  QUERY,
};
