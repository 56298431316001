import { useTranslation } from 'react-i18next';
import { getAddToCartQueryParams } from 'dd-client/site/analytics/utils/getAddToCartQueryParams/getAddToCartQueryParams';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const useAddToCartAnalytics = (): (() => string) => {
  const { i18n: { language } } = useTranslation();
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;

  return (): string => {
    if (!currentDealData) {
      return '';
    }

    const { startDate, analytics: { categorySlug, dealSlug } } = currentDealData;
    return getAddToCartQueryParams(language as LanguageCode, startDate, categorySlug, dealSlug);
  };
};

export {
  useAddToCartAnalytics,
};
