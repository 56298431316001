import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { FetchResponseBody } from './connector.types';
import { StaticPage } from './types';

const getStaticPage = (context: QueryFunctionContext): Promise<StaticPage> => (
  getApiRequest({
    context,
    url: `${getApiUrl('static')}/page/by/slug/${context.queryKey[2]}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<FetchResponseBody>,
): StaticPage => {
  const {
    data: {
      analytics,
      content,
      headerImage,
      id,
      meta,
      og,
      slug,
      slugs,
      title,
    },
  } = response;

  return ({
    analytics,
    content,
    headerImage,
    id,
    meta,
    og,
    slug,
    slugs,
    title,
  });
};

export {
  getStaticPage,
};
