/* eslint-disable sort-keys-fix/sort-keys-fix */
const PATHS = {
  home: '/:lang',
  category: '/:lang/category/:category',
  deal: '/:lang/deal/:deal',
  staticPage: '/:lang/site/:page',
  bannedIp: '/:lang/banned-ip',
  formSubmissionSuccess: '/:lang/form-submission-success',
  formSubmissionError: '/:lang/form-submission-error',
  news: '/:lang/news',
  pastDeals: '/:lang/past-deals',
  subscription: '/:lang/subscription',
  cookieOptOut: '/:lang/cookie-opt-out',
};

/* eslint-enable sort-keys-fix/sort-keys-fix */

interface PathParams {
  lang: string;
  category?: string;
  deal?: string;
  page?: string;
}

export type {
  PathParams,
};

export {
  PATHS,
};
