import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrow, ARROW_DISABLED_CLASS_NAME, ArrowType } from './Arrow';
import { Component, Props } from './';
import './Slider.scss';

const CLASS_NAME = {
  PaginationBullet: 'Slider-PaginationBullet',
  PaginationBulletActive: 'Slider-PaginationBullet--Active',
  Swiper: 'Swiper',
  SwiperSlide: 'Swiper-Slide',
};

const Slider: Component = ({
  breakpoints,
  className,
  spaceBetween = 16,
  slidesPerGroup = 1,
  slidesPerView = 'auto',
  slides,
}: Props): ReactElement | null => {
  const [prev, setPrev] = useState<HTMLElement | null>(null);
  const [next, setNext] = useState<HTMLElement | null>(null);

  const rootClassName = classNames(
    'Slider',
    className,
  );

  return (
    <div className={rootClassName}>
      <Swiper
        className={CLASS_NAME.Swiper}
        breakpoints={breakpoints}
        spaceBetween={spaceBetween}
        slidesPerGroup={slidesPerGroup}
        slidesPerView={slidesPerView}
        modules={[Navigation, Pagination]}
        navigation={{
          disabledClass: ARROW_DISABLED_CLASS_NAME,
          nextEl: next,
          prevEl: prev,
        }}
        pagination={{
          bulletActiveClass: CLASS_NAME.PaginationBulletActive,
          bulletClass: CLASS_NAME.PaginationBullet,
          clickable: true,
        }}
      >
        {slides.map(
          (slide, index) => (
            <SwiperSlide
              key={index}
              className={CLASS_NAME.SwiperSlide}
            >
              {slide}
            </SwiperSlide>
          ),
        )}
      </Swiper>

      <div className='Slider-PaginationArrow'>
        <Arrow
          type={ArrowType.PREV}
          setRef={setPrev}
        />

        <Arrow
          type={ArrowType.NEXT}
          setRef={setNext}
        />
      </div>
    </div>
  );
};

export {
  Slider,
};
