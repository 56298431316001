import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { getConfig } from 'dd-client/site/config/utils/config';
import { CategoryPromotion, FetchResponseBody } from './connector.types';
import { Promotions } from './types';

const getPromotions = (context: QueryFunctionContext): Promise<Promotions> => {
  return getApiRequest({
    context,
    url: `${getConfig('url.api.static')}/promotion`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError);
};

const mapResponseToState = (
  response: AxiosResponse<FetchResponseBody>,
): Promotions => {
  const {
    data,
  } = response;

  const result: Promotions = {};

  data.default && (
    result.default = {
      categories: data.default.categories,
      id: data.default.id,
      image: data.default.image,
      sectionTitle: data.default.sectionTitle,
      url: data.default.url,
    }
  );

  Array.isArray(data.category) && (
    data.category.forEach((categoryPromotion: CategoryPromotion) => {
      const { id, promotion } = categoryPromotion;
      if (promotion) {
        result[id] = {
          categories: promotion.categories,
          id: promotion.id,
          image: promotion.image,
          sectionTitle: promotion.sectionTitle,
          url: promotion.url,
        };
      }
    }));

  return result;
};

export {
  getPromotions,
};
