import { QueryFunctionContext } from '@tanstack/react-query';
import {
  AvailabilityUpdateEvent,
  DealsUpdateEvent,
  updateAvailability,
} from 'dd-client/site/common/hooks/useSseUpdates';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { getConfig } from 'dd-client/site/config/utils/config';
import { mapDealToState } from 'dd-client/site/deal/hooks/api/dealMapper';
import { Deal as EventDeal } from '../connector.types';
import { Deal } from '../types';

const getActiveDeals = (context: QueryFunctionContext): Promise<Array<Deal>> => {
  const language = context.queryKey[1];
  const tenant = getConfig('tenant');
  const url = `${getApiUrl('broadcast')}/get_latest_data?tenant=${tenant}&lang=${language}`;

  return getApiRequest({
    context,
    url,
  }).then(
    (response) => mapResponseToState(response.data),
  ).catch(handleApiRequestError);
};

const mapDealsUpdateEventToState = (
  data: Array<EventDeal>,
): Array<Deal> => {
  return Array.isArray(data)
    ? data.map(mapDealToState)
    : [];
};

const mapResponseToState = (
  data: [DealsUpdateEvent, AvailabilityUpdateEvent],
): Array<Deal> | undefined => {
  const dealsUpdatePayload = data[0].payload;
  const availabilityUpdatePayload = data[1].payload;

  return updateAvailability(mapDealsUpdateEventToState(dealsUpdatePayload), availabilityUpdatePayload);
};

export {
  getActiveDeals,
  mapDealsUpdateEventToState,
};
