/* eslint-disable sort-keys-fix/sort-keys-fix */

//NOTE: Keep in sync with $grid-breakpoints
const BREAKPOINTS = {
  xs: 0,
  sm: 480,
  md: 720,
  lg: 1200,
  xl: 1480,
  xxl: 1920,
};

export {
  BREAKPOINTS,
};
