import React, { ReactElement } from 'react';
import classNames from 'classnames';
import ArrowRight from 'dd-client/site/common/assets/icons/arrow-right.svg';
import { SECTION_ELEMENT_ID } from '../Section';
import { ComponentType, Props } from './';
import './Title.scss';

const Title: ComponentType = ({
  children,
  className,
  id,
  index,
  onClick,
  isOpen,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Accordion-SectionTitle',
    {
      'Accordion-SectionTitle--Open': isOpen,
    },
    className,
  );

  return (
    <div
      aria-controls={`${SECTION_ELEMENT_ID.content}-${id}-${index}`}
      aria-level={1}
      className={rootClassName}
      id={`${SECTION_ELEMENT_ID.heading}-${id}-${index}`}
      onClick={onClick}
      role="heading"
    >
      {children}
      <ArrowRight />
    </div>
  );
};

export {
  Title,
};
