import { FunctionComponent } from 'react';
import { CookieOptOutPage } from 'dd-client/site/analytics/components/CookieOptOutPage';
import * as App from 'dd-client/site/app/components/App';
import { hasNews, hasNewsletter, hasPastDeals, hasStaticPage } from 'dd-client/site/common/utils/features';
import { DealPage, loadData as loadHomePageData } from 'dd-client/site/deal/components/DealPage';
import { BanPage } from 'dd-client/site/error/components/BanPage';
import { NotFoundPage } from 'dd-client/site/error/components/NotFoundPage';
import { loadData as loadNewsPageData, NewsPage } from 'dd-client/site/news/components/NewsPage';
import { NewsletterErrorPage } from 'dd-client/site/newsletter/components/NewsletterErrorPage';
import { NewsletterSubscriptionPage } from 'dd-client/site/newsletter/components/NewsletterSubscriptionPage';
import { NewsletterUnsubscriptionPage } from 'dd-client/site/newsletter/components/NewsletterUnsubscriptionPage';
import { loadData as loadPastDealsPageData, PastDealsPage } from 'dd-client/site/pastDeals/components/PastDealsPage';
import { getPathsForAvailableLanguages } from 'dd-client/site/routes/utils/url';
import { loadData as loadStaticPageData, StaticPage } from 'dd-client/site/static/components/StaticPage';
import { PATHS } from './paths';
import { Route, RouteConfig } from './';

const newsConfig = [{
  component: NewsPage as FunctionComponent,
  exact: true,
  loadData: loadNewsPageData,
  path: getPathsForAvailableLanguages(PATHS.news),
}];

const newsletterConfig = [
  {
    component: NewsletterSubscriptionPage as FunctionComponent,
    exact: true,
    path: getPathsForAvailableLanguages(PATHS.formSubmissionSuccess),
  },
  {
    component: NewsletterErrorPage as FunctionComponent,
    exact: true,
    path: getPathsForAvailableLanguages(PATHS.formSubmissionError),
  },
  {
    component: NewsletterUnsubscriptionPage as FunctionComponent,
    exact: true,
    path: getPathsForAvailableLanguages(PATHS.subscription),
  },
];

const pastDealsConfig = [{
  component: PastDealsPage as FunctionComponent,
  exact: true,
  loadData: loadPastDealsPageData,
  path: getPathsForAvailableLanguages(PATHS.pastDeals),
}];

const staticPageConfig = [{
  component: StaticPage as FunctionComponent,
  exact: true,
  loadData: loadStaticPageData,
  path: getPathsForAvailableLanguages(PATHS.staticPage),
}];

const ROUTE_CONFIG: RouteConfig = [
  {
    ...App as Route,
    routes: [
      ...(hasNews ? newsConfig : []),
      ...(hasNewsletter ? newsletterConfig : []),
      ...(hasPastDeals ? pastDealsConfig : []),
      ...(hasStaticPage ? staticPageConfig : []),
      {
        component: BanPage as FunctionComponent,
        exact: true,
        path: getPathsForAvailableLanguages(PATHS.bannedIp),
      },
      {
        component: CookieOptOutPage as FunctionComponent,
        exact: true,
        path: getPathsForAvailableLanguages(PATHS.cookieOptOut),
      },
      {
        component: DealPage as FunctionComponent,
        exact: true,
        loadData: loadHomePageData,
        path: [
          ...getPathsForAvailableLanguages(PATHS.home),
          ...getPathsForAvailableLanguages(PATHS.deal),
          ...getPathsForAvailableLanguages(PATHS.category),
        ],
      },
      {
        component: NotFoundPage as FunctionComponent,
      },
    ],
  },
];

export {
  ROUTE_CONFIG,
};
