import React, { ReactElement } from 'react';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { usePromotions } from 'dd-client/site/promotions/hooks/usePromotions';
import { DismissiblePromotion } from './DismissiblePromotion';
import { StaticPromotion } from './StaticPromotion';
import { Component, Props } from './types';

const Promotions: Component = ({
  categoryId,
}: Props): ReactElement | null => {
  const { isMobile } = useDevice();
  const { isHomePage } = usePageMatch();
  const promotions = usePromotions();
  const promotion = isMobile ? promotions.data?.default : promotions.data?.[categoryId];

  if (!promotion) {
    return null;
  }

  if (!isMobile) {
    return (
      <DismissiblePromotion promotion={promotion} />
    );
  }

  if (isHomePage) {
    return (
      <div suppressHydrationWarning>
        <StaticPromotion promotion={promotion} />
      </div>
    );
  }

  return null;
};

export {
  Promotions,
};
