import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { FetchResponseBody, StaticPage } from './connector.types';
import { StaticPages } from './types';

const getStaticPages = (context: QueryFunctionContext): Promise<StaticPages> => (
  getApiRequest({
    context,
    url: `${getApiUrl('static')}/page`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<FetchResponseBody>,
): StaticPages => {
  const {
    data,
  } = response;

  const getStaticPage = (staticPage: StaticPage) => ({
    slug: staticPage.slug,
    title: staticPage.title,
  });

  return ({
    footer: Array.isArray(data.footer)
      ? data.footer.map(getStaticPage)
      : [],
    header: Array.isArray(data.header)
      ? data.header.map(getStaticPage)
      : [],
  });
};

export {
  getStaticPages,
};
