import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isPast, toDate } from 'date-fns';
import { Countdown } from 'dd-client/site/common/components/Countdown';
import { Component, Props } from './types';
import './ProgressBar.scss';

enum Type {
  MAIN = 'main',
  TILE = 'tile',
}

enum Threshold {
  ALMOST_END = 20,
  FULL = 100,
  NEAR_END = 40,
}

const ProgressBar: Component = ({
  availablePercentage = 0,
  className,
  dataTestId,
  endTime,
  isReserved,
  isSoldOut,
  startTime,
  type = Type.MAIN,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      available: t('Available'),
      dealEnded: t('Deal ended'),
      reserved: t('Reserved'),
      soldOut: t('Sold out'),
      still: t('Still'),
    }),
    [t],
  );

  const isEnded = endTime ? isPast(toDate(endTime)) : false;

  const rootClassName = classNames(
    'ProgressBar',
    {
      'ProgressBar--Ended': isEnded,
      'ProgressBar--SoldOut': isSoldOut,
      'ProgressBar--Tile': type === Type.TILE,
    },
    className,
  );

  const percentageFillClassName = classNames(
    'ProgressBar-PercentageFill',
    {
      'ProgressBar-PercentageFill--AlmostEnd': availablePercentage <= Threshold.ALMOST_END,
      'ProgressBar-PercentageFill--Full': availablePercentage === Threshold.FULL,
      'ProgressBar-PercentageFill--NearEnd': availablePercentage <= Threshold.NEAR_END,
      'ProgressBar-PercentageFill--Reserved': isReserved,
    },
  );

  return (
    <div
      className={rootClassName}
      data-testid={dataTestId}
    >
      {!isSoldOut && !isEnded && availablePercentage > 0 && (
        <div
          className={percentageFillClassName}
          data-testid="progress-bar-percentage-fill"
          style={{
            width: `${availablePercentage}%`,
          }}
        />
      )}

      {type === Type.MAIN && (
        <>
          <div
            className="ProgressBar-Text"
            data-testid="progress-bar-text"
          >
            {!isReserved && !isSoldOut && !isEnded && (
              text.still
            )}

            {!isSoldOut && !isEnded && availablePercentage > 0 && (
              <span className="ProgressBar-TextValue">
                {availablePercentage}%
              </span>
            )}

            {isReserved && text.reserved}
            {isSoldOut && text.soldOut}
            {isEnded && text.dealEnded}
            {!isReserved && !isSoldOut && !isEnded && text.available}
          </div>

          {!isEnded && endTime && (
            <Countdown
              key={`${startTime}-${endTime}`}
              className="ProgressBar-Countdown"
              endTime={endTime}
              startTime={startTime}
            />
          )}
        </>
      )}
    </div>
  );
};

ProgressBar.Type = Type;

export {
  ProgressBar,
  Type,
};
