import React, { MouseEvent, ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import { Component, Props } from './types';
import './Promotion.scss';

const Promotion: Component = ({
  className,
  imageSrc,
  onClose,
  sectionTitle,
  url,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Promotion',
    'Promotion-Animation',
    className,
  );

  const handleClose = useCallback(
    () => {
      onClose();
    },
    [onClose],
  );

  const handleLinkClick = useCallback(
    (e: MouseEvent) => {
      url ? onClose() : e.preventDefault();
    },
    [onClose, url],
  );

  return (
    <div className={rootClassName}>
      <a
        href={url}
        onClick={handleLinkClick}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt={sectionTitle}
          className="Promotion-Image"
          src={imageSrc}
        />
      </a>

      <CloseIcon
        className="Promotion-CloseIcon"
        onClick={handleClose}
      />
    </div>
  );
};

export {
  Promotion,
};
