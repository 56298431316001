import { LoadDataFunctionType, LoadDataType } from 'dd-server/site/types';
import { metricsModule } from 'dd-client/site/common/utils/metrics/metrics';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { NewsType } from 'dd-client/site/news/hooks/useNews';

const APP_MODULE = 'news_page';

const loadData: LoadDataFunctionType = (match, language): LoadDataType => {
  metricsModule.run(({ metrics }) => {
    metrics.increment(`${APP_MODULE}.request`);
  });

  const queries = [
    {
      isInfiniteQuery: true,
      isMainQuery: true,
      queryFunction: QUERY.news.queryFunction,
      queryKey: [QUERY.news.queryName, language, NewsType.ALL, null],
    },
  ];

  return {
    appModule: APP_MODULE,
    queries,
  };
};

export {
  loadData,
};
