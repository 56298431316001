import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

//Keep in sync language stored internally by i18n library and language in url
const useLanguageSync = (): void => {
  const { i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || history.action === 'PUSH') {
        const urlLanguage = history.location.pathname.substring(1, 3);
        if (urlLanguage !== i18n.language) {
          i18n.changeLanguage(urlLanguage);
        }
      }
    };
  }, [history.action, history.location, i18n]);
};


export {
  useLanguageSync,
};

