import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Spinner } from 'dd-client/site/common/components/Spinner';
import { Component, Props } from './types';
import './ItemSkeleton.scss';

const ItemSkeleton: Component = ({
  className,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'ItemSkeleton',
    'NewsItem',
    className,
  );

  return (
    <div className={rootClassName}>
      <Spinner className="ItemSkeleton-Spinner" />
    </div>
  );
};

export {
  ItemSkeleton,
};
