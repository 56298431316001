import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import ArrowDown from 'dd-client/site/common/assets/icons/arrow-down.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { Checkbox } from 'dd-client/site/common/components/Form/Checkbox';
import { getChangedCheckboxes, getFormFieldCheckboxes } from 'dd-client/site/common/utils/newsletter/newsletter';
import { Checkboxes } from 'dd-client/site/common/utils/newsletter/types';
import { ActiveCategories, useActiveCategories } from 'dd-client/site/pastDeals/hooks/useActiveCategories';
import { Component, Props } from './types';
import './NewsletterDropdown.scss';

const mergeData = (activeCategories: ActiveCategories | undefined, t: TFunction): Checkboxes => (
  [
    getFormFieldCheckboxes(t).defaultCheckboxItemAllOption,
    ...(
      activeCategories
        ?.slice(1) // Sliced one piece of array because it's DailyDeal category which shouldn't be included
        .map(
          activeCategory => ({ ...activeCategory, isChecked: true }),
        )
      || []
    ),
    ...getFormFieldCheckboxes(t).defaultCheckboxItems,
  ]
);

const NewsletterDropdown: Component = ({
  className,
  control,
  required,
  shouldReset,
}: Props): ReactElement => {
  const activeCategories = useActiveCategories();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [positionTop, setPositionTop] = useState<undefined | string>(undefined);
  const [checkboxes, setCheckboxes] = useState(mergeData(activeCategories.data, t));
  const handleToggleClick = () => setIsOpen(!isOpen);
  const handleDoneClick = () => setIsOpen(false);

  const dropdownWrapperRef = useRef<HTMLDivElement | null>(null);
  const dropdownContentRef = useRef<HTMLDivElement | null>(null);

  const rootClassName = classNames(
    'NewsletterDropdown',
    {
      'NewsletterDropdown--Open': isOpen,
    },
    className,
  );

  const text = useMemo(
    () => ({
      done: t('Done'),
    }),
    [t],
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent): void => {
      if (dropdownWrapperRef.current?.contains(event.target as Node)) {
        return;
      }

      setIsOpen(false);
    },
    [],
  );

  const handleRequiredValidation = useCallback(
    (checkboxes: Checkboxes) => {
      if (checkboxes.filter(checkboxItem => checkboxItem.isChecked).length) {
        required?.clearErrors(getFormFieldCheckboxes(t).newsletterCheckboxes);
      } else {
        required?.setError(getFormFieldCheckboxes(t).newsletterCheckboxes, { type: 'required' });
      }
    },
    [required, t],
  );

  const handleChange = useCallback(
    (index: number): void  => {
      const newChangedCheckboxes: Checkboxes = getChangedCheckboxes(index, checkboxes);

      if (required) {
        handleRequiredValidation(newChangedCheckboxes);
      }

      setCheckboxes(newChangedCheckboxes);
    },
    [checkboxes, handleRequiredValidation, required],
  );

  const toggleLabel = useMemo(
    () => {
      if (checkboxes[0].isChecked) {
        return checkboxes[0].name;
      }

      return checkboxes
        .filter(checkboxItem => checkboxItem.isChecked)
        .map(checkboxItem => checkboxItem.name)
        .join('; ');
    },
    [checkboxes],
  );


  useEffect(
    () => {
      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    },
    [handleOutsideClick],
  );

  useEffect(
    () => {
      if (activeCategories.data) {
        setCheckboxes(
          mergeData(activeCategories.data, t),
        );
      }
    },
    [activeCategories.data, t],
  );

  useEffect(
    () => {
      if (isOpen && dropdownContentRef.current) {
        const dropdownContentHeight = dropdownContentRef.current.offsetHeight;
        const dropdownContentTop = dropdownContentRef.current.getBoundingClientRect().top || 0;

        if (dropdownContentHeight + dropdownContentTop > window.innerHeight) {
          setPositionTop(`calc(100% - ${dropdownContentHeight + dropdownContentTop - window.innerHeight}px)`);
        }
      } else {
        setPositionTop(undefined);
      }
    },
    [isOpen],
  );

  useEffect(
    () => {
      if (shouldReset) {
        setCheckboxes(mergeData(activeCategories.data, t));
      }
    },
    [activeCategories.data, shouldReset, t],
  );

  return (
    <div
      className={rootClassName}
      ref={dropdownWrapperRef}
    >
      <div className="NewsletterDropdown-ToggleWrapper">
        <div
          className="NewsletterDropdown-Toggle"
          onClick={handleToggleClick}
        >
          <div className="NewsletterDropdown-ToggleLabel">
            {t(toggleLabel)}&nbsp;
          </div>
          <ArrowDown />
        </div>
      </div>

      <div
        className="NewsletterDropdown-Content"
        ref={dropdownContentRef}
        style={{ top: positionTop }}
      >
        <div className="NewsletterDropdown-CheckboxesWrapper">
          {
            checkboxes.map(
              (checkboxItem, index) => (
                <Checkbox
                  control={control}
                  isChecked={checkboxItem.isChecked}
                  key={checkboxItem.name}
                  label={checkboxItem.name}
                  name={`${getFormFieldCheckboxes(t).newsletterCheckboxes}.${checkboxItem.id}`}
                  onChange={() => handleChange(index)}
                />
              ),
            )
          }
        </div>

        <Button
          isFormSubmitPrevented={true}
          size={Button.Size.SMALL}
          onClick={handleDoneClick}
        >
          {text.done}
        </Button>
      </div>
    </div>
  );
};

export {
  NewsletterDropdown,
};
