import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'dd-client/site/common/components/Button';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { Component, Props } from './types';
import './SeeDealButton.scss';

const SeeDealButton: Component = ({
  className,
  categorySlug,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const goToCategoryPage = useGoToPage(`category/${categorySlug}`);

  const text = useMemo(
    () => ({
      seeDeal: t('See deal'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'SeeDealButton',
    className,
  );

  return (
    <Button
      className={rootClassName}
      isDisabled={false}
      isFullWidth
      onClick={goToCategoryPage}
    >
      {text.seeDeal}
    </Button>
  );
};

export {
  SeeDealButton,
};
