import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { ActiveCategories } from './types';

const useActiveCategories = (): UseQueryResult<ActiveCategories, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    queryFn: QUERY.activeCategories.queryFunction,
    queryKey: [QUERY.activeCategories.queryName, language],
  });
};

export {
  useActiveCategories,
};

