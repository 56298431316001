import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosPromise, Method } from 'axios';
import { isServer } from 'dd-client/site/common/utils/server';
import { getConfig } from 'dd-client/site/config/utils/config';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';

enum ApiHeader {
  LANGUAGE = 'x-lang',
  TENANT = 'x-tenant',
  TOTAL_COUNT = 'x-total-count',
}

interface ApiRequest {
  context?: QueryFunctionContext;
  data?: any;
  headers?: Record<string, string>;
  method?: Method;
  timeout?: number;
  url: string;
}

const getApiRequest = ({
  url,
  context,
  method = 'get',
  headers = {},
  timeout = 30000,
  data,
}: ApiRequest): AxiosPromise => {
  const language: string = context?.queryKey[1] ? `${context.queryKey[1]}` : i18nextInit.language;

  return (
    axios({
      data,
      headers: {
        ...headers,
        [ApiHeader.LANGUAGE]: language,
        [ApiHeader.TENANT]: getConfig('tenant'),
      },
      method,
      timeout,
      url,
    })
  );
};

const getApiUrl = (resource: string): string => {
  const postfix = isServer
    ? 'Ssr'
    : '';

  return getConfig(`url.api${postfix}.${resource}`);
};

export {
  ApiHeader,
  getApiRequest,
  getApiUrl,
};
