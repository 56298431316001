import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { getIsFullAnimationFeatureActive } from 'dd-client/site/common/utils/features';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import neutralizerImage from './assets/neutralizer.png';
import { Component, Props } from './types';
import './FullPageAnimation.scss';

// the value should be the same as the animation duration in css (last animated html element plus animation duration)
const FULL_ANIMATION_DURATION = 1000;

const FullPageAnimation: Component = ({
  className,
}: Props): ReactElement | null => {
  const rootClassName = classNames(
    'FullPageAnimation',
    className,
  );
  const { categoryParam, dealParam } = usePageMatch();
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;

  const isFullAnimationFeatureActive = getIsFullAnimationFeatureActive();
  const [isFullAnimated, setIsFullAnimated] = useState(isFullAnimationFeatureActive);

  useEffect(
    () => {
      if (isFullAnimationFeatureActive) {
        setIsFullAnimated(true);

        setTimeout(
          () => setIsFullAnimated(false),
          FULL_ANIMATION_DURATION,
        );
      }
    },
    [categoryParam, dealParam, currentDealData?.id, isFullAnimationFeatureActive],
  );

  if (!isFullAnimated) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <div className="FullPageAnimation-Background" />

      <div className="FullPageAnimation-ImageWrapper">
        <img
          className="FullPageAnimation-Image"
          src={neutralizerImage}
          alt="Neutralizer"
        />
        <div className="FullPageAnimation-Glow" />
      </div>

      <div className="FullPageAnimation-Flash" />
    </div>
  );
};

export {
  FullPageAnimation,
};
