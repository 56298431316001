import React, { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Modal } from './Modal';
import { Component, Props } from '.';

const ANIMATION_DURATION = 250;

enum AnimationType {
  FADE = 'fade',
  SLIDE_RIGHT = 'slideRight',
}

const ModalWrapper: Component = (props: Props): ReactElement => {
  const { isOpen } = props;

  return (
    <CSSTransition
      classNames={{
        exitActive: 'Modal-Animation--Close',
      }}
      in={isOpen}
      mountOnEnter
      timeout={ANIMATION_DURATION}
      unmountOnExit
    >
      <Modal {...props} />
    </CSSTransition>
  );
};

ModalWrapper.AnimationType = AnimationType;

export {
  AnimationType,
  ModalWrapper,
};
