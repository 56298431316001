import React, { ReactElement }  from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './BulletPoints.scss';

const BulletPoints: Component = ({
  className,
  keyFeatures,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'BulletPoints',
    className,
  );

  return (
    <ul className={rootClassName}>
      {
        keyFeatures.map(
          ({ keyFeature }, index) => (
            <li key={index}>
              {keyFeature}
            </li>
          ),
        )
      }
    </ul>
  );
};

export {
  BulletPoints,
};
