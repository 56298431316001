import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { capitalizeFirstLetter } from 'dd-client/site/common/utils/capitalizeFirstLetter';
import AlertInfo from './assets/alert-info.svg';
import AlertSuccess from './assets/alert-success.svg';
import AlertWarning from './assets/alert-warning.svg';
import { Component, Props } from './';
import './Alert.scss';

enum StyleType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

const Alert: Component = ({
  children,
  className,
  isVisible = false,
  styleType = StyleType.SUCCESS,
}: Props): ReactElement | null => {
  if (!isVisible) {
    return null;
  }

  const Icons = {
    info: AlertInfo,
    success: AlertSuccess,
    warning: AlertWarning,
  };

  const rootClassName = classNames(
    'Alert',
    `Alert--${capitalizeFirstLetter(styleType)}`,
    className,
  );
  const Icon = Icons[styleType];

  return (
    <div className={rootClassName}>
      <Icon className="Alert-Icon" />

      <div className="Alert-Content">
        {children}
      </div>
    </div>
  );
};

Alert.StyleType = StyleType;

export {
  Alert,
  StyleType,
};
