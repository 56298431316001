import { atom } from 'recoil';

const isErrorPage = atom({
  default: false,
  key: 'isErrorPage',
});

const userIpAddress = atom({
  default: '',
  key: 'ipAddress',
});

export {
  isErrorPage,
  userIpAddress,
};
