import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'dd-client/site/common/components/Button';
import { ComponentType, Props } from './';
import './RetryButton.scss';

const RetryButton: ComponentType = ({
  className,
  onClick,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      retry: t('Retry'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'RetryButton',
    className,
  );

  return (
    <Button
      className={rootClassName}
      styleType={Button.StyleType.GHOST}
      onClick={onClick}
    >
      {text.retry}
    </Button>
  );
};

export {
  RetryButton,
};
