import React, { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CLOSE_ANIMATION_DURATION } from '../';
import { Promotion } from './Promotion';
import { Component, Props } from './';

const PromotionWrapper: Component = ({
  isOpen,
  ...restProps
}: Props): ReactElement => {
  return (
    <CSSTransition
      classNames={{
        exitActive: 'Promotion-Animation--Close',
      }}
      in={isOpen}
      mountOnEnter
      timeout={CLOSE_ANIMATION_DURATION}
      unmountOnExit
    >
      <Promotion {...restProps} />
    </CSSTransition>
  );
};

export {
  PromotionWrapper,
};
