import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Newsletter } from 'dd-client/site/common/components/Form/Newsletter';
import { Component } from './types';
import './FooterNewsletter.scss';

const FooterNewsletter: Component = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      receiveNewsAndUpdates: t('Receive news and updates'),
    }),
    [t],
  );

  return (
    <div className="FooterNewsletter">
      <h3 className="FooterNewsletter-Title">
        {text.receiveNewsAndUpdates}
      </h3>

      <Newsletter />
    </div>
  );
};

export {
  FooterNewsletter,
};
