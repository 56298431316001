import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Slider } from 'dd-client/site/common/components/Slider';
import { BREAKPOINTS } from 'dd-client/site/common/utils/breakpoints';
import { Item } from './Item';
import { Component, Props } from './types';
import './RecommendedProducts.scss';

const RecommendedProducts: Component = ({
  className,
  recommendations,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      teamRecommends: t('Our recommendations'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'RecommendedProducts',
    'container',
    className,
  );

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <div className="SectionTitle RecommendedProducts-Title">
        {text.teamRecommends}
      </div>

      <Slider
        breakpoints={{
          [BREAKPOINTS.md]: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          [BREAKPOINTS.lg]: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
        }}
        slides={recommendations.map(
          recommendedProduct => (
            <Item
              labels={recommendedProduct.labels}
              key={recommendedProduct.sku}
              manufacturer={recommendedProduct.manufacturer}
              price={recommendedProduct.price}
              specialPrice={recommendedProduct.specialPrice}
              stock={recommendedProduct.stock}
              subtitle={recommendedProduct.subtitle}
              thumbnails={recommendedProduct.thumbnails}
              title={recommendedProduct.title}
              url={recommendedProduct.url}
            />
          ),
        )}
      />
    </div>
  );
};

export {
  RecommendedProducts,
};
