import React, { ReactElement } from 'react';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './Item.scss';

const Item: Component = ({
  headline,
  imageUrl,
  leadText,
  title,
  url,
}: Props): ReactElement => (
  <li className="NewsPage-Item">
    <a
      className="NewsPage-ItemLink"
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <span className="NewsPage-ItemTransparentBorder" />

      <img
        alt={title}
        className="NewsPage-ItemImage"
        src={`${getConfig('url.cdn')}/688x0/${imageUrl}`}
      />

      <div className="NewsPage-ItemDescriptionWrapper">
        <div className="NewsPage-ItemTitle">
          {title}
        </div>

        <div className="NewsPage-ItemHeadline">
          {headline}
        </div>

        <div
          className="NewsPage-ItemLeadText"
          dangerouslySetInnerHTML={{ __html: leadText }}
        />
      </div>
    </a>
  </li>
);

export {
  Item,
};
