import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { links } from 'dd-client/site/app/components/App/Footer/FooterBottom/links';
import { Link } from 'dd-client/site/common/components/Link';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { useIsStickyBar } from 'dd-client/site/common/hooks/useIsStickyBar';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { Component } from './types';
import './FooterBottom.scss';

const FooterBottom: Component = (): ReactElement => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as LanguageCode;

  const isStickyBar = useIsStickyBar();
  const { isMobile } = useDevice();

  const text = useMemo(
    () => ({
      allRightsReserved: t('All rights reserved'),
      currency: t('All prices are in Swiss Francs (CHF) including VAT and taxes'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'Footer-Bottom',
    {
      'Footer-Bottom--BottomSpace': isStickyBar && isMobile,
    },
  );

  return (
    <section className={rootClassName}>
      <ul className="Footer-BottomLinkList">
        {links[language].map((link) => (
          <li key={link.text}>
            <Link
              className="Footer-BottomLink"
              url={link.url}
              target="_blank"
              rel="noreferrer"
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
      <div className='Footer-Currency'>
        {text.currency}
      </div>
      <div>
        &copy;&nbsp;
        <a
          className="Footer-BottomLink"
          href={
            language === LanguageCode.FR
              ? 'https://www.brack.ch/fr'
              : 'https://www.brack.ch/'
          }
          suppressHydrationWarning
          target="_blank"
          rel="noreferrer"
        >
          BRACK.CH
        </a> — {text.allRightsReserved}
      </div>
    </section>
  );
};

export {
  FooterBottom,
};
