import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { ActiveCategories as ResponseActiveCategories } from './connector.types';
import { ActiveCategories } from './types';

const getActiveCategories = (context: QueryFunctionContext): Promise<ActiveCategories> => (
  getApiRequest({
    context,
    url: `${getApiUrl('deal')}/category/active`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<ResponseActiveCategories>,
): ActiveCategories => {
  const {
    data,
  } = response;

  return Array.isArray(data)
    ? data
      .map(
        ({ id, name }) => ({
          id,
          name,
        }),
      )
    : [];
};

export {
  getActiveCategories,
};
