import { useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { ApiError } from 'dd-client/site/common/types';
import { Deal } from 'dd-client/site/deal/hooks/api/types';
import { useActiveDeals } from 'dd-client/site/deal/hooks/api/useActiveDeals';
import { useCategoryDeal } from 'dd-client/site/deal/hooks/api/useCategoryDeal';
import { useDeal } from 'dd-client/site/deal/hooks/api/useDeal';

const useCurrentDeal = (): UseQueryResult<Deal | undefined, ApiError> | undefined => {
  const { dealParam, categoryParam } = usePageMatch();
  const { isHomePage } = usePageMatch();
  const deal = useDeal(dealParam);
  const categoryDeal = useCategoryDeal(categoryParam);
  const activeDeals = useActiveDeals();

  const activeDeal = useMemo(
    () => {
      if (dealParam) {
        return deal;
      }

      if (categoryParam) {
        return categoryDeal;
      }

      if (isHomePage) {
        return {
          ...activeDeals,
          data: activeDeals.data?.[0],
        };
      }
    },
    [activeDeals, categoryDeal, categoryParam, deal, dealParam, isHomePage],
  );

  // @ts-ignore
  return activeDeal;
};


export {
  useCurrentDeal,
};

