import { datadogLogs, Logger } from '@datadog/browser-logs';
import { getConfig } from 'dd-client/site/config/utils/config';
import { LogsClientReturn } from './types';

enum LogType {
  ERROR = 'error',
  INFO = 'info',
}

function LogsClient(): LogsClientReturn {
  const isEnabled = getConfig('datadogLogs.enabled');
  let instance: Logger | undefined;

  function getInstance(): Logger {
    if (!instance) {
      datadogLogs.init({
        clientToken: getConfig('datadogLogs.clientToken'),
        env: getConfig('env'),
        forwardErrorsToLogs: true,
        service: getConfig('appName'),
        sessionSampleRate: getConfig('datadogLogs.sessionSampleRate'),
        site: 'datadoghq.com',
        version: getConfig('version'),
      });
      datadogLogs.setGlobalContextProperty('site', getConfig('siteName'));

      instance = datadogLogs.logger;
    }

    return instance;
  }

  function log(type: LogType, message: string, messageContext?: object): void {
    if (!isEnabled) {
      return;
    }

    getInstance()[type](message, messageContext);
  }

  return {
    log,
  };
}

const logs = LogsClient();

export {
  logs,
  LogType,
};
