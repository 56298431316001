import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { createComment } from './connector';
import { BannedErrorData, SubmitCommentData } from './types';

const useSubmitComment = (): UseMutationResult<any, AxiosError<BannedErrorData>, SubmitCommentData> => (
  useMutation({ mutationFn: createComment })
);

export {
  useSubmitComment,
};

