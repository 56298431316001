const formatPrice = (
  price: string,
): string => {
  if (Number.isInteger(Number(price))) {
    return `${Number(price)}.–`;
  }

  return price;
};

export {
  formatPrice,
};
