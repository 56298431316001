import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import TimerIcon from './assets/timer.svg';
import { Component, Props } from './types';
import { getDeliveryInformation } from './utils/deliveryInformation';
import './DeliveryInformation.scss';

const DeliveryInformation: Component = ({
  className,
  deliveryInformation,
}: Props): ReactElement => {
  const [deliveryInfo, setDeliveryInfo] = useState(getDeliveryInformation(deliveryInformation, new Date()));

  useEffect(
    () => {
      setDeliveryInfo(getDeliveryInformation(deliveryInformation, new Date()));

      const intervalID = setInterval(
        () => (
          setDeliveryInfo(getDeliveryInformation(deliveryInformation, new Date()))
        ),
        10000,
      );

      return () => {
        clearInterval(intervalID);
      };
    },
    [deliveryInformation],
  );

  const rootClassName = classNames(
    'DeliveryInfo',
    className,
  );

  return (
    <div className={rootClassName}>
      <TimerIcon />
      {deliveryInfo}
    </div>
  );
};

export {
  DeliveryInformation,
};
