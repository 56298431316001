import React, { ReactElement } from 'react';
import { Property } from 'csstype';
import Logo from 'dd-client/site/app/components/App/Header/assets/logo.svg';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { primaryColor } from 'dd-client/site/theme';
import { Component, Props } from './types';

const AppCrash: Component = ({
  lang = LanguageCode.DE,
}: Props): ReactElement => {
  const style = {
    button: {
      background: primaryColor,
      border: 0,
      borderRadius: '4px',
      color: '#fff',
      cursor: 'pointer',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      margin: '0 auto 32px',
      padding: '12px 16px',
      textTransform: 'uppercase' as Property.TextTransform,
    },
    content: {
      fontFamily: 'Montserrat',
      paddingTop: '200px',
      textAlign: 'center' as Property.TextAlign,
    },
    header: {
      background: primaryColor,
      boxSizing: 'border-box' as Property.BoxSizing,
      height: '64px',
      paddingBottom: '16px',
      paddingLeft: '100px',
      paddingTop: '16px',
    },
    icon: {
      marginBottom: '32px',
    },
    info: {
      color: 'black',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '22.4px',
      marginBottom: '32px',
    },
    logo: {
      height: '32px',
    },
    root: {
      background: 'white',
    },
    subtitle: {
      color: '#717179',
      fontSize: '14.2px',
      marginBottom: '32px',
    },
    title: {
      fontSize: '32.4px',
      fontWeight: 'bold',
      letterSpacing: '-0.97px',
      marginBottom: '8px',
    },
  };

  const text = {
    button: {
      de: 'Seite neu laden',
      fr: 'Recharger la page',
    },
    info: {
      de: 'Unser technisches Team wurde über das Problem informiert und arbeitet an der Behebung.\n'
        + '<br />Versuche, die Seite neu zu laden, um zu prüfen, ob das Problem weiterhin besteht.',
      fr: 'Notre équipe technique a été informée du problème et s’efforce de le résoudre.\n'
        + '<br />Essayez de recharger la page pour vérifier si le problème persiste.',
    },
    subtitle: {
      de: 'Fehler: Die Anwendung ist abgestürzt.',
      fr: 'Erreur: l’application s’est arrêtée.',
    },
    title: {
      de: 'Es scheint, dass wir Probleme mit unserem Service haben.',
      fr: 'Il semble qu’il y ait des problèmes avec notre service.',
    },
  };

  return (
    <div style={style.root}>
      <div style={style.header}>
        <a href={'/'}>
          <Logo style={style.logo} />
        </a>
      </div>
      <div style={style.content}>
        <svg style={style.icon} width="195" height="128" viewBox="0 0 195 128" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 5.885A5.885 5.885 0 0 1 5.885 0h166.253a5.885 5.885 0 0 1 5.885 5.885v47.468a43.132 43.132 0 0 0-4.414-1.971v-36.67H4.414V114.76c0 .812.659 1.471 1.471 1.471h116.292a43.656 43.656 0 0 0 3.416 4.414H5.885A5.885 5.885 0 0 1 0 114.759V5.885zM114.864 95a43.173 43.173 0 0 0 2.574 12H93.5a4 4 0 0 1-4-4v-4a4 4 0 0 1 4-4h21.364zm5.491-24.38a43.205 43.205 0 0 0-2.175 4.414H91.954a2.207 2.207 0 0 1 0-4.413h28.401zm6.596-8.827a43.714 43.714 0 0 0-3.734 4.414H91.954a2.207 2.207 0 0 1 0-4.414h34.997zM8.824 10.3a2.942 2.942 0 1 1 .008 0h-.008zm8.827 0a2.942 2.942 0 1 1 .01 0h-.01zm8.827 0a2.942 2.942 0 1 1 .01 0h-.01zM11.77 29.425a5.885 5.885 0 0 1 5.885-5.885h55.908a5.885 5.885 0 0 1 5.885 5.885v72.092a5.885 5.885 0 0 1-5.885 5.885H17.655a5.885 5.885 0 0 1-5.885-5.885V29.425zm80.184-2.942h67.678a2.207 2.207 0 1 1 0 4.414H91.954a2.207 2.207 0 0 1 0-4.414zm0 8.827h42.667a2.207 2.207 0 1 1 0 4.414H91.954a2.207 2.207 0 1 1 0-4.414zm0 8.828h52.966a2.207 2.207 0 1 1 0 4.414H91.954a2.207 2.207 0 1 1 0-4.414zm0 8.828h19.127a2.208 2.208 0 0 1 0 4.413H91.954a2.207 2.207 0 0 1 0-4.413z" fill="#D4D4D8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M130.943 91.954c0-15.032 12.186-27.218 27.218-27.218a27.096 27.096 0 0 1 15.441 4.8l-37.859 37.858a27.094 27.094 0 0 1-4.8-15.44zm10.922 21.803a27.097 27.097 0 0 0 16.296 5.415c15.033 0 27.219-12.186 27.219-27.218a27.1 27.1 0 0 0-5.416-16.296l-38.099 38.099zm16.296-57.849c-19.907 0-36.046 16.138-36.046 36.046 0 19.908 16.139 36.046 36.046 36.046 19.908 0 36.046-16.138 36.046-36.046 0-19.908-16.138-36.046-36.046-36.046z" fill="#CD0000"/>
        </svg>
        <div style={style.title}>{text.title[lang]}</div>
        <div style={style.subtitle}>{text.subtitle[lang]}</div>
        <div style={style.info} dangerouslySetInnerHTML={{ __html: text.info[lang] }} />
        <a href="/">
          <button style={style.button}>
            {text.button[lang]}
          </button>
        </a>
      </div>
    </div>
  );
};

export {
  AppCrash,
};
