import { matchPath } from 'react-router';
import { LoadDataFunctionType, LoadDataType } from 'dd-server/site/types';
import { metricsModule } from 'dd-client/site/common/utils/metrics/metrics';
import { QUERY } from 'dd-client/site/common/utils/query/query';

const APP_MODULE = 'deal_page';

const loadData: LoadDataFunctionType = (match, language): LoadDataType => {
  metricsModule.run(({ metrics }) => {
    metrics.increment(`${APP_MODULE}.request`);
  });

  const isHomePage = !!matchPath(match.path as string || '', { path: `/${language}` })?.isExact;

  const queries = [
    {
      isMainQuery: isHomePage,
      queryFunction: QUERY.activeDeals.queryFunction,
      queryKey: [QUERY.activeDeals.queryName, language],
    },
    {
      isMainQuery: false,
      queryFunction: QUERY.themes.queryFunction,
      queryKey: [QUERY.themes.queryName],
    },
  ];

  if (match.params.deal) {
    queries.push({
      isMainQuery: true,
      queryFunction: QUERY.deal.queryFunction,
      queryKey: [QUERY.deal.queryName, language, match.params.deal],
    });
  }

  if (match.params.category) {
    queries.push({
      isMainQuery: true,
      queryFunction: QUERY.categoryDeal.queryFunction,
      queryKey: [QUERY.categoryDeal.queryName, language, match.params.category],
    });
  }

  return {
    appModule: APP_MODULE,
    queries,
  };
};

export {
  loadData,
};
