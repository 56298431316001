import React, { ChangeEvent, FocusEvent, Ref, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { Error } from 'dd-client/site/common/components/Form/common/Error';
import { Info } from 'dd-client/site/common/components/Form/common/Info';
import { Label } from 'dd-client/site/common/components/Form/common/Label';
import { useFormField } from 'dd-client/site/common/hooks/useFormField';
import { Component, Props } from './types';
import './TextArea.scss';

const TextArea: Component = ({
  className,
  control,
  defaultValue,
  error = null,
  info = null,
  isDisabled = false,
  isRequired = false,
  label,
  maxLength,
  name,
  onBlur,
  placeholder,
  rows = 2,
  textAreaRef,
  validationPattern,
}: Props) => {
  const rootClassName = classNames(
    'TextArea',
    {
      'TextArea--Disabled': isDisabled,
      'TextArea--Error': error,
    },
    className,
  );
  const autoGrowWrapperRef: Ref<any> = useRef(null);

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoGrowWrapperRef?.current) {
        autoGrowWrapperRef.current.dataset.replicatedValue = e.currentTarget.value;
      }
    },
    [],
  );

  const { field } = useFormField({ control, defaultValue, isRequired, maxLength, name, validationPattern });
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = useCallback(
    (): void => {
      setHasFocus(true);
    },
    [],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>): void => {
      setHasFocus(false);
      onBlur?.(e);
      field.onBlur();
    },
    [field, onBlur],
  );

  return (
    <div className={rootClassName}>
      {label && (
        <Label
          name={name}
          isRequired={isRequired}
          isAtFieldTop={hasFocus || !!field.value}
        >
          {label}
        </Label>
      )}

      <div
        className="TextArea-AutoGrowWrapper"
        ref={autoGrowWrapperRef}
      >
        <textarea
          {...field}
          className="TextArea-Input"
          disabled={isDisabled}
          id={label ? name : undefined}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onInput={handleInput}
          placeholder={placeholder}
          ref={textAreaRef}
          rows={rows}
        />
      </div>

      {info && (
        <Info>
          {info}
        </Info>
      )}

      {error && (
        <Error>
          {error}
        </Error>
      )}
    </div>
  );
};

export {
  TextArea,
};
