import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyzeAddToCart } from 'dd-client/site/analytics/utils/analyzeAddToCart';
import { BuyButton } from 'dd-client/site/common/components/BuyButton';
import { getAvailabilityProps } from 'dd-client/site/common/utils/availability/availability';
import { Variant as VariantType } from 'dd-client/site/deal/hooks/api/types';
import { SelectedVariant } from './SelectedVariant';
import { Component, Props } from './types';
import { Variant } from './Variant';
import './Variants.scss';

const Variants: Component = ({
  comparison,
  isBuyButtonDisabled = false,
  variants,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const [selectedVariant, setSelectedVariant] = useState<null | VariantType>(null);

  const text = useMemo(
    () => ({
      chooseVariant: t('Choose variant'),
      selected: t('Selected'),
    }),
    [t],
  );

  const handleVariantClick = (variant: VariantType) => setSelectedVariant(variant);

  if (variants.length === 0) {
    return null;
  }

  return (
    <div className="Variants-Wrapper">
      <div className="Variants-TilesSide">
        <div className="Variants-Label">
          {text.chooseVariant}
        </div>
        {variants.map(
          variant => {
            const variantsGroup = [];
            if (variant.name) {
              variantsGroup.push(
                <div
                  className="Variants-GroupName"
                  key={variant.name}
                >
                  {variant.name}
                </div>,
              );
            }

            variantsGroup.push(
              <div
                className="Variants-Group"
                key={`${variant.name}Group`}
              >
                {
                  variant.items.map(
                    item => {
                      const { isSoldOut, isReserved } = getAvailabilityProps(item.availability);

                      return (
                        <Variant
                          calculatedDiscount={item.calculatedDiscount}
                          image={item.image}
                          isReserved={isReserved}
                          isSelected={selectedVariant?.id === item.id}
                          isSoldOut={isSoldOut}
                          key={item.id}
                          name={item.name}
                          onClick={() => handleVariantClick(item)}
                          originalPrice={item.originalPrice}
                          specialPrice={item.specialPrice}
                        />
                      );
                    },
                  )
                }
              </div>,
            );

            return variantsGroup;
          },
        )}
      </div>

      <div className="Variants-SelectedSide">
        <div className="Variants-Label">
          {text.selected}
        </div>

        {selectedVariant && (
          <SelectedVariant
            calculatedDiscount={selectedVariant.calculatedDiscount}
            comparison={comparison}
            image={selectedVariant.image}
            originalPrice={selectedVariant.originalPrice}
            specialPrice={selectedVariant.specialPrice}
            name={selectedVariant.name}
          />
        )}

        <BuyButton
          className="Variants-BuyButton"
          dataTestId="variant-buy-button"
          isDisabled={isBuyButtonDisabled || !selectedVariant}
          navisionId={selectedVariant?.navisionId}
          onAddToCart={() => analyzeAddToCart(selectedVariant?.sku || 0, selectedVariant?.specialPrice || '')}
        />
      </div>
    </div>
  );
};

export {
  Variants,
};
