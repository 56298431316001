import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { logs, LogType } from 'dd-client/site/common/utils/logs/client';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const MESSAGE_INACTIVITY_THRESHOLD = 90 * 1000;
const INTERVAL_DELAY = 60 * 1000;

const useSseUpdatesMonitoring = (): void => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const language = i18n.language as LanguageCode;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const activeDealsState = queryClient.getQueryState([QUERY.activeDeals.queryName, language]);
      const timeFromLastUpdate = Date.now() - (activeDealsState?.dataUpdatedAt || 0);

      if (timeFromLastUpdate > MESSAGE_INACTIVITY_THRESHOLD) {
        logs.log(LogType.ERROR, 'SSE message not received', { seconds: Math.floor(timeFromLastUpdate / 1000) });
      }
    }, INTERVAL_DELAY);

    return () => clearInterval(intervalId);
  }, [language, queryClient]);
};

export {
  useSseUpdatesMonitoring,
};
