import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Countdown } from 'dd-client/site/common/components/Countdown';
import { Link } from 'dd-client/site/common/components/Link';
import { Price } from 'dd-client/site/common/components/Price';
import { ProgressBar } from 'dd-client/site/common/components/ProgressBar';
import { getAvailabilityProps } from 'dd-client/site/common/utils/availability/availability';
import { Component, Props } from './types';
import './CategoryItem.scss';

const HOURS_THRESHOLD_NEW_DEAL = 12;

const CategoryItem: Component = ({
  availability,
  calculatedDiscount,
  category,
  endDate,
  hasPriceRange,
  imageSource,
  isActive,
  isFirstCategory,
  originalPrice,
  slug,
  specialPrice,
  startDate,
  subtitle,
  title,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'CategoryItem',
    {
      'CategoryItem--Active': isActive,
    },
  );
  const { t } = useTranslation();
  const [isNewLabelVisible, setIsNewLabelVisible] = useState(false);
  const startTime = Date.parse(startDate);
  const [countdown, setCountdown] = useState(Date.now() - startTime);
  const hours = countdown / 1000 / 60 / 60;
  const text = useMemo(
    () => ({
      new: t('New'),
    }),
    [t],
  );

  const isNewDeal = useMemo(
    () => (
      !isFirstCategory
      && hours <= HOURS_THRESHOLD_NEW_DEAL
    ),
    [isFirstCategory, hours],
  );

  useEffect(
    () => {
      const timeoutID = setTimeout(
        () => isNewDeal && setIsNewLabelVisible(true),
        1000,
      );

      return () => clearTimeout(timeoutID);
    },
    [isNewDeal],
  );

  useEffect(
    () => {
      const intervalID = setInterval(
        () => setCountdown(Date.now() - startTime),
        1000,
      );
      return () => clearInterval(intervalID);
    },
    [startTime],
  );

  const newLabelClassName = classNames(
    'CategoryItem-NewLabel',
    {
      'CategoryItem-NewLabel--Visible': isNewLabelVisible,
    },
  );

  return (
    <Link
      className={rootClassName}
      url={`/category/${slug}`}
    >
      <div className="CategoryItem-Category">
        {category}

        <Countdown
          className="CategoryItem-Countdown"
          isHighlightedLessThanHour={true}
          endTime={Date.parse(endDate)}
        />
      </div>

      <div className="CategoryItem-Content">
        {imageSource && (
          <div className="CategoryItem-Image">
            <img
              alt={category}
              {...imageSource}
            />
          </div>
        )}

        <div className="CategoryItem-BottomContent">
          {isNewDeal && (
            <div className={newLabelClassName}>
              {text.new}
            </div>
          )}

          <div className="CategoryItem-Name">
            {title}
          </div>

          {subtitle && (
            <div className="CategoryItem-Headline">
              {subtitle}
            </div>
          )}

          <Price
            className="CategoryItem-Price"
            discount={calculatedDiscount}
            hasPriceRange={hasPriceRange}
            isFromInlined={true}
            oldPrice={originalPrice}
            price={specialPrice}
            styleType={Price.StyleType.SMALL}
          />
        </div>
      </div>

      <ProgressBar
        className="CategoryItem-ProgressBar"
        dataTestId="progress-bar-category-item"
        type={ProgressBar.Type.TILE}
        {...getAvailabilityProps(availability)}
      />
    </Link>
  );
};

export {
  CategoryItem,
};
