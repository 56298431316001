import localforage from 'localforage';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { FormField, WriteCommentFormData } from '../CommentList/WriteCommentForm';

enum StorageKey {
  SUBMITTED_DATA = 'submitted-data',
  SUBMITTED_VOTES = 'submitted-votes',
}

enum VoteType {
  DISLIKE = 'dislike',
  LIKE = 'like',
}

const VOTE_TYPE_VALUE: {
  [VoteType.DISLIKE]: -1;
  [VoteType.LIKE]: 1;
  unClickVote: 0;
} = {
  [VoteType.DISLIKE]: -1,
  [VoteType.LIKE]: 1,
  unClickVote: 0,
};

const DEFAULT_COMMENT_DATA = {
  [FormField.EMAIL]: '',
  [FormField.MESSAGE]: '',
  [FormField.MESSAGE_REPLY]: '',
  [FormField.NAME]: '',
  [FormField.TITLE]: '',
};

const clearSubmittedDataLocalForage = async (isReply: boolean): Promise<any> => {
  try {
    const localForageSubmittedData: WriteCommentFormData | null = await localforage.getItem(StorageKey.SUBMITTED_DATA);
    const newLocalForageSubmittedData: WriteCommentFormData = localForageSubmittedData || DEFAULT_COMMENT_DATA;

    if (isReply) {
      newLocalForageSubmittedData.messageReply = '';
    } else {
      newLocalForageSubmittedData.title = '';
      newLocalForageSubmittedData.message = '';
    }

    await localforage.setItem(StorageKey.SUBMITTED_DATA, newLocalForageSubmittedData);
  } catch (error) {
    logger.error(error);
  }
};

export {
  clearSubmittedDataLocalForage,
  DEFAULT_COMMENT_DATA,
  StorageKey,
  VOTE_TYPE_VALUE,
  VoteType,
};
