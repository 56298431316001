import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertSuccessIcon from 'dd-client/site/common/components/Alert/assets/alert-success.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { Component, Props } from './types';
import '../../common.scss';

const SavedPreferencesInfo: Component = ({
  isUnsubscribedFromAll,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const goToHomePage = useGoToPage();

  const text = useMemo(
    () => ({
      goToHomepage: t('Go to homepage'),
      newsletterSaved: t('Newsletter preferences saved'),
      unsubscribedFromAll: t('You were unsubscribed from all newsletters'),
    }),
    [t],
  );

  return (
    <div className="container NewsletterPage">
      <AlertSuccessIcon className="NewsletterPage-Icon NewsletterSubscriptionPage-Icon"/>

      <h1 className="NewsletterSubscriptionPage-Title">
        {
          isUnsubscribedFromAll
            ? text.unsubscribedFromAll
            : text.newsletterSaved
        }
      </h1>

      <Button
        className="NewsletterPage-Button"
        onClick={goToHomePage}
      >
        {text.goToHomepage}
      </Button>
    </div>
  );
};

export {
  SavedPreferencesInfo,
};
