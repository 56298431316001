import React, { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Tooltip  as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { Component, Props } from './types';
import './Tooltip.scss';

enum Place {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

const Tooltip: Component = ({
  className,
  id,
  place = Place.RIGHT,
  text,
}: Props) => {
  const [isMounted, setIsMounted] = useState(false); // specially added so as not to receive a warning from ssr
  const rootClassName = classNames(
    'Tooltip',
    className,
  );

  useEffect(
    () => {
      setIsMounted(true);
    },
    [],
  );

  if (!isMounted) {
    return null;
  }

  return (
    <ReactTooltip
      className={rootClassName}
      openEvents={
        isMobile || isTablet
          ? { click: true }
          : undefined
      }
      globalCloseEvents={{
        clickOutsideAnchor: true,
        scroll: true,
      }}
      id={id}
      place={place}
    >
      {text}
    </ReactTooltip>
  );
};

Tooltip.Place = Place;

export {
  Place,
  Tooltip,
};
