import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { addVote } from './connector';
import { VoteData, VoteResponse } from './types';

const useVote = (): UseMutationResult<AxiosResponse<VoteResponse>, AxiosError, VoteData> => (
  useMutation({ mutationFn: addVote })
);

export {
  useVote,
};

