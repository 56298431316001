import React, { ReactElement } from 'react';
import { FooterNewsletter } from 'dd-client/site/app/components/App/Footer/FooterNewsletter';
import { FooterTopLinks } from 'dd-client/site/app/components/App/Footer/FooterTopLinks';
import { Socials } from 'dd-client/site/app/components/App/Footer/Socials';
import { hasNewsletter } from 'dd-client/site/common/utils/features';
import { Component } from './types';
import './FooterTop.scss';

const FooterTop: Component = (): ReactElement => {
  return (
    <section className="Footer-Top">
      {hasNewsletter && (
        <FooterNewsletter />
      )}
      <FooterTopLinks />
      <Socials />
    </section>
  );
};

export {
  FooterTop,
};
