import { differenceInHours, differenceInMinutes, getDate, getHours, getMinutes, getMonth, getYear } from 'date-fns';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';

const { t } = i18nextInit;
const text = {
  hoursAgo: (countHours: number): string => t(
    'hours',
    { count: countHours, defaultValue: '{count, plural, one {1 hour ago} other {{count} hours ago}}' },
  ),
  minutesAgo: (countMinutes: number): string => t(
    'minutes',
    { count: countMinutes, defaultValue: '{count, plural, one {1 minute ago} other {{count} minutes ago}}' },
  ),
};

const getTimeAgo = (postedTime: number, comparedTime: number | Date = new Date()): string => {
  const differenceHours = differenceInHours(comparedTime, postedTime);

  if (differenceHours < 24 && differenceHours > 0) {
    return text.hoursAgo(differenceHours);
  }

  if (differenceHours === 0) {
    const differenceMinutes = differenceInMinutes(comparedTime, postedTime);
    return text.minutesAgo(differenceMinutes || 1);
  }

  const postedDay = `0${getDate(postedTime)}`.slice(-2);
  const postedMonth = `0${(getMonth(postedTime) + 1)}`.slice(-2);
  const postedYear = getYear(postedTime);
  const postedHours = `0${getHours(postedTime)}`.slice(-2);
  const postedMinutes = `0${getMinutes(postedTime)}`.slice(-2);

  return `${postedDay}.${postedMonth}.${postedYear} ${postedHours}:${postedMinutes}`;
};

export {
  getTimeAgo,
  text,
};
