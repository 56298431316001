import { Logger } from './types';

enum LoggerLevel {
  DEBUG = 'debug',
  ERROR = 'error',
  INFO = 'info',
  LOG = 'log',
  WARN = 'warn',
}

let logger: Logger = {
  debug: () => undefined,
  error: () => undefined,
  info: () => undefined,
  log: () => undefined,
  warn: () => undefined,
};

const setLogger = (nextLogger: Logger): void => {
  logger = nextLogger;
};

export {
  logger,
  LoggerLevel,
  setLogger,
};
