import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Loader } from 'dd-client/site/common/components/Loader';
import { RetryButton } from 'dd-client/site/common/components/RetryButton';
import { PastDealTile } from './PastDealTile';
import { Component, Props } from './types';
import './PastDealsPanel.scss';

const PastDealsPanel: Component = ({
  pastDeals,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      canNotLoad: t('Cannot load search results.'),
      noDealsFound: t('No deals found'),
      retry: t('Retry'),
    }),
    [t],
  );

  const renderLoader = useMemo(
    () => <Loader className="PastDealsPanel-Loader"/>,
    [],
  );

  const handleLoadMoreDeals = useCallback(
    () => !pastDeals.isFetchingNextPage && pastDeals.fetchNextPage(),
    [pastDeals],
  );

  const handleRetryButtonClick = useCallback(
    () => pastDeals.refetch(),
    [pastDeals],
  );

  return (
    <div className="PastDealsPanel">
      {pastDeals.isPending && renderLoader}

      {pastDeals.isSuccess && pastDeals.data.pages[0].totalCount === 0 && (
        <Alert
          styleType={Alert.StyleType.INFO}
          isVisible={true}
        >
          {text.noDealsFound}
        </Alert>
      )}

      {pastDeals.isSuccess && pastDeals.data.pages[0].totalCount > 0 && (
        <InfiniteScroll
          loadMore={handleLoadMoreDeals}
          hasMore={pastDeals.hasNextPage}
        >
          <ul className="PastDealsPanel-DealList">
            {pastDeals.data.pages.map(
              pastDealsGroup => (
                pastDealsGroup.results.map(
                  pastDeal => (
                    <PastDealTile
                      key={pastDeal.slug}
                      {...pastDeal}
                    />
                  ),
                )
              ),
            )}
          </ul>

          {pastDeals.isFetchingNextPage && renderLoader}
        </InfiniteScroll>
      )}

      {pastDeals.isError && (
        <Alert
          isVisible
          styleType={Alert.StyleType.WARNING}
        >
          {text.canNotLoad}

          <RetryButton onClick={handleRetryButtonClick} />
        </Alert>
      )}
    </div>
  );
};

export {
  PastDealsPanel,
};
