import React, { ReactElement } from 'react';
import { LinkGroup } from 'dd-client/site/app/components/App/Header/LinkGroup';
import { LanguageSelector } from 'dd-client/site/i18n/components/LanguageSelector';
import { Component, Props } from './types';
import './TopHeader.scss';

const TopHeader: Component = ({
  headerLinks,
  linkGroup,
}: Props): ReactElement => (
    <div className="TopHeader container">
      <div className="TopHeader-LinksWrapper">
        <ul className="TopHeader-LinkGroup">
          <LinkGroup links={linkGroup[0]}/>
          <LinkGroup links={headerLinks}/>
          <LinkGroup links={linkGroup[1]}/>
        </ul>
      </div>

      <div className="TopHeader-RightPart">
        <LanguageSelector/>
        <ul className="TopHeader-LinkGroup">
          <LinkGroup links={linkGroup[2]}/>
        </ul>
      </div>
    </div>
);

export {
  TopHeader,
};
