import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertSuccessIcon from 'dd-client/site/common/components/Alert/assets/alert-success.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { Component } from './types';
import '../common.scss';

const NewsletterSubscriptionPage: Component = (): ReactElement => {
  const { t } = useTranslation();
  const goToHomePage = useGoToPage();

  const text = useMemo(
    () => ({
      goToHomepage: t('Go to homepage'),
      newsletterConfirmed: t('Your newsletter subscription is confirmed!'),
    }),
    [t],
  );

  return (
    <div className="container NewsletterPage">
      <AlertSuccessIcon className="NewsletterPage-Icon"/>

      <h1 className="NewsletterPage-Title">
        {text.newsletterConfirmed}
      </h1>

      <Button
        className="NewsletterPage-Button"
        onClick={goToHomePage}
      >
        {text.goToHomepage}
      </Button>
    </div>
  );
};

export {
  NewsletterSubscriptionPage,
};
