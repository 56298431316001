import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ApiHeader, getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { stringify } from 'dd-client/site/common/utils/searchParams';
import { PastDeals as ResponsePastDeals } from './connector.types';
import { PastDeals } from './types';
import { PastDealsType } from './';

const ITEMS_PER_PAGE = 12;

const getPastDeals = async (context: QueryFunctionContext<QueryKey, number>): Promise<PastDeals> => {
  const { queryKey, pageParam } = context;
  const type = queryKey[2] as PastDealsType;
  const categoryId = queryKey[3];
  const searchText = queryKey[4];

  const searchParams = stringify({
    ...(
      type === PastDealsType.CATEGORY
        ? { 'fields.categoryId': categoryId }
        : {}
    ),
    _end: pageParam * ITEMS_PER_PAGE,
    _start: (pageParam - 1) * ITEMS_PER_PAGE,
    'fields.query': searchText,
  });

  return getApiRequest({
    context,
    url: `${getApiUrl('deal')}/deal/past${searchParams && `?${searchParams}`}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError);
};

const mapResponseToState = (
  response: AxiosResponse<ResponsePastDeals>,
): PastDeals => {
  const {
    headers,
    data,
  } = response;

  return ({
    results: Array.isArray(data)
      ? data.map(
        ({ fields: { category, commentsCount, startDate, mainImage, pdpUrl, slug, subtitle, title } }) => ({
          categoryName: category,
          commentsCount: commentsCount,
          date: startDate.replace(' ', 'T'),
          mainImage: mainImage,
          pdpUrl: pdpUrl,
          slug: slug,
          subtitle: subtitle,
          title: title,
        }),
      )
      : [],
    totalCount: parseInt(headers[ApiHeader.TOTAL_COUNT] || '0'),
  });
};

export {
  getPastDeals,
};
