import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Component, Props } from './types';
import './Label.scss';

const Label: Component = ({
  children,
  className,
  isAtFieldTop,
  isRequired,
  name,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      optional: t('(optional)'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'Label',
    {
      'Label--Top': isAtFieldTop,
    },
    className,
  );

  return (
    <label
      className={rootClassName}
      htmlFor={name}
    >
      {children}
      {!isRequired && (
        <>
          {' '}
          {text.optional}
        </>
      )}
    </label>
  );
};

export {
  Label,
};
