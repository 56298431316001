import React from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './Info.scss';

const Info: Component = ({
  children,
  className,
}: Props): React.ReactElement => {
  const rootClassName = classNames(
    'Info',
    className,
  );

  return (
    <div className={rootClassName}>
      {children}
    </div>
  );
};

export {
  Info,
};
