import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { UpdateSubscriptionData } from './types';

const update = (updateSubscription: UpdateSubscriptionData): Promise<any> => (
  getApiRequest({
    data: updateSubscription,
    method: 'put',
    url: `${getConfig('url.api.deal')}/subscription/update`,
  })
);

export {
  update,
};
