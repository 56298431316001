import React, { ReactElement, useCallback, useEffect } from 'react';
import Sticky from 'react-stickynode';
import classNames from 'classnames';
import UAParser from 'ua-parser-js';
import { BuyButton } from 'dd-client/site/common/components/BuyButton';
import { Price } from 'dd-client/site/common/components/Price';
import { ProgressBar } from 'dd-client/site/common/components/ProgressBar';
import { getAvailabilityProps } from 'dd-client/site/common/utils/availability/availability';
import { Component, Props } from './types';
import './StickyBar.scss';

const StickyBar: Component = ({
  availability,
  calculatedDiscount,
  className,
  comparison,
  hasPriceRange,
  hasVariants,
  isBuyButtonDisabled = false,
  navisionId,
  onAddToCart,
  onVariantButtonClick,
  originalPrice,
  specialPrice,
  title,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'StickyBar',
    {
      'StickyBar--IOS': UAParser().os.name === 'iOS',
    },
    className,
  );

  const { isReserved, availablePercentage } = getAvailabilityProps(availability);

  const calculateWindowHeight = useCallback(
    () => {
      if (UAParser().os.name === 'iOS') {
        //hack for iOS, prevent stickyBar jumping when scrolling: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
      }
    },
    [],
  );

  useEffect(
    () => {
      calculateWindowHeight();
      window.addEventListener('resize', calculateWindowHeight);

      return () => {
        window.removeEventListener('resize', calculateWindowHeight);
      };
    },
    [calculateWindowHeight],
  );

  return (
    <Sticky
      className={rootClassName}
      activeClass="StickyBar--Active"
      enableTransforms={false}
      innerClass="StickyBar-InnerWrapper"
    >
      <div className="StickyBar-Bar">
        <div className="container">
          <div className="StickyBar-Content">
            <div className="StickyBar-Title">
              {title}
            </div>

            <div className="StickyBar-PriceAndBuyButton">
              <Price
                className="StickyBar-Price"
                comparison={comparison}
                discount={calculatedDiscount}
                hasPriceRange={hasPriceRange}
                isFromInlined={true}
                oldPrice={originalPrice}
                price={specialPrice}
                styleType={Price.StyleType.SMALL}
              />

              {!isReserved && (
                <BuyButton
                  className="StickyBar-BuyButton"
                  dataTestId="sticky-bar-buy-button"
                  hasVariants={hasVariants}
                  isDisabled={isBuyButtonDisabled}
                  navisionId={navisionId}
                  onVariantButtonClick={onVariantButtonClick}
                  onAddToCart={onAddToCart}
                />
              )}
            </div>

            <ProgressBar
              availablePercentage={availablePercentage}
              className="StickyBar-ProgressBar"
              dataTestId="progress-bar-sticky-bar"
              type={ProgressBar.Type.TILE}
              isReserved={isReserved}
            />
          </div>
        </div>
      </div>
    </Sticky>
  );
};

export {
  StickyBar,
};
