import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CartIcon from 'dd-client/site/common/assets/icons/cart.svg';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { ProductLabels } from 'dd-client/site/common/components/ProductLabels';
import { formatPrice } from 'dd-client/site/common/utils/price/price';
import { Component, Props } from './types';
import './Item.scss';

const Item: Component = ({
  className,
  labels,
  name,
  price,
  specialPrice,
  stock,
  thumbnails,
  url,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      stock: t('Stock'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'AccessoriesProductItem',
    className,
  );

  return (
    <div
      className={rootClassName}>
      <a
        className="AccessoriesProductItem-LinkImage"
        href={url}
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt={name}
          className="AccessoriesProductItem-Image"
          src={thumbnails.standard}
          srcSet={`${thumbnails.large} 2x`}
        />
      </a>

      <div className="AccessoriesProductItem-DescWrapper">
        <div className="AccessoriesProductItem-TextWrapper">
          {labels.length > 0 && (
            <ProductLabels
              className="AccessoriesProductItem-Labels"
              labels={labels}
            />
          )}

          <a
            className="AccessoriesProductItem-Title"
            target="_blank"
            href={url}
            rel="noreferrer"
          >
            {name}
          </a>

          <div className="AccessoriesProductItem-Stock">
            {text.stock}: {stock}
          </div>
        </div>
        <div className="AccessoriesProductItem-PriceButtonWrapper">
          <div className="AccessoriesProductItem-PriceWrapper">
            <div className="AccessoriesProductItem-Price">
              {specialPrice ? formatPrice(specialPrice) : formatPrice(price)}
            </div>

            {specialPrice && (
              <div className="AccessoriesProductItem-OldPrice">
                {formatPrice(price)}
              </div>
            )}
          </div>
          <ButtonIcon
            className="AccessoriesProductItem-Button"
            href={url}
            icon={<CartIcon/>}
            size={ButtonIcon.Size.SMALL}
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
};

export { Item };
