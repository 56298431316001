import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { mapDealToState } from 'dd-client/site/deal/hooks/api/dealMapper';
import { Deal as ResponseDeal } from '../connector.types';
import { Deal } from '../types';

const getCategoryDeal = (context: QueryFunctionContext): Promise<Deal> => (
  getApiRequest({
    context,
    url: `${getApiUrl('deal')}/deal/by/category_slug/${context.queryKey[2]}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<ResponseDeal>,
): Deal => {
  const {
    data,
  } = response;

  return mapDealToState(data);
};

export {
  getCategoryDeal,
};
