import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import xor from 'lodash.xor';
import { Section } from './Section';
import { Component, Props } from './types';

const Accordion: Component = ({
  className,
  data,
  defaultOpenIndexArray,
}: Props) => {
  const [openIndexArray, setOpenIndexArray] = useState(defaultOpenIndexArray || []);

  const rootClassName = classNames(
    'Accordion',
    className,
  );

  const handleSectionClick = useCallback(
    (index: number): void => {
      const nextValue = xor(openIndexArray, [index]);
      setOpenIndexArray(nextValue);
    },
    [openIndexArray, setOpenIndexArray],
  );

  return (
    <div className={rootClassName}>
      {data.map(
        (item, index) => (
          <Section
            content={item.content}
            index={index}
            isOpen={openIndexArray.includes(index)}
            key={item.header}
            onClick={() => handleSectionClick(index)}
            title={item.header}
          />
        ),
      )}
    </div>
  );
};

export {
  Accordion,
};
