import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Button, Size as ButtonSize, StyleType as ButtonStyleType } from 'dd-client/site/common/components/Button';
import { capitalizeFirstLetter } from 'dd-client/site/common/utils/capitalizeFirstLetter';
import { ComponentType, Props } from './';
import './ButtonIcon.scss';

const Size = ButtonSize;
const StyleType = ButtonStyleType;

const ButtonIcon: ComponentType = ({
  icon,
  className,
  size = Size.DEFAULT,
  ...props
}: Props): ReactElement => {
  const rootClassName = classNames(
    'ButtonIcon',
    {
      [`ButtonIcon--${capitalizeFirstLetter(size || '')}`]: size,
    },
    className,
  );

  return (
    <Button
      {...props}
      className={rootClassName}
    >
      {icon}
    </Button>
  );
};

ButtonIcon.StyleType = StyleType;
ButtonIcon.Size = Size;

export {
  ButtonIcon,
  Size,
  StyleType,
};
