import React, { ReactElement } from 'react';
import classNames from 'classnames';
import ArrowLeft from 'dd-client/site/common/assets/icons/arrow-left.svg';
import ArrowRight from 'dd-client/site/common/assets/icons/arrow-right.svg';
import { ArrowTypeClassName, ArrowTypeIcon, ComponentType, Props } from './types';
import './Arrow.scss';

enum ArrowType {
  NEXT = 'next',
  PREV = 'prev',
}

const ARROW_DISABLED_CLASS_NAME = 'Slider-Arrow--Disabled';

const ARROW_TYPE_CLASS_NAME: ArrowTypeClassName = {
  [ArrowType.NEXT]: 'Slider-Arrow--Next',
  [ArrowType.PREV]: 'Slider-Arrow--Prev',
};

const ARROW_TYPE_ICON: ArrowTypeIcon = {
  [ArrowType.NEXT]: ArrowRight,
  [ArrowType.PREV]: ArrowLeft,
};

const Arrow: ComponentType = ({
  className,
  setRef,
  type,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Slider-Arrow',
    ARROW_TYPE_CLASS_NAME[type],
    className,
  );

  const Icon = ARROW_TYPE_ICON[type];

  return (
    <button
      className={rootClassName}
      ref={setRef}
    >
      <Icon className="Slider-ArrowIcon" />
    </button>
  );
};

Arrow.ArrowType = ArrowType;

export {
  Arrow,
  ARROW_DISABLED_CLASS_NAME,
  ARROW_TYPE_CLASS_NAME,
  ARROW_TYPE_ICON,
  ArrowType,
};
