import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { createReply } from './connector';
import { SubmitReplyData } from './types';

const useSubmitReply = (): UseMutationResult<any, AxiosError, SubmitReplyData> => (
  useMutation({ mutationFn: createReply })
);

export {
  useSubmitReply,
};

