import { useEffect } from 'react';
import { useCurrentTheme } from 'dd-client/site/common/hooks/useCurrentTheme';
import { mapThemeDataToCSSVars, THEME_HTML_STYLE_TAG_ID } from 'dd-client/site/common/utils/theme/theme';

const useThemeInjection = (): void => {
  const currentTheme = useCurrentTheme();

  useEffect(
    () => {
      const previousThemeStyleTag = document.getElementById(THEME_HTML_STYLE_TAG_ID);

      if (previousThemeStyleTag) {
        previousThemeStyleTag.remove();
      }

      if (currentTheme) {
        const themeCSSVars = mapThemeDataToCSSVars(currentTheme.manifest);

        const newStyleTag = document.createElement('style');
        newStyleTag.setAttribute('id', THEME_HTML_STYLE_TAG_ID);
        newStyleTag.innerText = `:root {${themeCSSVars}}`;
        document.head.appendChild(newStyleTag);
      }
    },
    [currentTheme],
  );
};

export {
  useThemeInjection,
};
