import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './Item.scss';

const NEWS_ITEM_IMAGE_SIZE = {
  height: 394,
  width: 688,
};

const Item: Component = ({
  className,
  headline,
  imageUrl,
  title,
  url,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'NewsItem',
    className,
  );
  const urlCdn = getConfig('url.cdn');

  return (
    <a
      className={rootClassName}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <span className="NewsItem-TransparentBorder" />

      <img
        alt={title}
        className="NewsItem-Image"
        src={`${urlCdn}/${NEWS_ITEM_IMAGE_SIZE.width}x${NEWS_ITEM_IMAGE_SIZE.height}/${imageUrl}`}
        srcSet={`${urlCdn}/${NEWS_ITEM_IMAGE_SIZE.width * 2}x${NEWS_ITEM_IMAGE_SIZE.height * 2}/${imageUrl} 2x`}
      />

      <div className="NewsItem-BottomWrapper">
        <h2 className="NewsItem-Title">
          {title}
        </h2>

        <div
          className="NewsItem-Headline"
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
      </div>
    </a>
  );
};

export {
  Item,
};
