import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { SubmitReplyData } from './types';

const createReply = ({ commentId, ...rest }: SubmitReplyData): Promise<any> => (
  getApiRequest({
    data: rest,
    method: 'post',
    url: `${getConfig('url.api.comment')}/comment/reply/${commentId}`,
  })
);

export {
  createReply,
};
