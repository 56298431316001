import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './ToggleSwitch.scss';

const ToggleSwitch: Component = ({
  className,
  isChecked,
  label,
  onChange,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'ToggleSwitch',
    {
      'ToggleSwitch--Checked': isChecked,
    },
    className,
  );

  return (
    <label className={rootClassName}>
      <input
        className="ToggleSwitch-Input"
        checked={isChecked}
        onChange={(e) => onChange(e.currentTarget.checked)}
        type="checkbox"
      />
      <span className="ToggleSwitch-Slider" />
      {label}
    </label>
  );
};

export {
  ToggleSwitch,
};
