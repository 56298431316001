import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ApiHeader, getApiRequest, getApiUrl } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { stringify } from 'dd-client/site/common/utils/searchParams';
import { NewsType } from 'dd-client/site/news/hooks/useNews/useNews';
import { FetchResponseBody, NewsItem } from './connector.types';
import { News } from './types';

const ITEMS_PER_PAGE = 12;

const getNews = async (context: QueryFunctionContext<QueryKey, number>): Promise<News> => {
  const { queryKey, pageParam } = context;
  const type = queryKey[2] as NewsType;
  const categoryId = queryKey[3];

  const searchParams = (type === NewsType.CATEGORY)
    ? `type=categories&categories[]=${categoryId}`
    : stringify({
      _end: pageParam * ITEMS_PER_PAGE,
      _start: (pageParam - 1) * ITEMS_PER_PAGE,
    });

  return getApiRequest({
    context,
    url: `${getApiUrl('static')}/news${searchParams && `?${searchParams}`}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError);
};

const mapResponseToState = (
  response: AxiosResponse<FetchResponseBody>,
): News => {
  const {
    data,
    headers,
  } = response;

  return ({
    results: Array.isArray(data)
      ? data.map((news: NewsItem) => ({
        headline: news.headLine,
        id: news.id,
        imageUrl: news.image,
        leadText: news.leadText,
        title: news.title,
        url: news.url,
      }))
      : [],
    totalCount: parseInt(headers[ApiHeader.TOTAL_COUNT] || '0'),
  });
};

export {
  getNews,
};
