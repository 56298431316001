import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import BellOffIcon from 'dd-client/site/common/assets/icons/bell-off.svg';
import BellRingIcon from 'dd-client/site/common/assets/icons/bell-ring.svg';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { Component, Props } from './types';
import './WebPushButton.scss';

enum GetbackPermissionStatus {
  DEFAULT = 'default',
  GRANTED = 'granted',
  DENIED = 'denied',
}

const WebPushButton: Component = ({
  className,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'WebPushButton',
    className,
  );

  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      activate: t('Activate'),
      deactivate: t('Deactivate'),
      pleaseAllow: t('Please allow notifications for this page: <a target="_blank" href="https://static.getback.ch/assets/push/manual/de.pdf">Instructions</a>'),
      unfortunatelyYourDevice: t('Unfortunately, your device / browser does not support notifications.'),
    }),
    [t],
  );

  const [isSubscribedToNotifications, setIsSubscribedToNotifications] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [alert, setAlert] = useState<string | null>(null);
  const [isChangingNotificationStatus, setIsChangingNotificationStatus] = useState(false);
  const wasInitialGetbackLoaded = useRef(false);

  const setupNotificationButton = useCallback(
    () => {
      if (!window.Getback || !window.Getback.PushWidget) {
        // Browser does not support push messages.
        setAlert(text.unfortunatelyYourDevice);
        return;
      }
      const permissionStatus: GetbackPermissionStatus = window.Getback.PushWidget.permissionStatus;
      wasInitialGetbackLoaded.current = true;

      if (permissionStatus === GetbackPermissionStatus.DENIED) {
        setAlert(text.pleaseAllow);
        setIsDisabled(true);
        setIsSubscribedToNotifications(false);
      } else if (window.gb_is_push_subscriber) {
        setAlert(null);
        setIsDisabled(false);
        setIsSubscribedToNotifications(true);
      } else {
        setAlert(null);
        setIsDisabled(false);
        setIsSubscribedToNotifications(false);
      }

      setIsChangingNotificationStatus(false);
    },
    [text.pleaseAllow, text.unfortunatelyYourDevice],
  );

  const handleClick = useCallback(
    () => {
      setIsChangingNotificationStatus(true);

      window.gb_is_push_subscriber
        ? window.gb_disable_push?.(setupNotificationButton)
        : window.gb_enable_push?.(true, setupNotificationButton);
    },
    [setupNotificationButton],
  );

  useEffect(
    () => {
      if (window.getback_loaded) {
        // Getback has already loaded. Push message functions should be available.
        setupNotificationButton();
      } else {
        // Getback is not yet available. 'getback_loaded' in window gets fired as soon as Getback is loaded
        window.addEventListener(
          'getback_loaded',
          setupNotificationButton,
        );
      }

      return () => {
        window.removeEventListener('getback_loaded', setupNotificationButton);
      };
    },
    [setupNotificationButton],
  );

  useEffect(
    () => {
      const intervalID = setInterval(
        () => {
          if (wasInitialGetbackLoaded.current) {
            setupNotificationButton();
          }
        },
        500,
      );
      return () => clearInterval(intervalID);
    },
    [setupNotificationButton],
  );

  return (
    <>
      <Button
        className={rootClassName}
        iconPosition={Button.IconPosition.LEFT}
        isDisabled={isDisabled || isChangingNotificationStatus}
        onClick={handleClick}
        styleType={
          isSubscribedToNotifications
            ? Button.StyleType.LIGHT
            : Button.StyleType.PRIMARY
        }
      >
        {
          isSubscribedToNotifications
            ? (
              <>
                <BellOffIcon /> {text.deactivate}
              </>
            )
            : (
              <>
                <BellRingIcon /> {text.activate}
              </>
            )
        }
      </Button>

      {alert && (
        <Alert
          isVisible={true}
          styleType={Alert.StyleType.WARNING}
          className="WebPushButton-Alert"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: alert,
            }}
          />
        </Alert>
      )}
    </>
  );
};

export {
  WebPushButton,
};
