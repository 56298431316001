import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'dd-client/site/routes/components/Router';
import { PageMatch } from './';

const usePageMatch = (): PageMatch => {
  const { pathname } = useLocation();

  return useMemo(
    () => {
      const homePageMatch = matchPath(pathname, { path: PATHS.home });
      const pastDealsPageMatch = matchPath(pathname, { path: PATHS.pastDeals });
      const newsPageMatch = matchPath(pathname, { path: PATHS.news });
      const dealPageMatch = matchPath<{ deal?: string }>(pathname, { path: PATHS.deal });
      const categoryPageMatch = matchPath<{ category?: string }>(pathname, { path: PATHS.category });
      const staticPageMatch = matchPath<{ page?: string }>(pathname, { path: PATHS.staticPage });

      return {
        categoryParam: categoryPageMatch?.params.category,
        dealParam: dealPageMatch?.params.deal,
        isCategoryPage: !!categoryPageMatch?.isExact,
        isDealPage: !!dealPageMatch?.isExact,
        isHomePage: !!homePageMatch?.isExact,
        isNewsPage: !!newsPageMatch?.isExact,
        isPastDealsPage: !!pastDealsPageMatch?.isExact,
        isStaticPage: !!staticPageMatch?.isExact,
        staticPageParam: staticPageMatch?.params.page,
      };
    }, [pathname],
  );
};

export {
  usePageMatch,
};
