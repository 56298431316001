import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Price } from 'dd-client/site/common/components/Price';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './SelectedVariant.scss';

const SELECTED_VARIANT_IMAGE_SIZE = {
  height: 0,
  width: 690,
};

const SelectedVariant: Component = ({
  calculatedDiscount,
  className,
  comparison,
  image,
  originalPrice,
  specialPrice,
  name,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'SelectedVariant',
    className,
  );
  const urlCdn = getConfig('url.cdn');

  return (
    <div
      className={rootClassName}
      data-testid="selected-variant"
    >
      {image && (
        <img
          alt={name}
          className="SelectedVariant-Image"
          src={`${urlCdn}/${SELECTED_VARIANT_IMAGE_SIZE.width}x${SELECTED_VARIANT_IMAGE_SIZE.height}/${image}`}
          srcSet={`${urlCdn}/${SELECTED_VARIANT_IMAGE_SIZE.width * 2}x${SELECTED_VARIANT_IMAGE_SIZE.height * 2}/${image} 2x`}
        />
      )}

      <div className="SelectedVariant-Name">
        {name}
      </div>

      <Price
        className="SelectedVariant-Price"
        discount={calculatedDiscount}
        styleType={Price.StyleType.VARIANT}
        oldPrice={originalPrice}
        price={specialPrice}
        comparison={comparison}
        tooltipId={`${name}-${specialPrice}`}
      />
    </div>
  );
};

export {
  SelectedVariant,
};
