import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dd-client/site/common/components/Button';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import DislikeIcon from './assets/dislike.svg';
import DislikeOutlineIcon from './assets/dislike-outline.svg';
import LikeIcon from './assets/like.svg';
import LikeOutlineIcon from './assets/like-outline.svg';
import ReplyIcon from './assets/reply.svg';
import { Component, Props } from './types';
import './Voting.scss';


const Voting: Component = ({
  areVotingButtonsDisabled,
  rating,
  wasDisliked,
  wasLiked,
  onDislikeButtonClick,
  onLikeButtonClick,
  onReplyButtonClick,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      reply: t('Reply'),
    }),
    [t],
  );

  const handleReplyButtonClick = useCallback(
    () => {
      onReplyButtonClick?.();
    },
    [onReplyButtonClick],
  );

  const handleDislikeButtonClick = useCallback(
    () => {
      onDislikeButtonClick?.();
    },
    [onDislikeButtonClick],
  );

  const handleLikeButtonClick = useCallback(
    () => {
      onLikeButtonClick?.();
    },
    [onLikeButtonClick],
  );

  return (
    <div className="Voting">
      {rating !== undefined && (
        <span className="Voting-Rating">
          {rating}
        </span>
      )}

      <ButtonIcon
        className="Voting-Button Voting-Button--Like"
        icon={
          wasLiked
            ? <LikeIcon />
            : <LikeOutlineIcon />
        }
        onClick={handleLikeButtonClick}
        size={ButtonIcon.Size.SMALL}
        styleType={
          wasLiked
            ? ButtonIcon.StyleType.PRIMARY
            : ButtonIcon.StyleType.LIGHT
        }
        isDisabled={areVotingButtonsDisabled}
      />

      <ButtonIcon
        className="Voting-Button Voting-Button--Dislike"
        icon={
          wasDisliked
            ? <DislikeIcon />
            : <DislikeOutlineIcon />
        }
        onClick={handleDislikeButtonClick}
        size={ButtonIcon.Size.SMALL}
        styleType={
          wasDisliked
            ? ButtonIcon.StyleType.PRIMARY
            : ButtonIcon.StyleType.LIGHT
        }
        isDisabled={areVotingButtonsDisabled}
      />

      <Button
        className="Voting-ReplyButton"
        iconPosition={Button.IconPosition.LEFT}
        onClick={handleReplyButtonClick}
        styleType={Button.StyleType.GHOST}
        size={Button.Size.SMALL}
        isDisabled={areVotingButtonsDisabled}
      >
        <ReplyIcon />
        {text.reply}
      </Button>
    </div>
  );
};

export {
  Voting,
};
