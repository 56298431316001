import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { Head } from 'dd-client/site/app/components/App/Head';
import { Button } from 'dd-client/site/common/components/Button';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { isErrorPage } from 'dd-client/site/common/utils/global';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { Component, Props } from './types';
import './ErrorPage.scss';

const ErrorPage: Component = ({
  analytics,
  errorCode,
  imageUrl,
  subtitle,
  title,
  videoUrl,
}: Props): ReactElement => {
  const setIsErrorPage = useSetRecoilState(isErrorPage);
  const { t } = useTranslation();
  const goToHomePage = useGoToPage();
  const { isHomePage } = usePageMatch();

  const text = useMemo(
    () => ({
      error: t('Error'),
      goToHomepage: t('Go to homepage'),
      reloadPage: t('Reload page'),
    }),
    [t],
  );

  useEffect(() => {
    setIsErrorPage(true);

    return () => setIsErrorPage(false);
  }, [setIsErrorPage]);

  const {
    buttonText,
    handleButtonClick,
  } = useMemo(
    () => {
      if (isHomePage) {
        return {
          buttonText: text.reloadPage,
          handleButtonClick: () => window.location.reload(),
        };
      }

      return {
        buttonText: text.goToHomepage,
        handleButtonClick: goToHomePage,
      };
    },
    [goToHomePage, isHomePage, text.goToHomepage, text.reloadPage],
  );

  return (
    <div className="ErrorPage">
      <Head
        analytics={analytics}
        title={title}
      />

      {videoUrl && (
        <video
          autoPlay={true}
          className="ErrorPage-Video"
          muted={true}
          loop={true}
          poster={imageUrl}
        >
          <source src={videoUrl} type="video/webm" />
        </video>
      )}

      <div className="ErrorPage-Details">
        <h1 className="ErrorPage-Title">
          {title} — {text.error} {errorCode}
        </h1>
        <span>{subtitle}</span>
        <Button
          className="ErrorPage-Button"
          onClick={handleButtonClick}
          styleType={Button.StyleType.PRIMARY}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export {
  ErrorPage,
};
