import { useTranslation } from 'react-i18next';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { News } from './types';

enum NewsType {
  ALL = 'all',
  CATEGORY = 'category',
}

const useNews = (type: NewsType, categoryId?: number): UseInfiniteQueryResult<InfiniteData<News>, string> => {
  const { i18n: { language } } = useTranslation();

  return useInfiniteQuery({
    enabled: (type === NewsType.CATEGORY && Number.isInteger(categoryId))
      || type == NewsType.ALL,
    getNextPageParam: (lastPage: News, allPages: Array<News>) => {
      const allLoadedNews = allPages.reduce(
        (result: number, news) => (
          result + news.results.length
        ),
        0,
      );

      if (allLoadedNews < lastPage.totalCount) {
        return allPages.length + 1;
      }

      return;
    },
    initialPageParam: 1,
    queryFn: QUERY.news.queryFunction,
    queryKey: [QUERY.news.queryName, language, type, categoryId],
  });
};

export {
  NewsType,
  useNews,
};

