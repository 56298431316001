import { FieldError } from 'react-hook-form';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';

const { t } = i18nextInit;
const text = {
  errorEmailPattern: t('Entered value does not match email format'),
  errorPostalCodePattern: t('Entered value does not match postal code format'),
  fieldRequired: t('Field is required'),
  tooManyCharacters: t('Too Many Characters. Max characters: '),
};

const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;
const POSTAL_CODE_PATTERN = /^([0-9]{4})$/;

const inputRule = (
  // specially added type 'any' for error. In new version of react-hook-form, types for FieldError are wrong
  // https://stackoverflow.com/questions/73251057/react-hook-form-property-type-is-missing-in-type-mergefielderror-fielderror
  error?: FieldError | any,
): string | null => {
  if (error?.type === 'required') {
    return text.fieldRequired;
  }

  if (error?.type === 'manual' && error?.message) {
    return error.message;
  }

  return null;
};

const emailRule = (
  error?: FieldError | any,
): string | null => {
  if (error?.type === 'pattern') {
    return text.errorEmailPattern;
  }

  return inputRule(error);
};

const postalCodeRule = (
  error?: FieldError | any,
): string | null => {
  if (error?.type === 'pattern') {
    return text.errorPostalCodePattern;
  }

  return inputRule(error);
};

const maxLengthRule = (
  maxLength: number,
  error?: FieldError | any,
): string | null => {
  if (error?.type === 'maxLength') {
    return `${text.tooManyCharacters} ${maxLength}`;
  }

  return inputRule(error);
};

export {
  EMAIL_PATTERN,
  emailRule,
  inputRule,
  maxLengthRule,
  POSTAL_CODE_PATTERN,
  postalCodeRule,
};
