import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { StaticPage } from './types';

const useStaticPage = (slug?: string): UseQueryResult<StaticPage, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    enabled: !!slug,
    queryFn: QUERY.staticPage.queryFunction,
    queryKey: [QUERY.staticPage.queryName, language, slug],
  });
};


export {
  useStaticPage,
};

