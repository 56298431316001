import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import classNames from 'classnames';
import { Accessories } from './Accessories';
import { Description } from './Description';
import { Details } from './Details';
import { Component, Props } from './types';

const ProductDetails: Component = ({
  accessories,
  apps,
  className,
  description,
  detail,
  links,
  media,
  scopeOfDelivery,
  specification,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      accessories: t('Accessories'),
      description: t('Description'),
      details: t('Details'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'ProductDetails',
    'container',
    className,
  );

  return (
    <div
      className={rootClassName}
    >
      <Tabs
        className="Tabs"
        selectedTabClassName="Tabs-Tab--Selected"
      >
        <TabList className="Tabs-TabList">
          <Tab className="Tabs-Tab">
            {text.description}
          </Tab>

          <Tab className="Tabs-Tab">
            {text.details}
          </Tab>

          {accessories.length > 0 && (
            <Tab className="Tabs-Tab">
              {text.accessories}
            </Tab>
          )}
        </TabList>

        <TabPanel>
          <Description
            description={description}
            media={media}
          />
        </TabPanel>

        <TabPanel>
          <Details
            apps={apps}
            detail={detail}
            links={links}
            scopeOfDelivery={scopeOfDelivery}
            specification={specification}
          />
        </TabPanel>

        {accessories.length > 0 && (
          <TabPanel>
            <Accessories accessories={accessories}/>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export {
  ProductDetails,
};
