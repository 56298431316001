import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'dd-client/site/common/components/Spinner';
import { Component, Props } from './types';
import './CommentsSkeleton.scss';

const CommentsSkeleton: Component = ({
  className,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      comments: t('Comments'),
    }),
    [t],
  );

  return (
    <div className={className}>
      <div className="container">
        <div className="SectionTitle Comments-Title">
          {text.comments}
        </div>

        <div className="Comments-BoxWrapper">
          {[0, 1].map(item => (
            <div
              className="Comments-CommentBox"
              key={item}
            >
              <Spinner className="CommentsSkeleton-Spinner"/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export {
  CommentsSkeleton,
};
