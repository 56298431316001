import { VariantsGroup } from 'dd-client/site/deal/hooks/api/types';

const getHasPriceRange = (variants: Array<VariantsGroup>): boolean => {
  const variantsPrices = variants.reduce(
    (result: Array<string>, variantGroup) => {
      variantGroup.items.forEach(
        variant => {
          result.push(variant.specialPrice);
        },
      );
      return result;
    }, [],
  );

  return Array.from(new Set(variantsPrices)).length > 1;
};

export {
  getHasPriceRange,
};
