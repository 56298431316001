import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import { SeverityLevel } from '@sentry/types';
import localforage from 'localforage';
import isObject from 'lodash.isobject';
import { v4 } from 'uuid';
import { WriteCommentFormData } from 'dd-client/site/deal/components/DealPage/Comments/CommentList/WriteCommentForm';
import { StorageKey } from 'dd-client/site/deal/components/DealPage/Comments/utils/comments';
import { logger, LoggerLevel } from '../logger';
import { Logger, LoggerExtras } from '../types';

const user: User = {
  id: v4(),
};

const loadAdditionalUserData = async () => {
  //TODO: This is temporary solution which helps to identify user in logs. It should be improved when working on tracing headers.
  try {
    const localForageSubmittedData: WriteCommentFormData | null = await localforage.getItem(StorageKey.SUBMITTED_DATA);
    user.email = localForageSubmittedData?.email || '';
    user.username = localForageSubmittedData?.name || '';
  } catch (e) {
    logger.error(e);
  }
};

loadAdditionalUserData();

const sentryLogger: Logger = {
  [LoggerLevel.DEBUG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage('debug', message, extras),
  [LoggerLevel.ERROR]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage('error', message, extras),
  [LoggerLevel.INFO]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage('info', message, extras),
  [LoggerLevel.LOG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage('log', message, extras),
  [LoggerLevel.WARN]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage('warning', message, extras),
};

const captureMessage = (
  level: SeverityLevel,
  message: string,
  extras: LoggerExtras = {},
): void => {
  const { fingerprint, ...otherExtras } = extras;
  const { error } = otherExtras;

  Sentry.withScope(scope => {
    scope.setLevel(level);
    scope.setUser(user);

    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }

    if (isObject(otherExtras)) {
      scope.setExtras(otherExtras);
    }

    if (error && level === 'error') {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(message);
    }
  });
};

export {
  sentryLogger,
};
