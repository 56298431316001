import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { VoteData } from './types';

const addVote = ({ commentId, vote }: VoteData): Promise<any> => (
  getApiRequest({
    data: { vote },
    method: 'put',
    url: `${getConfig('url.api.comment')}/comment/vote/${commentId}`,
  })
);

export {
  addVote,
};
