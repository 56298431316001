import { generatePath, matchPath } from 'react-router';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { PathParams, PATHS } from 'dd-client/site/routes/components/Router/paths';

const replaceParamsInPath = (pathname: string, pathParams: PathParams): string => {
  const { category, deal, lang, page } = pathParams;
  const matchedPath = Object.values(PATHS).find((path) => matchPath(pathname, { path })?.isExact);
  if (!matchedPath) {
    return pathname;
  }

  let result = pathname;

  try {
    result = generatePath(matchedPath, { category, deal, lang, page });
  } catch (e) {
    logger.error(e);
    return `/${lang}`;
  }

  return result;
};

export {
  replaceParamsInPath,
};
