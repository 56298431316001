import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from 'dd-client/site/error/components/ErrorPage';
import { imageUrl, videoUrl } from 'dd-client/site/error/components/NotFoundPage/assets';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { Component } from './types';

const NotFoundPage: Component = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const de = i18n.getFixedT(LanguageCode.DE);

  const text = useMemo(
    () => ({
      pageMightBeDown: t('Page might be temporarily down or it may have moved to a new address'),
      pageNotFound: t('Page not found'),
      pageNotFoundDE: de('Page not found'),
    }),
    [de, t],
  );

  return (
    <ErrorPage
      analytics={{
        title: text.pageNotFoundDE,
      }}
      errorCode={404}
      imageUrl={imageUrl}
      subtitle={text.pageMightBeDown}
      title={text.pageNotFound}
      videoUrl={videoUrl}
    />
  );
};

export {
  NotFoundPage,
};
