import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { replaceParamsInPath } from 'dd-client/site/common/utils/replaceParamsInPath/replaceParamsInPath';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import { getAvailableLanguages } from 'dd-client/site/i18n/utils/language';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useStaticPage } from 'dd-client/site/static/hooks/useStaticPage';
import { Props } from './types';
import './LanguageSelector.scss';

const LanguageSelector = ({ onClick }: Props): ReactElement => {
  const { i18n } = useTranslation();
  const { staticPageParam } = usePageMatch();

  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;
  const staticPage = useStaticPage(staticPageParam);
  const staticPageData = staticPage?.data;
  const history = useHistory();

  const handleChange = useCallback(
    (code: LanguageCode): void => {
      const nextUrl = replaceParamsInPath(
        location.pathname,
        {
          category: currentDealData?.slugs.category[code],
          deal: currentDealData?.slugs.deal[code],
          lang: code,
          page: staticPageData?.slugs[code],
        },
      );

      i18n.changeLanguage(code);
      history.push(nextUrl);
      onClick?.();
    },
    [currentDealData?.slugs.category, currentDealData?.slugs.deal, history, i18n, onClick, staticPageData?.slugs],
  );

  return (
    <div className="LanguageSelector">
      <ul className="LanguageSelector-List">
        {getAvailableLanguages().map(
          ({ code, label }) => {
            const elementClassName = classNames(
              'LanguageSelector-Item',
              {
                'LanguageSelector-Item--Active': i18n.language === code,
              },
            );

            return (
              <li
                key={code}
                onClick={() => handleChange(code)}
                className={elementClassName}
              >
                <a
                  className="LanguageSelector-Link"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  {label}
                </a>
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};

export {
  LanguageSelector,
};
