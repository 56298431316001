import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isPast, toDate } from 'date-fns';
import { analyzeAddToCart } from 'dd-client/site/analytics/utils/analyzeAddToCart';
import fallbackImage from 'dd-client/site/common/assets/fallback-image.png';
import { BuyButton } from 'dd-client/site/common/components/BuyButton';
import { Loader } from 'dd-client/site/common/components/Loader';
import { Modal } from 'dd-client/site/common/components/Modal';
import { Price } from 'dd-client/site/common/components/Price';
import { ProgressBar } from 'dd-client/site/common/components/ProgressBar';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { useIsStickyBar } from 'dd-client/site/common/hooks/useIsStickyBar';
import { usePrevious } from 'dd-client/site/common/hooks/usePrevious';
import { getAvailabilityProps } from 'dd-client/site/common/utils/availability/availability';
import { hasNewsletter } from 'dd-client/site/common/utils/features';
import { isPastDeal } from 'dd-client/site/common/utils/pastDeal/pastDeal';
import { isServer } from 'dd-client/site/common/utils/server';
import { getConfig } from 'dd-client/site/config/utils/config';
import { getHasPriceRange } from 'dd-client/site/deal/utils/variants';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { BulletPoints } from './BulletPoints';
import { DeliveryInformation } from './DeliveryInformation';
import { ReservedInformation } from './ReservedInformation';
import { SeeDealButton } from './SeeDealButton';
import { SoldOutBox } from './SoldOutBox';
import { SoldOutInformation } from './SoldOutInformation';
import { Stickers } from './Stickers';
import { StickyBar } from './StickyBar';
import { Component, Props } from './types';
import { Variants } from './Variants';
import { Video } from './Video';
import './ProductMain.scss';

const MAIN_IMAGE_SIZE = {
  height: 520,
  width: 572,
};

const ProductMain: Component = ({
  className,
  dealData,
  isHomePage,
}: Props): ReactElement => {
  const [isVariantsModalOpen, setIsVariantsModalOpen] = useState(false);
  const {
    availability,
    calculatedDiscount,
    category,
    comparison,
    deliveryInformation,
    endDate,
    keyFeatures,
    mainImage,
    navisionId,
    originalPrice,
    pdpUrl,
    sku,
    slug,
    specialPrice,
    startDate,
    stickers,
    subtitle,
    title,
    variants,
    video,
  } = dealData;

  const handleVariantBuyButtonClick = () => setIsVariantsModalOpen(true);
  const onVariantsModalClose = () => setIsVariantsModalOpen(false);
  const { isSoldOut, isReserved } = getAvailabilityProps(availability);
  const { isMobile } = useDevice();
  const isStickyBar = useIsStickyBar();
  const goToCategoryPage = useGoToPage(`category/${category.slug}`);
  const hasPriceRange = getHasPriceRange(variants);
  const hasDealStarted = isPast(toDate(Date.parse(startDate)));
  const [isImageLoading, setIsImageLoading] = useState(false);
  const prevDealSku = usePrevious(dealData.sku);

  const rootClassName = classNames(
    'container',
    'ProductMain',
    {
      'ProductMain--HomePage': isHomePage,
    },
    className,
  );

  const imageClassName = classNames(
    'ProductMain-Image',
    {
      'ProductMain-Image--Loading': isImageLoading,
    },
  );

  const handleCategoryLinkClick = useCallback(
    () => {
      if (isHomePage && isMobile) {
        goToCategoryPage();
      }
    },
    [isHomePage, isMobile, goToCategoryPage],
  );

  const handleImageLoadingFinished = useCallback(
    () => {
      setIsImageLoading(false);
    },
    [],
  );

  const handleAddToCart = useCallback(
    () => analyzeAddToCart(sku, specialPrice),
    [sku, specialPrice],
  );

  useEffect(() => {
    if (prevDealSku && prevDealSku !== dealData.sku) {
      setIsImageLoading(true);
    }
  }, [dealData.sku, prevDealSku]);

  const mainImageSource = useMemo(
    () => {
      const urlCdn = getConfig('url.cdn');

      return (
        mainImage
          ? {
            src: `${urlCdn}/${MAIN_IMAGE_SIZE.width}x${MAIN_IMAGE_SIZE.height}/${mainImage}`,
            srcSet: `${urlCdn}/${MAIN_IMAGE_SIZE.width * 2}x${MAIN_IMAGE_SIZE.height * 2}/${mainImage} 2x`,
          }
          : { src: fallbackImage }
      );
    },
    [mainImage],
  );

  const hasVariants = useMemo(
    () => variants.length !== 0,
    [variants.length],
  );

  return (
    <div className={rootClassName}>
      {!isHomePage && (
        <div className="ProductMain-CategoryName">
          {category.name}
        </div>
      )}

      <h1
        className="ProductMain-Title"
        onClick={handleCategoryLinkClick}
      >
        {title}
      </h1>

      <h2
        className="ProductMain-Subtitle"
        onClick={handleCategoryLinkClick}
      >
        {subtitle}
      </h2>

      <div className="ProductMain-ImageWrapper">
        <Stickers stickers={stickers} />

        {isImageLoading && <Loader className="ProductMain-ImageLoader" />}
        <img
          onLoad={handleImageLoadingFinished}
          onError={handleImageLoadingFinished}
          alt={title}
          className={imageClassName}
          onClick={handleCategoryLinkClick}
          {...mainImageSource}
        />

        {!isImageLoading && (
          <div suppressHydrationWarning>
            {
              video
              && (!isMobile || isMobile && !isHomePage)
              && !isSoldOut
              && !isPastDeal(endDate)
              && (
                <Video videoId={video}/>
              )
            }
          </div>
        )}

        <div suppressHydrationWarning>
          {
            (isSoldOut || isPastDeal(endDate))
            && !isServer
            && (!isMobile || isMobile && !isHomePage)
            && hasNewsletter
            && (
              <SoldOutBox slug={slug}/>
            )
          }
        </div>
      </div>

      <ProgressBar
        className="ProductMain-ProgressBar"
        dataTestId="progress-bar-main"
        endTime={Date.parse(endDate)}
        startTime={Date.parse(startDate)}
        {...getAvailabilityProps(availability)}
      />

      {!isPastDeal(endDate) && (
        <Price
          className="ProductMain-Price"
          comparison={comparison}
          discount={calculatedDiscount}
          hasPriceRange={hasPriceRange}
          oldPrice={originalPrice}
          price={specialPrice}
        />
      )}

      {isHomePage && (
        <SeeDealButton
          categorySlug={category.slug}
          className="ProductMain-SeeDealButton"
        />
      )}

      {isSoldOut && (
        <SoldOutInformation
          className="ProductMain-SoldOutInfo"
        />
      )}

      {isReserved && (
        <ReservedInformation
          className="ProductMain-ReservedInfo"
        />
      )}

      {!isSoldOut && !isReserved && (
        <>
          <BuyButton
            className="ProductMain-BuyButton"
            dataTestId={
              hasVariants
                ? 'open-variants-modal-button'
                : 'main-buy-button'
            }
            hasVariants={hasVariants}
            navisionId={navisionId}
            isDisabled={!hasVariants && !hasDealStarted}
            isPastDeal={isPastDeal(endDate)}
            onVariantButtonClick={handleVariantBuyButtonClick}
            pdpUrl={pdpUrl}
            onAddToCart={handleAddToCart}
          />
          {!isPastDeal(endDate) && (
            <DeliveryInformation
              className="ProductMain-DeliveryInfo"
              deliveryInformation={deliveryInformation}
            />
          )}
        </>
      )}

      <Modal
        className="ProductMain-VariantsModal"
        isOpen={isVariantsModalOpen}
        onClose={onVariantsModalClose}
      >
        {() => (
          <Variants
            comparison={comparison}
            isBuyButtonDisabled={!hasDealStarted}
            variants={variants}
          />
        )}
      </Modal>

      <BulletPoints
        className="ProductMain-BulletPoints"
        keyFeatures={keyFeatures}
      />

      <div suppressHydrationWarning>
        {
          isStickyBar && (
            <StickyBar
              availability={availability}
              calculatedDiscount={calculatedDiscount}
              className="ProductMain-Sticky"
              comparison={comparison}
              hasPriceRange={hasPriceRange}
              hasVariants={hasVariants}
              isBuyButtonDisabled={!hasVariants && !hasDealStarted}
              navisionId={navisionId}
              onAddToCart={handleAddToCart}
              onVariantButtonClick={handleVariantBuyButtonClick}
              originalPrice={originalPrice}
              specialPrice={specialPrice}
              title={title}
            />
          )
        }
      </div>
    </div>
  );
};

export {
  ProductMain,
};
