import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

enum MediaType {
  PHOTO = 'Photo',
  VIDEO = 'Video',
}

enum LabelType {
  OFFER = 'offer',
  TRADE = 'trade',
  SALE = 'sale',
  INTROPROMOTION = 'intropromotion',
  CASHBACK = 'cashback',
  NEW = 'new',
}

interface Availability {
  availablePercentage: number;
  buyable: boolean;
  hasReservedProducts: boolean;
  isSoldOut: boolean;
}

interface Analytics {
  categorySlug: string;
  dealSlug: string;
  dealSubtitle: string;
  dealTitle: string;
  metaTitle: string;
}

interface Category {
  name: string;
  slug: string;
  id: number;
}

interface Slugs {
  category: Record<LanguageCode, string>;
  deal: Record<LanguageCode, string>;
}

interface User {
  avatar: string | null;
  label: string | null;
  name: string | null;
}

interface Variant {
  availability: Availability;
  calculatedDiscount: number | null;
  id: string;
  image: string | null;
  name: string;
  navisionId: number;
  originalPrice: string | null;
  sku?: number;
  specialPrice: string;
}

interface VariantsGroup {
  name: string | null;
  items: Array<Variant>;
}

type Variants = Array<VariantsGroup>;

interface Description {
  content: string | null;
  user: User | null;
  teaser: string | null;
}

type KeyFeatures = Array<{
  keyFeature: string;
}>;

type Media = Array<{
  caption: string | null;
  source: string;
  type: MediaType;
}>;

interface Thumbnails {
  standard: string;
  large: string;
}

interface RecommendedProduct {
  labels: Array<LabelType>;
  manufacturer: string;
  price: string;
  sku: number;
  specialPrice: string | null;
  stock: number;
  subtitle: string;
  thumbnails: Thumbnails;
  title: string;
  url: string;
}

interface AccessoryProduct {
  labels: Array<LabelType>;
  name: string;
  price: string;
  sku: number;
  specialPrice: string | null;
  stock: number;
  thumbnails: Thumbnails;
  url: string;
}

interface AccessoryGroup {
  label: string;
  products: Array<AccessoryProduct>;
  total: number;
}

interface LinksGroup {
  id: string;
  title: string;
  url: string;
}

interface Apps {
  description: string | null;
  links: Array<{
    image: string;
    url: string;
  }>;
}

interface Sticker {
  id: string;
  name: string;
  label: string;
  image: string;
  url: string | null;
}

interface Meta {
  description: string;
  title: string;
}

interface Og {
  description: string | null;
  image: string | null;
  title: string | null;
}

type Stickers = Array<Sticker>;

type Links = Array<LinksGroup>

type Accessories = Array<AccessoryGroup>;

type Recommendations = Array<RecommendedProduct>;

interface Deal {
  accessories: Accessories;
  analytics: Analytics;
  apps: Apps;
  availability: Availability;
  calculatedDiscount: number | null;
  category: Category;
  comparison: string | null;
  deliveryInformation: string | null;
  description: Description;
  detail: string | null;
  endDate: string;
  id: number;
  keyFeatures: KeyFeatures;
  links: Links;
  mainImage: string | null;
  media: Media;
  meta: Meta;
  navisionId: number;
  og: Og;
  originalPrice: string | null;
  pdpUrl: string | null;
  recommendations: Recommendations;
  scopeOfDelivery: string | null;
  sku: number;
  slug: string;
  slugs: Slugs;
  specialPrice: string;
  specification: string | null;
  startDate: string;
  stickers: Stickers;
  subtitle: string;
  themeId: number | null;
  title: string;
  variants: Variants;
  video: string | null;
}

export {
  LabelType,
  MediaType,
};
export type {
  Accessories,
  AccessoryGroup,
  AccessoryProduct,
  Apps,
  Availability,
  Deal,
  Description,
  KeyFeatures,
  Links,
  LinksGroup,
  Media,
  Meta,
  Og,
  Recommendations,
  RecommendedProduct,
  Slugs,
  Sticker,
  Stickers,
  Variant,
  Variants,
  VariantsGroup,
};
