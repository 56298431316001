import React, { MouseEvent, Ref, useCallback } from 'react';
import classNames from 'classnames';
import { capitalizeFirstLetter } from 'dd-client/site/common/utils/capitalizeFirstLetter';
import { Component, Props } from './types';
import './Button.scss';

enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

enum Size {
  DEFAULT = 'default',
  SMALL = 'small',
}

enum StyleType {
  GHOST = 'ghost',
  LIGHT = 'light',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const Button: Component = ({
  buttonRef,
  children,
  className,
  href,
  iconPosition,
  isDisabled = false,
  isFormSubmitPrevented = false,
  isFullWidth = false,
  onClick,
  size = Size.DEFAULT,
  styleType = StyleType.PRIMARY,
  target,
}: Props) => {
  const rootClassName = classNames(
    'Button',
    `Button--${capitalizeFirstLetter(size)}`,
    `Button--${capitalizeFirstLetter(styleType)}`,
    {
      'Button--FullWidth': isFullWidth,
      [`Button-Icon--${capitalizeFirstLetter(iconPosition || '')}`]: !!iconPosition,
    },
    className,
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => !isDisabled && onClick && onClick(e),
    [isDisabled, onClick],
  );

  if (href) {
    return (
      <a
        className={rootClassName}
        href={href}
        onClick={handleClick}
        ref={buttonRef as Ref<HTMLAnchorElement>}
        target={target}
        {...(isFormSubmitPrevented ? { type: 'button' } : {})}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      className={rootClassName}
      disabled={isDisabled}
      onClick={handleClick}
      ref={buttonRef}
      {...(isFormSubmitPrevented ? { type: 'button' } : {})}
    >
      {children}
    </button>
  );
};

Button.IconPosition = IconPosition;
Button.Size = Size;
Button.StyleType = StyleType;

export {
  Button,
  IconPosition,
  Size,
  StyleType,
};
