import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MessageIcon from 'dd-client/site/common/assets/icons/message.svg';
import { getConfig } from 'dd-client/site/config/utils/config';
import PinIcon from './assets/pin.svg';
import { Component, Props } from './types';
import { getSanitizedHtml } from './utils/getSanitizedHtml';
import { getTimeAgo } from './utils/getTimeAgo';
import { Voting } from './Voting';
import './CommentBox.scss';

const COMMENT_AVATAR_SIZE = {
  height: 30,
  width: 30,
};

const CommentBox: Component = ({
  areVotingButtonsDisabled,
  authorName,
  avatarImg,
  badge,
  className,
  content,
  repliesCount,
  onCommentBoxClick,
  hasVotingButtons = true,
  isPinned,
  onDislikeButtonClick,
  onLikeButtonClick,
  onReplyButtonClick,
  postedTime,
  rating,
  title,
  wasDisliked,
  wasLiked,
}: Props): React.ReactElement => {
  const rootClassName = classNames(
    'CommentBox',
    className,
  );
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      replies: (repliesCount: number) => t(
        'replies',
        { count: repliesCount, defaultValue: '{count, plural, one {1 Reply} other {{count} Replies}}' },
      ),
    }),
    [t],
  );
  const urlCdn = getConfig('url.cdn');

  const handleCommentBoxClick = useCallback(
    () => {
      onCommentBoxClick?.();
    },
    [onCommentBoxClick],
  );

  return (
    <div
      className={rootClassName}
      onClick={handleCommentBoxClick}
    >
      <div className="CommentBox-Top">
        <div className="CommentBox-AuthorAndTime">
          {avatarImg && (
            <img
              alt={authorName}
              className="CommentBox-Avatar"
              src={`${urlCdn}/${COMMENT_AVATAR_SIZE.width}x${COMMENT_AVATAR_SIZE.height}/${avatarImg}`}
              srcSet={`${urlCdn}/${COMMENT_AVATAR_SIZE.width * 2}x${COMMENT_AVATAR_SIZE.height * 2}/${avatarImg} 2x`}
            />
          )}

          <div className="CommentBox-AuthorName">
            {authorName}
          </div>

          {badge && (
            <div
              className="CommentBox-Badge"
              style={{ backgroundColor: badge.color || undefined }}
            >
              {badge.name}
            </div>
          )}

          <div className="CommentBox-Time">
            {getTimeAgo(postedTime)}
          </div>
        </div>

        {isPinned && (
          <PinIcon />
        )}
      </div>

      {title && (
        <div className="CommentBox-Title">
          {title}
        </div>
      )}

      <div
        className="CommentBox-Content"
        dangerouslySetInnerHTML={{ __html: getSanitizedHtml(content) }}
      />

      {!!repliesCount && (
        <div className="CommentBox-ReplyCount">
          <MessageIcon className="CommentBox-ReplyIcon" />
          {text.replies(repliesCount)}
        </div>
      )}

      {hasVotingButtons && (
        <Voting
          areVotingButtonsDisabled={areVotingButtonsDisabled}
          onReplyButtonClick={onReplyButtonClick}
          onLikeButtonClick={onLikeButtonClick}
          onDislikeButtonClick={onDislikeButtonClick}
          rating={rating}
          wasDisliked={wasDisliked}
          wasLiked={wasLiked}
        />
      )}
    </div>
  );
};

export {
  CommentBox,
};
