import React, { ReactElement } from 'react';
import classNames from 'classnames';
import ArrowRight from 'dd-client/site/common/assets/icons/arrow-right.svg';
import { ComponentType, Props } from './';
import './Title.scss';

const Title: ComponentType = ({
  children,
  className,
  isOpen,
  onClick,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'ContentNavigation-Title',
    {
      'ContentNavigation-Title--Open': isOpen,
    },
    className,
  );

  return (
    <li
      className={rootClassName}
      onClick={onClick}
    >
      {children}
      <ArrowRight />
    </li>
  );
};

export {
  Title,
};
