import React, { ReactElement, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Title } from './Title';
import { Component, Props } from './types';
import './ContentNavigation.scss';

const ContentNavigation: Component = ({
  className,
  data,
  defaultOpenIndex,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'ContentNavigation',
    className,
  );

  const [openIndex, setOpenIndex] = useState(defaultOpenIndex);

  const handleClick = useCallback(
    (index: number) => {
      setOpenIndex(index);
    },
    [],
  );

  return (
    <div className={rootClassName}>
      <ul className="ContentNavigation-List">
        {data.map(
          ({ header }, index) => (
            <Title
              isOpen={index === openIndex}
              key={header}
              onClick={() => handleClick(index)}
            >
              {header}
            </Title>
          ),
        )}
      </ul>
      <div className="ContentNavigation-Content">
        {data[openIndex]?.content}
      </div>
    </div>
  );
};

export {
  ContentNavigation,
};
