import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert, StyleType } from 'dd-client/site/common/components/Alert';
import { Component, Props } from './types';
import './ReservedInformation.scss';

const ReservedInformation: Component = ({
  className,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const rootClassName = classNames(
    'ReservedInfo',
    className,
  );

  const text = useMemo(
    () => ({
      allItemsReserved: t('All items are currently reserved in customer’s shopping carts. Stay tuned in case these products become available again'),
    }),
    [t],
  );

  return (
    <div
      className={rootClassName}
      data-testid="reserved-info"
    >
      <Alert
        isVisible
        styleType={StyleType.INFO}
      >
        {text.allItemsReserved}
      </Alert>
    </div>
  );
};

export {
  ReservedInformation,
};
