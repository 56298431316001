import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SECTION_ELEMENT_ID } from '../Section';
import { ComponentType, Props } from './';
import './Content.scss';

const Content: ComponentType = ({
  children,
  className,
  id,
  index,
  isOpen,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Accordion-SectionContent',
    {
      'Accordion-SectionContent--Open': isOpen,
    },
    className,
  );

  return (
    <div
      aria-labelledby={`${SECTION_ELEMENT_ID.heading}-${id}-${index}`}
      className={rootClassName}
      id={`${SECTION_ELEMENT_ID.content}-${id}-${index}`}
      role="region"
    >
      {children}
    </div>
  );
};

export { Content };
