const SCROLL_LOCK_CLASS_NAME = 'HtmlElement--ScrollLock';

const enableScrollLock = (): void => {
  const htmlTag = document.querySelector('html');
  htmlTag?.classList.add(SCROLL_LOCK_CLASS_NAME);
};

const disableScrollLock = (): void => {
  const htmlTag = document.querySelector('html');
  htmlTag?.classList.remove(SCROLL_LOCK_CLASS_NAME);
};

export {
  disableScrollLock,
  enableScrollLock,
};
