import { getConfig } from 'dd-client/site/config/utils/config';
import { getAvailableLanguages } from 'dd-client/site/i18n/utils/language';

const isExternalUrl = (url: string): boolean => {
  if (!url || !url.startsWith) {
    return true;
  }

  return (
    url.startsWith('//') || (!url.startsWith('/') && !url.startsWith(getConfig('url.root')))
  );
};

const getPathsForAvailableLanguages = (
  path = '',
  availableLanguages = getAvailableLanguages(),
): Array<string> => availableLanguages.map(
  language => path.replace(':lang', language.code),
);

export {
  getPathsForAvailableLanguages,
  isExternalUrl,
};
