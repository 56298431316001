import React, { ReactElement, useCallback, useState } from 'react';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import { VideoButton } from 'dd-client/site/common/components/VideoButton';
import { disableScrollLock, enableScrollLock } from 'dd-client/site/common/utils/scrollLock';
import { getVideoLink } from 'dd-client/site/common/utils/video/video';
import { Component, Props } from './types';
import './Video.scss';

const Video: Component = ({
  videoId,
}: Props): ReactElement => {
  const [isVideoBoxOpen, setIsVideoBoxOpen] = useState(false);

  const handleVideoButtonClick = useCallback(
    (): void => {
      setIsVideoBoxOpen(true);
      enableScrollLock();
    },
    [],
  );

  const handleVideoBgClick = useCallback(
    (): void => {
      setIsVideoBoxOpen(false);
      disableScrollLock();
    },
    [],
  );

  return (
    <>
      <VideoButton
        className="Video-Button"
        onClick={handleVideoButtonClick}
      />
      {isVideoBoxOpen && (
        <>
          <div className="Video-Box" onClick={handleVideoBgClick}>
            <div className="Video-Content">
              <div className="Video-IframeWrapper">
                <button className="Video-CloseButton">
                  <CloseIcon />
                </button>

                <iframe
                  src={getVideoLink(videoId)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export {
  Video,
};
