import { format, toDate } from 'date-fns';
import { stringify } from 'dd-client/site/common/utils/searchParams';
import { getConfig } from 'dd-client/site/config/utils/config';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const utmSource = getConfig('analytics.utmSource');

const getAddToCartQueryParams = (
  language: LanguageCode,
  startDate: string,
  categorySlug: string,
  dealSlug: string,
): string => {
  const formattedStartDate = format(toDate(Date.parse(startDate)), 'ddMMyy');
  const campaignParams = {
    '!cc': 'cmot',
    '!d': formattedStartDate,
    '!i': utmSource,
    '!i2': categorySlug,
    '!l': language[0],
    '!t': 'dealsite',
  };
  const queryParams = {
    ddcmp: localStorage.getItem('ddcmp') || sessionStorage.getItem('ddcmp') || 'no_ddcmp',
    utm_campaign: Object.keys(campaignParams).map(key => `${key}-${campaignParams[key as keyof typeof campaignParams]}`).join(''),
    utm_content: dealSlug,
    utm_medium: 'dealsite',
    utm_source: utmSource,
  };

  return stringify(queryParams);
};

export {
  getAddToCartQueryParams,
};
