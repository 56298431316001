import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useComments } from 'dd-client/site/comments/hooks/useComments';
import MessageIcon from 'dd-client/site/common/assets/icons/message.svg';
import PlusIcon from 'dd-client/site/common/assets/icons/plus.svg';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { Modal } from 'dd-client/site/common/components/Modal';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { CommentBox } from './CommentBox';
import { CommentList } from './CommentList';
import { CommentsSkeleton } from './CommentsSkeleton';
import { Component, Props } from './types';
import './Comments.scss';

const Comments: Component = ({
  className,
  dealId,
}: Props): ReactElement | null => {
  const [modalWindowCommentList, setModalWindowCommentList] = useState({
    isOpen: false,
    isWriteCommentFormOpen: false,
  });

  const comments = useComments(dealId);

  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      comments: t('Comments'),
      couldNotLoadComments: t('Couldn\'t load comments'),
      noCommentsYet: t('No comments yet'),
      retry: t('Retry'),
      seeAll: t('See all'),
      writeComment: t('Write a comment...'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'Comments',
    className,
  );

  const handleCommentsModalClose = () => setModalWindowCommentList({
    isOpen: false,
    isWriteCommentFormOpen: false,
  });

  const refetchComments = useCallback(
    async () => {
      try {
        await comments.refetch();
      } catch (error) {
        logger.error(error);
      }
    },
    [comments],
  );

  const handleSeeAllButtonAndCommentBoxClick = useCallback(
    async () => {
      setModalWindowCommentList({
        isOpen: true,
        isWriteCommentFormOpen: false,
      });

      await refetchComments();
    },
    [refetchComments],
  );

  const handleWriteCommentButtonClick = useCallback(
    async () => {
      setModalWindowCommentList({
        isOpen: true,
        isWriteCommentFormOpen: true,
      });

      await refetchComments();
    },
    [refetchComments],
  );

  const modalHtmlElementRef = useRef<HTMLDivElement | null>(null);

  const handleSetModalHtmlElement = useCallback(
    (element: HTMLDivElement | null) => {
      modalHtmlElementRef.current = element;
    },
    [],
  );

  const renderTitleWithCreateButton = useMemo(
    () => (
      <div className="SectionTitle Comments-Title">
        {text.comments}

        <Button
          iconPosition={Button.IconPosition.LEFT}
          onClick={handleWriteCommentButtonClick}
          size={Button.Size.SMALL}
        >
          <PlusIcon />
          {text.writeComment}
        </Button>
      </div>
    ),
    [handleWriteCommentButtonClick, text.comments, text.writeComment],
  );

  if (comments.isPending) {
    return (
      <CommentsSkeleton className={rootClassName} />
    );
  }

  return (
    <div className={rootClassName}>
      <div className="container">
        {comments.isError && (
          <>
            {renderTitleWithCreateButton}

            <Alert
              className="Comments-Alert"
              isVisible
              styleType={Alert.StyleType.WARNING}
            >
              {text.couldNotLoadComments}

              <Button
                className="Comments-RetryButton"
                styleType={Button.StyleType.GHOST}
                onClick={refetchComments}
              >
                {text.retry}
              </Button>
            </Alert>
          </>
        )}

        {comments.isSuccess && comments.data.threads.length === 0 && (
          <>
            <div className="SectionTitle Comments-Title Comments-Title--NoComment">
              <span>
                {text.noCommentsYet}&nbsp;
                <MessageIcon className="Comments-MessageIcon" />
              </span>
            </div>

            <Button
              iconPosition={Button.IconPosition.LEFT}
              onClick={handleWriteCommentButtonClick}
            >
              <PlusIcon />
              {text.writeComment}
            </Button>
          </>
        )}

        {comments.isSuccess && comments.data.threads.length > 0 && (
          <>
            {renderTitleWithCreateButton}

            <div className="Comments-BoxWrapper">
              {comments.data.threads
                .slice(0, 2)
                .map(
                  ({ comment, title, replies }) => (
                    <CommentBox
                      authorName={comment.author.name}
                      avatarImg={comment.author.avatar}
                      badge={comment.author.badge}
                      className="Comments-CommentBox"
                      content={comment.content}
                      hasVotingButtons={false}
                      repliesCount={replies.length}
                      isPinned={comment.isPinned}
                      key={comment.id}
                      postedTime={Date.parse(comment.postedDate)}
                      title={title}
                      onCommentBoxClick={handleSeeAllButtonAndCommentBoxClick}
                    />
                  ),
                )
              }
              <div className="Comments-ButtonWrapper">
                <Button
                  className="Comments-SeeAllButton"
                  styleType={Button.StyleType.LIGHT}
                  onClick={handleSeeAllButtonAndCommentBoxClick}
                >
                  <MessageIcon />
                  {text.seeAll} ({comments.data.totalComments})
                </Button>
              </div>
            </div>
          </>
        )}

        <Modal
          animationType={Modal.AnimationType.SLIDE_RIGHT}
          className="Comments-Modal"
          hasCloseButton={false}
          isOpen={modalWindowCommentList.isOpen}
          onClose={handleCommentsModalClose}
          setModalHtmlElement={handleSetModalHtmlElement}
        >
          {closeModal => (
            <CommentList
              closeModal={closeModal}
              comments={comments}
              dealId={dealId}
              isWriteCommentFormOpen={modalWindowCommentList.isWriteCommentFormOpen}
              modalHtmlElement={modalHtmlElementRef.current}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export {
  Comments,
};
