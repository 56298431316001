import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextMiddleware from 'i18next-http-middleware';
import ICU from 'i18next-icu';
import { isServer } from 'dd-client/site/common/utils/server';
import de from 'dd-client/site/i18n/translations/de.json';
import fr from 'dd-client/site/i18n/translations/fr.json';
import { getAvailableLanguages } from 'dd-client/site/i18n/utils/language';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const languageDetectorOptions = {
  caches: ['cookie'],
  order: ['path', 'cookie', isServer ? 'header' : 'navigator'],
};

i18n
  .use(ICU)
  .use(initReactI18next)
  .use(
    isServer
      ? i18nextMiddleware.LanguageDetector
      : LanguageDetector,
  )
  .init({
    detection: languageDetectorOptions,
    fallbackLng: LanguageCode.DE,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
    resources: {
      [LanguageCode.DE]: {
        translation: de,
      },
      [LanguageCode.FR]: {
        translation: fr,
      },
    },
    returnNull: false,
    supportedLngs: getAvailableLanguages().map(
      language => language.code,
    ),
  });

export default i18n;
