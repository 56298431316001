import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { generateUnique } from 'dd-client/site/common/utils/key/key';
import { Content } from './Content';
import { Title } from './Title';
import { Component, Props } from './types';
import './Section.scss';

enum SECTION_ELEMENT_ID {
  content = 'content',
  heading = 'heading',
}

const Section: Component = ({
  className,
  content,
  index,
  isOpen = false,
  onClick,
  title,
}: Props): ReactElement => {
  const id = generateUnique();

  const rootClassName = classNames(
    'Accordion-Section',
    className,
  );

  return (
    <div className={rootClassName}>
      <Title
        id={id}
        index={index}
        isOpen={isOpen}
        onClick={onClick}
      >
        {title}
      </Title>

      <Content
        id={id}
        isOpen={isOpen}
        index={index}
      >
        {content}
      </Content>
    </div>
  );
};

export {
  Section,
  SECTION_ELEMENT_ID,
};
