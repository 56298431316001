import { LoadDataFunctionType, LoadDataType } from 'dd-server/site/types';
import { hasNewsletter, hasPastDeals, hasStaticPage } from 'dd-client/site/common/utils/features';
import { metricsModule } from 'dd-client/site/common/utils/metrics/metrics';
import { QUERY } from 'dd-client/site/common/utils/query/query';

const APP_MODULE = 'common';

const loadData: LoadDataFunctionType = (match, language): LoadDataType => {
  metricsModule.run(({ metrics }) => {
    metrics.increment(`${APP_MODULE}.request`);
  });

  const queries = [];

  if (hasStaticPage) {
    queries.push({
      isMainQuery: false,
      queryFunction: QUERY.staticPages.queryFunction,
      queryKey: [QUERY.staticPages.queryName, language],
    });
  }

  if (hasNewsletter || hasPastDeals) {
    queries.push({
      isMainQuery: false,
      queryFunction: QUERY.activeCategories.queryFunction,
      queryKey: [QUERY.activeCategories.queryName, language],
    });
  }

  return {
    appModule: APP_MODULE,
    queries,
  };
};

export {
  loadData,
};
