import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useAddToCartAnalytics } from 'dd-client/site/analytics/hooks/useAddToCartAnalytics';
import CartIcon from 'dd-client/site/common/assets/icons/cart.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import VariantsIcon from './assets/variants.svg';
import { Component, Props } from './types';
import './BuyButton.scss';

const BuyButton: Component = ({
  className,
  dataTestId,
  hasVariants = false,
  isDisabled = false,
  isPastDeal = false,
  navisionId,
  onAddToCart,
  onVariantButtonClick,
  pdpUrl,
}: Props): ReactElement | null => {
  const { i18n, t } = useTranslation();
  const getQueryParams = useAddToCartAnalytics();
  const language = i18n.language as LanguageCode;

  const text = useMemo(
    () => ({
      orderNow: t('Order Now!'),
      seeAtBrack: t('See at brack.ch'),
      selectVariant: t('Select variant...'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'BuyButton',
    className,
  );

  const handlePastDealClick = useCallback(() => {
    pdpUrl && window.open(pdpUrl, '_blank');
  }, [pdpUrl]);

  const handleVariantsClick = useCallback(() => {
    onVariantButtonClick?.();
  }, [onVariantButtonClick]);

  const handleDefaultClick = useCallback(() => {
    const cartUrl = {
      [LanguageCode.FR]: 'fr/panier',
      [LanguageCode.DE]: 'warenkorb',
    };
    const cartLink = `https://www.brack.ch/${cartUrl[language]}/daydeal?dealId=${navisionId}&${getQueryParams()}`;

    window.open(cartLink, '_blank');
    onAddToCart?.();
  }, [getQueryParams, language, navisionId, onAddToCart]);

  const result = {
    className: 'BuyButton-Button--Default',
    icon: <CartIcon/>,
    isVisible: true,
    onClick: handleDefaultClick,
    text: text.orderNow,
  };

  if (isPastDeal) {
    result.className = 'BuyButton-Button--PastDeal';
    result.icon = <></>;
    result.isVisible = !!pdpUrl;
    result.onClick = handlePastDealClick;
    result.text = text.seeAtBrack;
  } else if (hasVariants) {
    result.icon = <VariantsIcon/>;
    result.onClick = handleVariantsClick;
    result.text = text.selectVariant;
  }

  return result.isVisible ? (
    <div
      className={rootClassName}
      data-testid={dataTestId}
    >
      <Button
        iconPosition={Button.IconPosition.LEFT}
        isFullWidth={true}
        className={`BuyButton-Button ${result.className}`}
        onClick={result.onClick}
        isDisabled={!!isDisabled}
      >
        {result.icon}
        <span className="BuyButton-Label">
          {result.text}
        </span>
      </Button>
    </div>
  ) : null;
};

export {
  BuyButton,
};
