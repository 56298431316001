import { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { Centrifuge } from 'centrifuge';
import { Subscription } from 'centrifuge/build/subscription';
import { usePrevious } from 'dd-client/site/common/hooks/usePrevious';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { getConfig } from 'dd-client/site/config/utils/config';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
// import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const WS_URL = getConfig('url.liveMessage');

const ONLINE_CHANNEL = 'online';
const DEAL_ONLINE_CHANNEL = 'deal_online_';

//TODO enable availability and deals channels when tests succeed
// const AVAILABILITY_CHANNEL = 'availability:';
// const DEALS_CHANNEL = 'deals:';

//TODO should we handle situation when url is not defined?
const centrifuge = new Centrifuge(`${WS_URL}/connection/websocket`);

const subscribeToDealChannel = (dealId?: number): Subscription | undefined => {
  if (dealId) {
    const dealOnlineChannelName = `${DEAL_ONLINE_CHANNEL}${dealId}`;

    return subscribeToChannel(dealOnlineChannelName);
  }
};

const subscribeToChannel = (channelName: string): Subscription | undefined => {
  try {
    const channel = centrifuge.newSubscription(channelName);
    channel
      // .on('unsubscribed', () => console.log(`Unsubscribed from ${channelName}`))
      // .on('subscribed', () => console.log(`Subscribed to ${channelName}`))
      // .on('error', () => console.log(`error from ${channelName}`))
      .subscribe();

    return channel;
  } catch (e) {
    logger.log('subscribeToChannel error', e);
  }
};

const clearSubscription = (subscription?: Subscription): void => {
  try {
    if (subscription) {
      subscription.unsubscribe();
      subscription.removeAllListeners();
      centrifuge.removeSubscription(subscription);
    }
  } catch (e) {
    logger.log('clearSubscription error', e);
  }
};

const connectCentrifuge = (): void => {
  try {
    centrifuge
      // .on('connected', ctx => console.log(`Connected over ${ctx.transport}`))
      // .on('disconnected', ctx => console.log(`Disconnected: ${ctx.code}, ${ctx.reason}`))
      // .on('error', ctx => console.log('error centrifuge', ctx))
      .connect();
  } catch (e) {
    logger.log('centrifugeConnect error', e);
  }
};

const disconnectCentrifuge = (): void => {
  try {
    centrifuge.disconnect();
  } catch (e) {
    logger.log('centrifugeDisconnect error', e);
  }
};

const useSseUpdatesTest = (): void => {
  const currentDeal = useCurrentDeal();
  // const { i18n } = useTranslation();
  // const language = i18n.language as LanguageCode;
  const currentDealId = currentDeal?.data?.id;
  const previousDealId = usePrevious(currentDealId);

  useEffect(() => {
    let currentDealSubscription: Subscription | undefined;

    if (currentDealId !== previousDealId) {
      if (currentDealSubscription) {
        clearSubscription(currentDealSubscription);
      }
      currentDealSubscription = subscribeToDealChannel(currentDealId);
    }

    return () => {
      if (currentDealSubscription) {
        clearSubscription(currentDealSubscription);
      }
    };
  }, [currentDealId]);//eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const dealsChannelName = `${DEALS_CHANNEL}${language}`;
  //   const dealsChannelSubscription = centrifuge.newSubscription(dealsChannelName);
  //   dealsChannelSubscription
  //     .on('publication', ctx => console.log('publication', dealsChannelName, ctx.data))
  //     .on('error', ctx => console.log('error', dealsChannelName, ctx))
  //     .subscribe();
  //
  //   const availabilityChannelName = `${AVAILABILITY_CHANNEL}${language}`;
  //   const availabilityChannelSubscription = centrifuge.newSubscription(availabilityChannelName);
  //   availabilityChannelSubscription
  //     .on('publication', ctx => console.log('publication', availabilityChannelName, ctx.data))
  //     .on('error', ctx => console.log('error', availabilityChannelName, ctx))
  //     .subscribe();
  //
  //   return () => {
  //     clearSubscription(dealsChannelSubscription);
  //     clearSubscription(availabilityChannelSubscription);
  //   };
  // }, [language]);

  useEffect(() => {
    const onlineChannelSubscription = subscribeToChannel(ONLINE_CHANNEL);
    connectCentrifuge();

    return () => {
      clearSubscription(onlineChannelSubscription);
      disconnectCentrifuge();
    };
  }, []);
};

export {
  useSseUpdatesTest,
};
