import {
  createBrowserHistory,
  createMemoryHistory,
} from 'history';
import { isServer } from './server';

const history = isServer
  ? createMemoryHistory({
    initialEntries: ['/'],
  })
  : createBrowserHistory();

export {
  history,
};
