import React, { ReactElement } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Link } from 'dd-client/site/common/components/Link';
import { Component, Props } from './types';

const LinkGroup: Component = ({
  links,
  onClick,
}: Props): ReactElement => {
  const { pathname } = useLocation();

  return (
    <>
      {links.map(
        ({
          label,
          url,
          isBlank,
        }) => (
          <li key={url}>
            <Link
              isSelected={matchPath(pathname, { path: `/:lang${url}` })?.isExact}
              onClick={onClick}
              target={isBlank ? '_blank' : undefined}
              url={url}
            >
              {label}
            </Link>
          </li>
        ),
      )}
    </>
  );
};

export {
  LinkGroup,
};
