import { getConfig } from 'dd-client/site/config/utils/config';

const analyticsPageType = getConfig('analytics.pageType');
const analyticsComponentLocation = getConfig('analytics.componentLocation');

enum ANALYTICS_BOOLEAN {
  false = 'n',
  true = 'y',
}

enum ANALYTICS_ACTION {
  dealDetails = 'detail',
}

enum ANALYTICS_EVENT_ID {
  addToCart = 'add_pdp',
}

const ANALYTICS_PLATFORM = 'dealsites';
const ANALYTICS_SERVICE = 'n';
const ANALYTICS_CATEGORIES = ['daydeal', 'daydeal', 'daydeal', 'daydeal', 'daydeal'];
const ANALYTICS_COMPONENT_CATEGORY = ['ecommerce'];
const ANALYTICS_COMPONENT_SUBCATEGORY = ['add_pdp'];

export {
  ANALYTICS_ACTION,
  ANALYTICS_BOOLEAN,
  ANALYTICS_CATEGORIES,
  ANALYTICS_COMPONENT_CATEGORY,
  ANALYTICS_COMPONENT_SUBCATEGORY,
  ANALYTICS_EVENT_ID,
  ANALYTICS_PLATFORM,
  ANALYTICS_SERVICE,
  analyticsComponentLocation,
  analyticsPageType,
};
