import { AxiosError } from 'axios';
import { getDefaultWinstonLoggerFields } from 'dd-server/site/utils/logger/winston';
import { LoggerLevel } from 'dd-client/site/common/utils/logger/logger';

const handleApiRequestError = (
  error: AxiosError,
): Promise<any> => {
  const timestamp = Date.now();

  const errorObject = {
    ...getDefaultWinstonLoggerFields(),
    datetime: new Date(timestamp).toISOString(),
    level: LoggerLevel.ERROR,
    message: `react-query request failed with url: ${error?.config?.url}`,
    request: {
      http_host: error?.request?.host,
      method: error?.config?.method?.toUpperCase(),
      path: error?.request?.path,
    },
    response: {
      status: error?.response?.status,
    },
    ...(error?.stack && { stack: error.stack }),
    timestamp: timestamp,
  };

  return Promise.reject(JSON.stringify(errorObject));
};

export {
  handleApiRequestError,
};
