import { useTranslation } from 'react-i18next';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { PastDeals } from './types';

enum PastDealsType {
  ALL = 'all',
  CATEGORY = 'category',
}

const usePastDeals = (
  categoryId: number | null,
  searchText: string | null,
): UseInfiniteQueryResult<InfiniteData<PastDeals>, string> => {
  const { i18n: { language } } = useTranslation();
  let type = PastDealsType.ALL;

  if (Number.isInteger(categoryId)) {
    type = PastDealsType.CATEGORY;
  }

  return useInfiniteQuery({
    getNextPageParam: (lastPage: PastDeals, allPages: Array<PastDeals>) => {
      const allLoadedPastDeals = allPages.reduce(
        (result: number, pastDeals) => (
          result + pastDeals.results.length
        ),
        0,
      );

      if (allLoadedPastDeals < lastPage.totalCount) {
        return allPages.length + 1;
      }

      return;
    },
    initialPageParam: 1,
    queryFn: QUERY.pastDeals.queryFunction,
    queryKey: [QUERY.pastDeals.queryName, language, type, categoryId, searchText],
  });
};

export {
  PastDealsType,
  usePastDeals,
};

