import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { SubmitNewsletterData } from './types';

const subscribe = (submitCompetition: SubmitNewsletterData): Promise<any> => (
  getApiRequest({
    data: submitCompetition,
    method: 'post',
    url: `${getConfig('url.api.deal')}/newsletter/subscribe`,
  })
);

export {
  subscribe,
};
