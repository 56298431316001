import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { update } from './connector';
import { UpdateSubscriptionData } from './types';

const useUpdateSubscription = (): UseMutationResult<any, AxiosError, UpdateSubscriptionData> => (
  useMutation({ mutationFn: update })
);

export {
  useUpdateSubscription,
};

