import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Slider } from 'dd-client/site/common/components/Slider';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { getImageSource } from 'dd-client/site/deal/components/DealPage/CategoryMenu/utils/image';
import { Deal } from 'dd-client/site/deal/hooks/api/types';
import { useActiveDeals } from 'dd-client/site/deal/hooks/api/useActiveDeals';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import { getHasPriceRange } from 'dd-client/site/deal/utils/variants';
import { CategoryItem } from './CategoryItem';
import { Component, Props } from './types';
import './CategoryMenu.scss';

const CategoryMenu: Component = ({
  isForMobilesOnDealPage,
  isForMobilesOnHomePage,
}: Props): ReactElement | null => {
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;
  const activeDeals = useActiveDeals();
  const { t } = useTranslation();
  const { isHomePage } = usePageMatch();

  const text = useMemo(
    () => ({
      otherDeals: t('Other deals'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'CategoryMenu',
    {
      'CategoryMenu--Mobile': isForMobilesOnHomePage || isForMobilesOnDealPage,
      'CategoryMenu--MobileDealPage': isForMobilesOnDealPage,
      'CategoryMenu--MobileHomePage': isForMobilesOnHomePage,
    },
  );

  const renderCategoryItem = useCallback(
    (activeDeal: Deal, isForMobiles: boolean, isFirstCategory: boolean, isActive: boolean) => {
      const {
        availability, calculatedDiscount, category, endDate, startDate, variants, originalPrice, specialPrice, subtitle, title,
      } = activeDeal;

      return (
        <CategoryItem
          availability={availability}
          calculatedDiscount={calculatedDiscount}
          category={category.name}
          endDate={endDate}
          startDate={startDate}
          isFirstCategory={isFirstCategory}
          hasPriceRange={getHasPriceRange(variants)}
          isActive={isActive}
          imageSource={getImageSource(activeDeal, isForMobiles)}
          key={category.name}
          originalPrice={originalPrice}
          slug={category.slug}
          specialPrice={specialPrice}
          subtitle={isForMobiles ? subtitle : ''}
          title={title}
        />
      );
    },
    [],
  );

  if (activeDeals.isSuccess && activeDeals.data?.length > 1) {
    if (isForMobilesOnHomePage && isHomePage) {
      return (
        <div className={rootClassName}>
          <div className="CategoryMenu-Content container">
            <div className="CategoryMenu-MobileHomePageTitle">
              {text.otherDeals}
            </div>

            {
              activeDeals.data
                .filter((activeDeal, index) => index !== 0)
                .map(activeDeal => (
                  renderCategoryItem(activeDeal, true, false, false)
                ))
            }
          </div>
        </div>
      );
    }

    if (isForMobilesOnDealPage && !isHomePage) {
      const firstDeal = activeDeals.data[0];
      return (
        <div className={rootClassName}>
          <div className="CategoryMenu-Content container">
            <div className="SectionTitle CategoryMenu-MobileDealPageTitle">
              {text.otherDeals}
            </div>

            {
              <Slider
                className="CategoryMenu-Slider"
                slides={activeDeals.data
                  .filter(activeDeal => activeDeal.slug !== currentDealData?.slug)
                  .map(activeDeal => (
                    renderCategoryItem(activeDeal, true, activeDeal.id === firstDeal.id, false)
                  ))
                }
              />
            }
          </div>
        </div>
      );
    }

    if (!isForMobilesOnHomePage && !isForMobilesOnDealPage) {
      return (
        <div className={rootClassName}>
          <div className="CategoryMenu-Content container">
            {
              activeDeals.data
                .map((activeDeal, index) => {
                  const isActive = activeDeal.slug === currentDealData?.slug
                    || (isHomePage && index === 0);

                  return (
                    renderCategoryItem(activeDeal, false, index === 0, isActive)
                  );
                })
            }
          </div>
        </div>
      );
    }
  }

  return null;
};

export {
  CategoryMenu,
};
