import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Component, Props } from './types';
import './SoldOutInformation.scss';

const SoldOutInformation: Component = ({
  className,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const rootClassName = classNames(
    'SoldOutInfo',
    className,
  );

  const text = useMemo(
    () => ({
      itemSoldOut: t('The item has been sold out'),
    }),
    [t],
  );

  return (
    <div
      className={rootClassName}
      data-testid="sold-out-info"
    >
      {text.itemSoldOut}
    </div>
  );
};

export {
  SoldOutInformation,
};
