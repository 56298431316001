import { TFunction } from 'i18next';
import { SubmitNewsletterData } from 'dd-client/site/common/hooks/useSubmitNewsletter';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';
import { Checkboxes, FormFieldCheckboxes, NewsletterCheckboxesValue } from './types';

const getSelectedCategories = (
  newsletterCheckboxes : NewsletterCheckboxesValue,
): Pick<SubmitNewsletterData, 'categoryIds' | 'specialCategories'> => {
  const getCategoryIds = () => {
    const categoryIdsArray: Array<number> = [];

    Object.entries(newsletterCheckboxes).forEach(
      ([key, value]) => {
        const keyNumber = parseInt(key);

        if (Number.isInteger(keyNumber) && value) {
          categoryIdsArray.push(keyNumber);
        }
      },
    );

    return categoryIdsArray;
  };

  return ({
    categoryIds: getCategoryIds(),
    specialCategories: {
      ballon: newsletterCheckboxes.ballon ? 1 : 0,
      news: newsletterCheckboxes.news ? 1 : 0,
    },
  });
};

enum DefaultCheckboxID {
  ALL = 'all',
  NEWS = 'news',
  BALLON = 'ballon',
}

const getFormFieldCheckboxes = (t: TFunction = i18nextInit.t): FormFieldCheckboxes => ({
  defaultCheckboxItemAllOption: {
    id: DefaultCheckboxID.ALL,
    isChecked: true,
    name: t('All Categories and Specials'),
  },
  defaultCheckboxItems: [
    {
      id: DefaultCheckboxID.NEWS,
      isChecked: true,
      name: t('News & Competitions'),
    },
    {
      id: DefaultCheckboxID.BALLON,
      isChecked: true,
      name: t('DayDeal Ballon News'),
    },
  ],
  newsletterCheckboxes: 'newsletterCheckboxes',
});

const isAllCategoriesOptionChecked = (checkboxes: Checkboxes): boolean => {
  const checkedCheckboxesLength = checkboxes.filter(checkboxItem => checkboxItem.isChecked).length;

  return (checkedCheckboxesLength === checkboxes.length - 1);
};

const getChangedCheckboxes = (index: number, checkboxes: Checkboxes): Checkboxes => {
  let newArrayCheckboxes: Checkboxes = JSON.parse(JSON.stringify(checkboxes));
  const isChecked = newArrayCheckboxes[index].isChecked;
  newArrayCheckboxes[index].isChecked = !isChecked;

  if (index === 0) {
    // if 'all categories' option is checked/unchecked, all options are checked/unchecked
    newArrayCheckboxes = newArrayCheckboxes
      .map(checkboxItem => (
        { ...checkboxItem, isChecked: newArrayCheckboxes[0].isChecked }
      ));
  } else {
    //if all categories (instead of 'all categories') are checked, 'all categories' option is checked
    if (newArrayCheckboxes[index].isChecked) {
      newArrayCheckboxes[0].isChecked = isAllCategoriesOptionChecked(newArrayCheckboxes);
    } else {
      //if one of category (instead of 'all categories') is unchecked then 'all categories' option is unchecked
      newArrayCheckboxes[0].isChecked = false;
    }
  }

  return newArrayCheckboxes;
};


export {
  DefaultCheckboxID,
  getChangedCheckboxes,
  getFormFieldCheckboxes,
  getSelectedCategories,
  isAllCategoriesOptionChecked,
};
