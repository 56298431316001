import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Link } from 'dd-client/site/common/components/Link';
import { Component, Props } from './types';
import './Links.scss';

const Links: Component = ({
  className,
  links,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Links',
    className,
  );

  return (
    <div className={rootClassName}>
      <ul className='Links-List'>
        {links.map(({
          id,
          title,
          url,
        }) => (
          <li key={id}>
            <Link
              className='Links-ListLink'
              target="_blank"
              url={url}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export {
  Links,
};
