import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './Loader.scss';

enum StyleType {
  DEFAULT = 'default',
  SMALL = 'small',
}

const Loader: Component = ({
  className,
  styleType = StyleType.DEFAULT,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Loader',
    {
      'Loader--Small': styleType === StyleType.SMALL,
    },
    className,
  );

  return (
    <div className={rootClassName} />
  );
};

Loader.StyleType = StyleType;

export {
  Loader,
  StyleType,
};
