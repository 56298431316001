import React, { MouseEvent, ReactElement, useCallback } from 'react';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './StaticPromotion.scss';

const PROMOTION_IMAGE_SIZE = {
  height: 344,
  width: 688,
};

const StaticPromotion: Component = ({
  promotion,
}: Props): ReactElement => {
  const { url, image, sectionTitle } = promotion;
  const urlCdn = getConfig('url.cdn');

  const handleLinkClick = useCallback(
    (e: MouseEvent) => {
      !url && e.preventDefault();
    },
    [url],
  );

  return (
    <div className="StaticPromotion">
      <div className="StaticPromotion-Title">
        {sectionTitle}
      </div>
      <a
        className="StaticPromotion-Link"
        href={url}
        onClick={handleLinkClick}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt={sectionTitle}
          className='StaticPromotion-Image'
          src={`${urlCdn}/${PROMOTION_IMAGE_SIZE.width}x${PROMOTION_IMAGE_SIZE.height}/${image}`}
          srcSet={`${urlCdn}/${PROMOTION_IMAGE_SIZE.width * 2}x${PROMOTION_IMAGE_SIZE.height * 2}/${image} 2x`}
        />
      </a>
    </div>
  );
};

export {
  StaticPromotion,
};
