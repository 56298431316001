import { Request, Response } from 'express';
import { TransformableInfo } from 'logform';
import { createLogger, format, Logger as WinstonLogger, transports } from 'winston';
import { isDev } from 'dd-server/site/utils/env';
import { LoggerLevel } from 'dd-client/site/common/utils/logger/logger';
import { Logger, LoggerExtras } from 'dd-client/site/common/utils/logger/types';
import { getConfig } from 'dd-client/site/config/utils/config';

const getDefaultWinstonLoggerFields = (): Record<string, string | object> => ({
  app: {
    env: getConfig('env'),
    version: getConfig('version'),
  },
  service: `ssr_${getConfig('siteName')}`,
  source: 'app',
});

class CustomFormatter {
  public transform(info: TransformableInfo) {
    const { timestamp, stack, ...rest } = info;

    return {
      datetime: timestamp,
      ...(stack && { stack }),
      timestamp: new Date(timestamp).getTime(),
      ...rest,
    };
  }
}

const devFormatters = [];
if (isDev()) {
  devFormatters.push(format.prettyPrint());
  devFormatters.push(format.colorize({ all: true }));
}

const winstonLogger = createLogger({
  defaultMeta: getDefaultWinstonLoggerFields(),
  transports: [
    new transports.Console({
      format: format.combine(
        format.errors({ stack: true }),
        format.timestamp(),
        new CustomFormatter(),
        format.json(),
        ...devFormatters,
      ),
    }),
  ],
});

const loggerWithHttpObjects = (req: Request, res: Response): WinstonLogger => {
  return winstonLogger.child({
    request: {
      http_host: req.headers.host,
      method: req.method.toUpperCase(),
      path: req.url,
      referrer: req.headers.referrer || req.headers.referer,
    },
    response: {
      status: res.statusCode,
    },
  });
};

const logger: Logger = {
  [LoggerLevel.DEBUG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.DEBUG, message, extras),
  [LoggerLevel.ERROR]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.ERROR, message, extras),
  [LoggerLevel.INFO]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.INFO, message, extras),
  [LoggerLevel.LOG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.LOG, message, extras),
  [LoggerLevel.WARN]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.WARN, message, extras),
};

const captureMessage = (
  level: LoggerLevel,
  message: string,
  extras: LoggerExtras = {},
): void => {
  winstonLogger.log(level === LoggerLevel.LOG ? 'info' : level, message, extras);
};

export {
  getDefaultWinstonLoggerFields,
  logger,
  loggerWithHttpObjects,
};
