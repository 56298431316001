import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import useDynamicRefs from 'use-dynamic-refs';
import ArrowLeft from 'dd-client/site/common/assets/icons/arrow-left.svg';
import ArrowRight from 'dd-client/site/common/assets/icons/arrow-right.svg';
import PlayIcon from 'dd-client/site/common/assets/icons/play.svg';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { isRetinaDevice } from 'dd-client/site/common/utils/retina';
import { getVideoImage, getVideoLink } from 'dd-client/site/common/utils/video/video';
import { getConfig } from 'dd-client/site/config/utils/config';
import { MediaType } from 'dd-client/site/deal/hooks/api/types';
import { Component, Props } from './';
import './Gallery.scss';

const GALLERY_THUMBNAIL_IMAGE_SIZE = {
  height: 0,
  width: 118,
};

const Gallery: Component = ({
  currentItemIndex,
  media,
}: Props): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState<number>(currentItemIndex);
  const totalMediaCount = media.length;
  const [getRef, setRef] = useDynamicRefs();
  const galleryWrapperRef = useRef<HTMLDivElement>(null);
  const urlCdn = getConfig('url.cdn');

  const renderVideoItem = (item: ReactImageGalleryItem) => (
    <div className="Gallery-VideoWrapper">
      <div className="Gallery-VideoIframe">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="Gallery-Video"
          // @ts-ignore
          ref={setRef(item.original)}
          src={getVideoLink(item.original)}
          title="YouTube video player"
        >
        </iframe>
      </div>
    </div>
  );

  const renderVideoThumbnailItem = (item: ReactImageGalleryItem) => (
    <>
      <img
        alt="Thumbnail"
        className="image-gallery-thumbnail-image"
        src={item.thumbnail}
      />
      <div className="Gallery-ThumbnailPlay">
        <PlayIcon />
      </div>
    </>
  );

  const getThumbnailImageUrl = useCallback(
    (type: MediaType, source: string) => {
      const urlCdn = getConfig('url.cdn');

      return (
        // react-image-gallery does not provide srcSet attribute for thumbnails, url for src attribute is calculated depending on device
        // twice the size for retina devices
        type === MediaType.PHOTO
          ? (
            `${urlCdn}/${
              isRetinaDevice
                ? `${GALLERY_THUMBNAIL_IMAGE_SIZE.width * 2}x${GALLERY_THUMBNAIL_IMAGE_SIZE.height * 2}`
                : `${GALLERY_THUMBNAIL_IMAGE_SIZE.width}x${GALLERY_THUMBNAIL_IMAGE_SIZE.height}`
            }/${source}`
          )
          : getVideoImage(source)
      );
    },
    [],
  );

  const handleSlide = useCallback(
    (nextIndex: number) => {
      if (media[currentIndex].type === MediaType.VIDEO) {
        const videoRef = getRef(media[currentIndex].source);
        // @ts-ignore
        const src = videoRef.current.getAttribute('src') || '';
        const url = new URL(src);
        // @ts-ignore
        videoRef.current.setAttribute('src', url.href);
      }
      setCurrentIndex(nextIndex);
    },
    [currentIndex, getRef, media],
  );

  const handleThumbnailClick = useCallback(
    () => {
      galleryWrapperRef.current?.focus();
    },
    [],
  );

  useEffect(
    () => setCurrentIndex(currentItemIndex),
    [currentItemIndex],
  );

  return (
    <div
      className="Gallery"
      ref={galleryWrapperRef}
      tabIndex={-1}
    >
      <div className="Gallery-Label">
        {media[currentIndex].caption}
      </div>

      <ImageGallery
        items={(
          media.map(item => ({
            original: (
              item.type === MediaType.PHOTO
                ? `${urlCdn}/${item.source}`
                : item.source
            ),
            renderItem: item.type === MediaType.VIDEO ? renderVideoItem : undefined,
            renderThumbInner: item.type === MediaType.VIDEO ? renderVideoThumbnailItem : undefined,
            thumbnail: getThumbnailImageUrl(item.type, item.source),
          }))
        )}
        onSlide={handleSlide}
        onThumbnailClick={handleThumbnailClick}
        renderLeftNav={(onClick, isDisabled) => (
          <ButtonIcon
            className="Gallery-Arrow Gallery-Arrow--Left"
            isDisabled={isDisabled}
            icon={<ArrowLeft />}
            onClick={onClick}
            size={ButtonIcon.Size.SMALL}
            styleType={ButtonIcon.StyleType.GHOST}
          />
        )}
        renderRightNav={(onClick, isDisabled) => (
          <ButtonIcon
            className="Gallery-Arrow Gallery-Arrow--Right"
            isDisabled={isDisabled}
            icon={<ArrowRight />}
            onClick={onClick}
            size={ButtonIcon.Size.SMALL}
            styleType={ButtonIcon.StyleType.GHOST}
          />
        )}
        showFullscreenButton={false}
        showPlayButton={false}
        slideDuration={200}
        startIndex={currentIndex}
      />

      <div className="Gallery-Index">
        {currentIndex + 1} / {totalMediaCount}
      </div>
    </div>
  );
};

export {
  Gallery,
};
