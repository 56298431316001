import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useGoToPage = (page = ''): (
  (e?: MouseEvent<any>) => void
) => {
  const { i18n: { language } } = useTranslation();
  const history = useHistory();

  return useCallback(
    (event?: MouseEvent<any>) => {
      event?.preventDefault();
      window.scrollTo(0, 0);
      return history.push(`/${language}/${page}`);
    },
    [history, language, page],
  );
};

export {
  useGoToPage,
};
