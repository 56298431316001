import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'dd-client/site/common/components/Accordion';
import { ContentNavigation } from 'dd-client/site/common/components/ContentNavigation';
import { AccordionAndContentNavigationData } from 'dd-client/site/common/types';
import { getContentWithSpecialComponents } from 'dd-client/site/common/utils/contentWithSpecialComponents/contentWithSpecialComponents';
import { Apps } from 'dd-client/site/deal/components/DealPage/ProductDetails/Details/Apps';
import { Links } from 'dd-client/site/deal/components/DealPage/ProductDetails/Details/Links';
import { Component, Props } from './types';
import './Details.scss';

const Details: Component = ({
  apps,
  detail,
  links,
  scopeOfDelivery,
  specification,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      apps: t('Apps'),
      details: t('Details'),
      links: t('Links'),
      scopeDelivery: t('Scope of delivery'),
      specification: t('Specification'),
    }),
    [t],
  );

  const detailsData = useMemo(
    (): AccordionAndContentNavigationData => {
      const detailsArray = [];

      if (detail) {
        detailsArray.push(
          {
            content: getContentWithSpecialComponents(detail) as ReactElement,
            header: text.details,
          },
        );
      }

      if (specification) {
        detailsArray.push({
          content: (
              <div
                className="Details-TechnicalData"
                dangerouslySetInnerHTML={{ __html: specification }}
              />
          ),
          header: text.specification,
        });
      }

      if (scopeOfDelivery) {
        detailsArray.push({
          content: <div dangerouslySetInnerHTML={{ __html: scopeOfDelivery }}/>,
          header: text.scopeDelivery,
        });
      }

      if (links.length) {
        detailsArray.push({
          content: <Links links={links} />,
          header: text.links,
        });
      }

      if (apps.links.length) {
        detailsArray.push({
          content: <Apps apps={apps} />,
          header: text.apps,
        });
      }

      return detailsArray;
    },
    [apps, detail, links, scopeOfDelivery, specification, text.apps, text.details, text.links, text.scopeDelivery, text.specification],
  );


  return (
    <div className="Details">
      <Accordion
        className="Details-Accordion"
        data={detailsData}
        defaultOpenIndexArray={[0]}
      />

      <ContentNavigation
        className="Details-ContentNavigation"
        data={detailsData}
        defaultOpenIndex={0}
      />

      <div className="Details-Desktop">
        <div className="Details-Desktop--LeftSide">
          {detail && getContentWithSpecialComponents(detail)}

          {scopeOfDelivery && (
            <>
              <div className="Details-DesktopHeader">
                {text.scopeDelivery}
              </div>
              <div
                className="Details-Desktop--ScopeOfDelivery"
                dangerouslySetInnerHTML={{ __html: scopeOfDelivery }}
              />
            </>
          )}

          {links.length > 0 && (
            <>
              <div className="Details-DesktopHeader">
                {text.links}
              </div>
              <Links
                className="Details-Desktop--Links"
                links={links}
              />
            </>
          )}

          {apps.links.length > 0 && (
            <>
              <div className="Details-DesktopHeader">
                {text.apps}
              </div>
              <Apps apps={apps} />
            </>
          )}
        </div>

        {specification && (
          <div
            className="Details-Desktop--RightSide Details-TechnicalData"
            dangerouslySetInnerHTML={{ __html: specification }}
          />
        )}
      </div>
    </div>
  );
};

export {
  Details,
};
