import React, { ReactElement } from 'react';
import FacebookIcon from './assets/facebook.svg';
import InstagramIcon from './assets/instagram.svg';
import TikTokIcon from './assets/tiktok.svg';
import TwitterIcon from './assets/twitter.svg';
import YoutubeIcon from './assets/youtube.svg';
import { Component } from './types';
import './Socials.scss';

const Socials: Component = (): ReactElement => {
  return (
    <ul className="Socials">
      <li>
        <a
          href="https://www.facebook.com/DayDeal.ch"
          rel="noreferrer"
          target="_blank"
        >
          <FacebookIcon />
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/user/DayDealTV"
          rel="noreferrer"
          target="_blank"
        >
          <YoutubeIcon />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/daydeal"
          rel="noreferrer"
          target="_blank"
        >
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/daydeal.ch/"
          rel="noreferrer"
          target="_blank"
        >
          <InstagramIcon />
        </a>
      </li>
      <li>
        <a
          href="https://www.tiktok.com/@daydeal.ch"
          rel="noreferrer"
          target="_blank"
        >
          <TikTokIcon />
        </a>
      </li>
    </ul>
  );
};

export {
  Socials,
};
