import React from 'react';
import classNames from 'classnames';
import PlayIcon from 'dd-client/site/common/assets/icons/play.svg';
import { Component, Props } from './types';
import './VideoButton.scss';

const VideoButton: Component = ({
  className,
  onClick,
}: Props) => {
  const rootClassName = classNames(
    'VideoButton',
    className,
  );

  return (
    <button
      className={rootClassName}
      data-testid="video-button"
      onClick={onClick}
    >
      <PlayIcon />
    </button>
  );
};

export {
  VideoButton,
};
