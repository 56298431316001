import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { getConfig } from 'dd-client/site/config/utils/config';
import { FetchResponseBody } from './connector.types';
import { SubscriptionSettings } from './types';

const getSubscriptionSettings = (context: QueryFunctionContext): Promise<SubscriptionSettings> => {
  return getApiRequest({
    context,
    url: `${getConfig('url.api.deal')}/subscription/settings?id=${context.queryKey[2]}&uid=${context.queryKey[3]}&locale=${context.queryKey[1]}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError);
};

const mapResponseToState = (
  response: AxiosResponse<FetchResponseBody>,
): SubscriptionSettings => {
  const {
    data: { email, categoryIds, specialCategories },
  } = response;

  return ({
    categoryIds,
    email,
    specialCategories,
  });
};

export {
  getSubscriptionSettings,
};
