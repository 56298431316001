import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { handleApiRequestError } from 'dd-client/site/common/utils/api/error';
import { getConfig } from 'dd-client/site/config/utils/config';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';
import { Comment as ResponseComment, Comments as ResponseComments } from './connector.types';
import { Comment, Comments } from './types';

enum State {
  CENSORED = 'censored',
  EMPTY = 'empty',
  PINNED = 'pinned',
}

const getComments = (context: QueryFunctionContext): Promise<Comments> => (
  getApiRequest({
    context,
    url: `${getConfig('url.api.comment')}/comment/list/${context.queryKey[2]}`,
  }).then(
    (response) => mapResponseToState(response),
  ).catch(handleApiRequestError)
);

const mapResponseToState = (
  response: AxiosResponse<ResponseComments>,
): Comments => {
  const {
    data,
  } = response;
  const { t } = i18nextInit;
  const text = {
    contentWasCensored: t('The content was censored'),
  };

  const getCommentObject = (comment: ResponseComment): Comment => ({
    author: comment.author,
    content: (
      comment.state === State.CENSORED
        ? text.contentWasCensored
        : comment.content
    ),
    id: comment.id,
    isPinned: comment.state === State.PINNED,
    postedDate: comment.postedDate.replace(' ', 'T'),
    rating: comment.rating,
  });

  return ({
    threads: Array.isArray(data.threads)
      ? data.threads.map(thread => (
        {
          comment: getCommentObject(thread.comment),
          replies: Array.isArray(thread.replies)
            ? thread.replies.map(reply => (
              getCommentObject(reply)
            ))
            : [],
          title: thread.title,
        }
      ))
      : [],
    totalComments: data.totalComments,
  });
};

export {
  getComments,
  State,
};
