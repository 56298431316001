import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import useImage from 'react-hook-image';
import localforage from 'localforage';
import { useAsyncEffect } from 'use-async-effect';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { isRetinaDevice } from 'dd-client/site/common/utils/retina';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Promotion as PromotionType } from 'dd-client/site/promotions/hooks/usePromotions';
import { Promotion } from './Promotion';
import { Component, PromotionsStorage, Props } from './types';

const CLOSE_ANIMATION_DURATION = 100;
const DELAY = 15 * 1000;
const PROMOTIONS_KEY = 'promotions';
const SHOW_AGAIN_TIME = 24 * 60 * 60 * 1000;
const PROMOTION_IMAGE_SIZE = {
  height: 192,
  width: 384,
};

const DismissiblePromotion: Component = ({
  promotion,
}: Props): ReactElement | null => {
  const [isOpen, setIsOpen] = useState(true);
  const [isDelayExpired, setIsDelayExpired] = useState(false);
  const [viewedPromotions, setViewedPromotions] = useState<PromotionsStorage | null>(null);
  const { src: imageSrc } = useImage(
    `${getConfig('url.cdn')}/${
      isRetinaDevice
        ? `${PROMOTION_IMAGE_SIZE.width * 2}x${PROMOTION_IMAGE_SIZE.height * 2}`
        : `${PROMOTION_IMAGE_SIZE.width}x${PROMOTION_IMAGE_SIZE.height}`
    }/${promotion.image}`,
  );

  useAsyncEffect(async () => {
    try {
      const loadedPromotions: PromotionsStorage = await localforage.getItem(PROMOTIONS_KEY) || {};
      setViewedPromotions(loadedPromotions);
    } catch (e) {
      logger.error(e);
    }
  }, [promotion]);

  useEffect(() => {
    setIsDelayExpired(false);
    setIsOpen(true);
    const timeout = setTimeout(
      () => setIsDelayExpired(true),
      DELAY,
    );

    return () => clearTimeout(timeout);
  }, [promotion]);

  const handleClose = useCallback(
    () => {
      setIsOpen(false);
    },
    [],
  );

  const shouldBeShown = (promotion: PromotionType): boolean => {
    const currentTime = Date.now();
    const lastViewedTime = viewedPromotions?.[promotion.id] || 0;

    return isDelayExpired
      && !!imageSrc
      && !!viewedPromotions
      && (currentTime - lastViewedTime > SHOW_AGAIN_TIME);
  };

  if (shouldBeShown(promotion)) {
    const updatedViewedPromotions = {
      ...viewedPromotions,
      [promotion.id]: Date.now(),
    };

    localforage.setItem(PROMOTIONS_KEY, updatedViewedPromotions);

    return (
      <Promotion
        imageSrc={imageSrc || ''}
        isOpen={isOpen}
        onClose={handleClose}
        sectionTitle={promotion.sectionTitle}
        url={promotion.url}
      />
    );
  }

  return null;
};

export {
  CLOSE_ANIMATION_DURATION,
  DismissiblePromotion,
};
