import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Head } from 'dd-client/site/app/components/App/Head';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Loader } from 'dd-client/site/common/components/Loader';
import { RetryButton } from 'dd-client/site/common/components/RetryButton';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { BREAKPOINTS } from 'dd-client/site/common/utils/breakpoints';
import { getContentWithSpecialComponents } from 'dd-client/site/common/utils/contentWithSpecialComponents/contentWithSpecialComponents';
import { getConfig } from 'dd-client/site/config/utils/config';
import { useStaticPage } from 'dd-client/site/static/hooks/useStaticPage';
import { Component } from './types';
import './StaticPage.scss';

const StaticPage: Component = (): ReactElement | null => {
  const { staticPageParam } = usePageMatch();
  const staticPage = useStaticPage(staticPageParam);
  const { isMobile, isTablet, isXLDesktop, isLDesktop } = useDevice();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      cannotLoad: t('Cannot load search results.'),
      retry: t('Retry'),
    }),
    [t],
  );

  const getHeaderImageSource = useCallback(
    (headerImage: string) => {
      const urlCdn = getConfig('url.cdn');
      let source: {src: string; srcSet?: string} = {
        src: `${urlCdn}/${headerImage}`,
      };

      if (isXLDesktop) {
        source = {
          src: `${urlCdn}/${BREAKPOINTS.xxl}x0/${headerImage}`,
        };
      } else if (isLDesktop) {
        source = {
          src: `${urlCdn}/${BREAKPOINTS.xl}x0/${headerImage}`,
          srcSet: `${urlCdn}/${BREAKPOINTS.xl * 2}x0/${headerImage} 2x`,
        };
      } else if (isTablet) {
        source = {
          src: `${urlCdn}/${BREAKPOINTS.lg}x0/${headerImage}`,
          srcSet: `${urlCdn}/${BREAKPOINTS.lg * 2}x0/${headerImage} 2x`,
        };
      } else if (isMobile) {
        source = {
          src: `${urlCdn}/${BREAKPOINTS.md}x0/${headerImage}`,
          srcSet: `${urlCdn}/${BREAKPOINTS.md * 2}x0/${headerImage} 2x`,
        };
      }

      return source;
    },
    [isLDesktop, isMobile, isTablet, isXLDesktop],
  );

  const handleRetryButtonClick = useCallback(
    () => staticPage.refetch(),
    [staticPage],
  );

  return (
    <div className="StaticPage">
      {staticPage.isSuccess && (
        <>
          <Head
            analytics={{
              title: staticPage.data.analytics.metaTitle,
            }}
            title={staticPage.data.meta.title}
            description={staticPage.data.meta.description}
            ogTags={{
              ...staticPage.data.og,
              type: 'website',
              url: `${getConfig('url.root')}${pathname}`,
            }}
          />

          {staticPage.data.headerImage && (
            <div className="StaticPage-HeaderImageWrapper">
              <img
                alt={staticPage.data.title}
                className="StaticPage-HeaderImage"
                {...getHeaderImageSource(staticPage.data.headerImage)}
                suppressHydrationWarning
              />
            </div>
          )}

          <div className="container">
            <div className="StaticPage-Content">
              {staticPage.data.content && getContentWithSpecialComponents(staticPage.data.content)}
            </div>
          </div>
        </>
      )}

      {staticPage.isPending && (
        <Loader className="StaticPage-Loader" />
      )}

      {staticPage.isError && (
        <div className="container">
          <div className="StaticPage-Content">
            <Alert
              isVisible
              styleType={Alert.StyleType.WARNING}
            >
              {text.cannotLoad}

              <RetryButton onClick={handleRetryButtonClick} />
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
};

export {
  StaticPage,
};
