import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Head } from 'dd-client/site/app/components/App/Head';
import { Button } from 'dd-client/site/common/components/Button';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component } from './types';
import './NoActiveDeals.scss';

const parentName = getConfig('parent.name');
const parentUrl = getConfig('parent.url');
const newsletterUrl = getConfig('parent.newsletterUrl');
const title = getConfig('url.root', '').replace('https://', '');

const NoActiveDeals: Component = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      noRunningDeals: (newsletterUrl: string) => t(
        'No running deals at the moment. Visit us later or <a href="{newsletterUrl}">subscribe for newsletter</a> to not miss a future occasion!',
        { newsletterUrl },
      ),
      returnButton: (parentName: string) => t('Return to {parentName}', { parentName }),
      soldOut: t('Sold out!'),
    }),
    [t],
  );

  return (
    <div className="NoActiveDeals">
      <Head
        analytics={{
          title: title,
        }}
        title={title}
      />
      <h1 className="NoActiveDeals-Title">
        {text.soldOut}
      </h1>
      <span
        className="NoActiveDeals-Details"
        dangerouslySetInnerHTML={{
          __html: text.noRunningDeals(newsletterUrl),
        }}
      />
      <Button
        className="NoActiveDeals-Button"
        href={parentUrl}
        styleType={Button.StyleType.PRIMARY}
      >
        {text.returnButton(parentName)}
      </Button>
    </div>
  );
};

export {
  NoActiveDeals,
};
