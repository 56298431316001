import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { Comments } from './types';

const useComments = (dealId: number): UseQueryResult<Comments, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    queryFn: QUERY.comments.queryFunction,
    queryKey: [QUERY.comments.queryName, language, dealId],
  });
};

export {
  useComments,
};

