import { LoggerLevel } from '../logger';
import { Logger, LoggerExtras } from '../types';

const consoleLogger: Logger = {
  [LoggerLevel.DEBUG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.DEBUG, message, extras),
  [LoggerLevel.ERROR]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.ERROR, message, extras),
  [LoggerLevel.INFO]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.INFO, message, extras),
  [LoggerLevel.LOG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.LOG, message, extras),
  [LoggerLevel.WARN]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(LoggerLevel.WARN, message, extras),
};

const captureMessage = (
  level: LoggerLevel,
  message: string,
  extras: LoggerExtras = {},
): void => {
  console[level]({ // eslint-disable-line no-console
    extras,
    message,
  });
};

export {
  consoleLogger,
};
