import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { Deal } from 'dd-client/site/deal/hooks/api/types';

const useCategoryDeal = (categorySlug: string | undefined): UseQueryResult<Deal, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    enabled: !!categorySlug,
    queryFn: QUERY.categoryDeal.queryFunction,
    queryKey: [QUERY.categoryDeal.queryName, language, categorySlug],
  });
};


export {
  useCategoryDeal,
};

