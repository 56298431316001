import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { SubmitCompetitionData } from './types';

const submitCompetition = (competitionData: SubmitCompetitionData): Promise<any> => (
  getApiRequest({
    data: competitionData,
    method: 'post',
    url: `${getConfig('url.api.deal')}/newsletter/competition`,
  })
);

export {
  submitCompetition,
};
