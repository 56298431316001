import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import localforage from 'localforage';
import { useSetRecoilState } from 'recoil';
import { useAsyncEffect } from 'use-async-effect';
import { Comment } from 'dd-client/site/comments/hooks/useComments';
import { useSubmitComment } from 'dd-client/site/comments/hooks/useSubmitComment';
import { useSubmitReply } from 'dd-client/site/comments/hooks/useSubmitReply';
import { useVote, VoteResponse } from 'dd-client/site/comments/hooks/useVote';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import PlusIcon from 'dd-client/site/common/assets/icons/plus.svg';
import RefreshIcon from 'dd-client/site/common/assets/icons/refresh.svg';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { userIpAddress } from 'dd-client/site/common/utils/global';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { CommentBox } from '../CommentBox';
import { clearSubmittedDataLocalForage, StorageKey, VOTE_TYPE_VALUE, VoteType } from '../utils/comments';
import { Component, Props, SubmittedVotes } from './types';
import { WriteCommentForm, WriteCommentFormData } from './WriteCommentForm';
import './CommentList.scss';

const DISTANCE_BETWEEN_REPLY_FORM_AND_HEADER = 88; // header height plus 16px gap

const CommentList: Component = ({
  className,
  closeModal,
  comments,
  dealId,
  isWriteCommentFormOpen,
  modalHtmlElement,
}: Props): ReactElement => {
  const [isModalScrolled, setIsModalScrolled] = useState(false);
  const [selectedReplyForm, setSelectedReplyForm] = useState<{
    commentId: number | null;
    commentTitle: string;
  }>({
    commentId: null,
    commentTitle: '',
  });
  const [moreRepliesId, setMoreRepliesId] = useState<Array<number>>([]);
  const [submittedVotes, setSubmittedVotes] = useState<SubmittedVotes>({});
  const [writeComment, setWriteComment] = useState({
    isButtonVisible: !isWriteCommentFormOpen,
    isFormVisible: isWriteCommentFormOpen,
  });
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const replyFormRef = useRef<HTMLDivElement>(null);
  const replyMessageTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const writeCommentTitleInputRef = useRef<HTMLInputElement>(null);
  const submitComment = useSubmitComment();
  const submitReply = useSubmitReply();
  const vote = useVote();
  const setIpAddress = useSetRecoilState(userIpAddress);
  const goToBanPage = useGoToPage('banned-ip');

  const rootClassName = classNames(
    'CommentList',
    {
      'CommentList--StickyHeader': isModalScrolled,
    },
    className,
  );
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      comments: t('Comments'),
      couldNotLoadComments: t('Couldn\'t load comments, please try again.'),
      loadNewComments: t('Load new comments'),
      showMoreComments: (countComments: number) => t(
        'comments',
        { count: countComments, defaultValue: '{count, plural, one {show 1 more comment} other {show {count} more comments}}' },
      ),
      writeComment: t('Write a comment...'),
    }),
    [t],
  );

  const handleWriteCommentFormCancel = () => {
    setWriteComment({
      isButtonVisible: true,
      isFormVisible: false,
    });
  };

  const handleWriteCommentButtonClick = useCallback(
    () => {
      setWriteComment({
        isButtonVisible: false,
        isFormVisible: true,
      });
      submitComment.reset();

      modalHtmlElement?.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });

      setTimeout(() => {
        if (modalHtmlElement?.scrollTop === 0) {
          writeCommentTitleInputRef.current?.focus();
        }
      });
    },
    [modalHtmlElement, submitComment],
  );

  const handleLoadNewCommentsButtonClick = useCallback(
    async () => {
      try {
        await comments.refetch();
      } catch (error) {
        logger.error(error);
      }
    },
    [comments],
  );

  const handleReplyButtonClick = useCallback(
    (commentId: number, commentTitle: string) => () => {
      setSelectedReplyForm({
        commentId,
        commentTitle,
      });
      submitReply.reset();

      setTimeout(() => {
        if (replyFormRef.current && modalHtmlElement) {
          const TOP_DISTANCE = replyFormRef.current.getBoundingClientRect().top
            + modalHtmlElement.scrollTop
            - DISTANCE_BETWEEN_REPLY_FORM_AND_HEADER;

          let timeoutId: ReturnType<typeof setTimeout>;

          const scrollFinished = () => {
            modalHtmlElement.removeEventListener('scroll', scrollListener);
            replyMessageTextAreaRef.current?.focus();
          };

          const scrollListener = () => {
            clearTimeout(timeoutId);

            // scroll is finished when either the position has been reached, or 50ms have elapsed since the last scroll event
            if (modalHtmlElement.scrollTop === TOP_DISTANCE) {
              scrollFinished();
            } else {
              timeoutId = setTimeout(scrollFinished, 50);
            }
          };

          modalHtmlElement.addEventListener('scroll', scrollListener);
          scrollListener();

          modalHtmlElement?.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: TOP_DISTANCE,
          });
        }
      });
    },
    [modalHtmlElement, submitReply],
  );

  const handleReplyCommentFormCancel = () => setSelectedReplyForm({
    commentId: null,
    commentTitle: '',
  });

  const handleMoreCommentsClick = (commentId: number) => () => {
    setMoreRepliesId(
      prevMoreRepliesId => (
        [...prevMoreRepliesId, commentId]
      ),
    );
  };

  const handleCommentListScroll = () => {
    setIsModalScrolled(!!modalHtmlElement?.scrollTop);

    if (modalHtmlElement?.scrollTop === 0) {
      writeCommentTitleInputRef.current?.focus();
    }
  };

  const handleVoteButtonClick = useCallback(
    (commentId: number, voteType: VoteType) => async () => {
      try {
        const localForageVotes: SubmittedVotes | null = await localforage.getItem(StorageKey.SUBMITTED_VOTES);
        const newLocalForageVotes: SubmittedVotes = localForageVotes || {};
        newLocalForageVotes[commentId] = newLocalForageVotes[commentId] || {};
        let voteApiResponse: AxiosResponse<VoteResponse>;

        if (newLocalForageVotes[commentId].voteType === voteType) {
          delete newLocalForageVotes[commentId];
          voteApiResponse = await vote.mutateAsync({ commentId, vote: VOTE_TYPE_VALUE.unClickVote });
        } else {
          newLocalForageVotes[commentId].voteType = voteType;
          voteApiResponse = await vote.mutateAsync({ commentId, vote: VOTE_TYPE_VALUE[voteType] });
        }
        await localforage.setItem(StorageKey.SUBMITTED_VOTES, newLocalForageVotes);

        setSubmittedVotes(
          prevSubmittedVotes => ({
            ...prevSubmittedVotes,
            [commentId]: {
              ...newLocalForageVotes[commentId],
              rating: voteApiResponse.data.rating,
            },
          }),
        );
      } catch (error) {
        logger.error(error);
      }
    },
    [vote],
  );

  const handleSubmit = useCallback(
    (data: WriteCommentFormData, isReply: boolean, token: string | null): void  => {
      const { message, messageReply, email, name, title } = data;
      const content = (
        isReply
          ? messageReply
          : message
      )?.replace(/(\r\n|\r|\n)/g, '<br>') || '';

      if (isReply) {
        return (
          submitReply.mutate({
            commentId: selectedReplyForm.commentId || 0,
            content,
            email,
            name,
            token,
          })
        );
      }

      return (
        submitComment.mutate({
          content,
          dealId,
          email,
          name,
          title: title || '',
          token,
        })
      );
    },
    [dealId, selectedReplyForm.commentId, submitComment, submitReply],
  );

  const getCommentBoxComponent = useCallback(
    (comment: Comment, id: number, title: string, isReply = false) => (
      <CommentBox
        areVotingButtonsDisabled={
          vote.variables?.commentId === comment.id
          && vote.isPending
        }
        authorName={comment.author.name}
        avatarImg={comment.author.avatar}
        badge={comment.author.badge}
        content={comment.content}
        isPinned={comment.isPinned}
        key={comment.id}
        onLikeButtonClick={handleVoteButtonClick(comment.id, VoteType.LIKE)}
        onDislikeButtonClick={handleVoteButtonClick(comment.id, VoteType.DISLIKE)}
        onReplyButtonClick={handleReplyButtonClick(id, title)}
        rating={
          submittedVotes[comment.id]?.rating !== undefined
            ? submittedVotes[comment.id].rating
            : comment.rating
        }
        postedTime={Date.parse(comment.postedDate)}
        title={
          isReply
            ? undefined
            : title
        }
        wasDisliked={submittedVotes[comment.id]?.voteType === VoteType.DISLIKE}
        wasLiked={submittedVotes[comment.id]?.voteType === VoteType.LIKE}
      />
    ),
    [vote.variables?.commentId, vote.isPending, handleVoteButtonClick, handleReplyButtonClick, submittedVotes],
  );

  useEffect(
    () => {
      closeButtonRef.current?.focus();
      writeCommentTitleInputRef.current?.focus();
    },
    [],
  );

  useEffect(
    () => {
      modalHtmlElement?.addEventListener('scroll', handleCommentListScroll);

      return () => {
        modalHtmlElement?.removeEventListener('scroll', handleCommentListScroll);
      };
    },
  );

  useAsyncEffect(
    async () => {
      try {
        const localForageVotes: SubmittedVotes | null = await localforage.getItem(StorageKey.SUBMITTED_VOTES);
        setSubmittedVotes(localForageVotes || {});
      } catch (error) {
        logger.error(error);
      }
    },
    [],
  );

  useAsyncEffect(
    async () => {
      if (submitComment.isSuccess) {
        await clearSubmittedDataLocalForage(false);

        setWriteComment({
          isButtonVisible: true,
          isFormVisible: true,
        });
      }
    },
    [submitComment.isSuccess],
  );

  useAsyncEffect(
    async () => {
      if (submitReply.isSuccess) {
        await clearSubmittedDataLocalForage(true);
      }
    },
    [submitReply.isSuccess],
  );

  useEffect(
    () => {
      if (submitComment.isError) {
        const errorName = submitComment.error?.response?.data.error;
        const userIp = submitComment.error?.response?.data.ip;

        if (errorName === 'BANNED_IP' && userIp !== undefined) {
          setIpAddress(userIp);
          closeButtonRef.current?.click();

          setTimeout(() => {
            goToBanPage();
          }, 0);
        }
      }
    },
    // @ts-ignore
    [goToBanPage, setIpAddress, submitComment.error?.response?.data?.error, submitComment.error?.response?.data?.ip, submitComment.isError],
  );

  return (
    <div className={rootClassName}>
      <div className="CommentList-HeaderWrapper">
        <div className="CommentList-Title">
          {text.comments} ({comments.isSuccess ? comments.data.totalComments : 0})
        </div>

        {writeComment.isButtonVisible && (
          <Button
            className="CommentList-WriteCommentButton"
            iconPosition={Button.IconPosition.LEFT}
            onClick={handleWriteCommentButtonClick}
            size={Button.Size.SMALL}
          >
            <PlusIcon />
            {text.writeComment}
          </Button>
        )}

        <ButtonIcon
          buttonRef={closeButtonRef}
          className="CommentList-CloseButton"
          icon={<CloseIcon />}
          onClick={closeModal}
          size={ButtonIcon.Size.SMALL}
          styleType={ButtonIcon.StyleType.GHOST}
        />
      </div>

      {writeComment.isFormVisible && (
        <WriteCommentForm
          className="CommentList-WriteCommentForm"
          isError={submitComment.isError}
          isPending={submitComment.isPending}
          isSuccess={submitComment.isSuccess}
          onCancel={handleWriteCommentFormCancel}
          onSubmit={handleSubmit}
          titleInputRef={writeCommentTitleInputRef}
        />
      )}

      {comments.isSuccess && (
        <Button
          className="CommentList-LoadNewCommentsButton"
          iconPosition={Button.IconPosition.LEFT}
          isDisabled={comments.isFetching}
          isFullWidth
          onClick={handleLoadNewCommentsButtonClick}
          size={Button.Size.SMALL}
          styleType={Button.StyleType.SECONDARY}
        >
          <RefreshIcon />
          {text.loadNewComments}
        </Button>
      )}

      {comments.isRefetchError && (
        <Alert
          className="CommentList-Alert"
          isVisible
          styleType={Alert.StyleType.WARNING}
        >
          {text.couldNotLoadComments}
        </Alert>
      )}

      <div className="CommentList-List">
        {
          comments.isSuccess && comments.data.threads
            .map(
              ({ comment, replies, title }) => (
                <React.Fragment key={comment.id}>
                  {getCommentBoxComponent(comment, comment.id, title)}

                  {replies.length > 0 && (
                    <div className="CommentList-Replies">
                      {replies
                        .slice(0, moreRepliesId.includes(comment.id) ? replies.length : 2)
                        .map(
                          reply => (
                            getCommentBoxComponent(reply, comment.id, title, true)
                          ),
                        )
                      }
                      {replies.length > 2 && !moreRepliesId.includes(comment.id) && (
                        <Button
                          size={Button.Size.SMALL}
                          styleType={Button.StyleType.LIGHT}
                          onClick={handleMoreCommentsClick(comment.id)}
                        >
                          {text.showMoreComments(replies.length - 2)}
                        </Button>
                      )}
                    </div>
                  )}

                  {selectedReplyForm.commentId === comment.id && (
                    <div className="CommentList-ReplyCommentWrapper">
                      <WriteCommentForm
                        isError={submitReply.isError}
                        isPending={submitReply.isPending}
                        isReply={true}
                        isSuccess={submitReply.isSuccess}
                        onCancel={handleReplyCommentFormCancel}
                        onSubmit={handleSubmit}
                        replyMessageTextAreaRef={replyMessageTextAreaRef}
                        replyTitle={selectedReplyForm.commentTitle}
                        writeCommentRef={replyFormRef}
                      />
                    </div>
                  )}
                </React.Fragment>
              ),
            )
        }
      </div>
    </div>
  );
};

export {
  CommentList,
};
