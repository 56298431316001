import envConfig from 'dd-client/site/config/env';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import packageJson from '../../../../../package.json';

export default Object.freeze({
  appName: packageJson.name,
  languages: {
    available: [
      {
        code: LanguageCode.DE,
        label: 'DE',
      },
      {
        code: LanguageCode.FR,
        label: 'FR',
      },
    ],
  },
  version: packageJson.version,
  ...envConfig,
});
