import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Head } from 'dd-client/site/app/components/App/Head';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Loader } from 'dd-client/site/common/components/Loader';
import { RetryButton } from 'dd-client/site/common/components/RetryButton';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { NewsType, useNews } from 'dd-client/site/news/hooks/useNews';
import { Item } from './Item';
import { Component } from './types';
import './NewsPage.scss';

const NewsPage: Component = (): ReactElement | null => {
  const { t, i18n } = useTranslation();
  const de = i18n.getFixedT(LanguageCode.DE);
  const news = useNews(NewsType.ALL);

  const text = useMemo(
    () => ({
      canNotLoad: t('Cannot load search results.'),
      news: t('News'),
      newsPageMetaDescription: t('News page meta description'),
      newsPageMetaTitle: t('News page meta title'),
      newsPageMetaTitleDE: de('News page meta title'),
    }),
    [t, de],
  );

  const handleRetryButtonClick = useCallback(
    () => news.refetch(),
    [news],
  );

  const handleLoadMoreNews = useCallback(
    () => !news.isFetchingNextPage && news.fetchNextPage(),
    [news],
  );

  const renderLoader = useMemo(
    () => <Loader className="NewsPage-Loader"/>,
    [],
  );

  return (
    <div className="NewsPage">
      <Head
        analytics={{
          title: text.newsPageMetaTitleDE,
        }}
        description={text.newsPageMetaDescription}
        title={text.newsPageMetaTitle}
      />

      <div className="container">
        <h1>
          {text.news}
        </h1>
      </div>

      <div className="NewsPage-Content">
        <div className="container">
          {news.isPending && renderLoader}

          {news.isSuccess && news.data.pages[0].totalCount > 0 && (
            <InfiniteScroll
              loadMore={handleLoadMoreNews}
              hasMore={news.hasNextPage}
            >
              <ul className="NewsPage-List">
                {news.data.pages.map(
                  newsGroup => (
                    newsGroup.results.map(
                      ({ headline, imageUrl, id, leadText, title, url }) => (
                        <Item
                          headline={headline}
                          imageUrl={imageUrl}
                          key={id}
                          leadText={leadText}
                          title={title}
                          url={url}
                        />
                      ),
                    )
                  ),
                )}
              </ul>

              {news.isFetchingNextPage && renderLoader}
            </InfiniteScroll>
          )}

          {news.isError && (
            <Alert
              isVisible
              styleType={Alert.StyleType.WARNING}
            >
              {text.canNotLoad}

              <RetryButton onClick={handleRetryButtonClick} />
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export {
  NewsPage,
};
