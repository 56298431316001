import { getFormFieldCheckboxes, isAllCategoriesOptionChecked } from 'dd-client/site/common/utils/newsletter/newsletter';
import { Checkboxes } from 'dd-client/site/common/utils/newsletter/types';
import { SpecialCategories, SubscriptionSettings } from 'dd-client/site/newsletter/hooks/useSubscriptionSettings';
import { ActiveCategories } from 'dd-client/site/pastDeals/hooks/useActiveCategories';

const mergeCheckboxesData = (
  activeCategories: ActiveCategories | undefined,
  subscriptionSettings: SubscriptionSettings | undefined,
): Checkboxes => {
  if (activeCategories && subscriptionSettings) {
    const defaultCheckboxes = getFormFieldCheckboxes().defaultCheckboxItems
      .map(defaultCheckboxItem => ({
        ...defaultCheckboxItem,
        isChecked: subscriptionSettings.specialCategories[defaultCheckboxItem.id as keyof SpecialCategories]  === 1,
      }));

    const activeCategoriesCheckboxes = activeCategories
      .slice(1)
      .map(activeCategoryItem => ({
        ...activeCategoryItem,
        isChecked: subscriptionSettings.categoryIds.includes(activeCategoryItem.id) || false,
      }));

    const checkboxes = [
      { ...getFormFieldCheckboxes().defaultCheckboxItemAllOption, isChecked: false },
      ...activeCategoriesCheckboxes,
      ...defaultCheckboxes,
    ];
    checkboxes[0].isChecked = isAllCategoriesOptionChecked(checkboxes);

    return checkboxes;
  }

  return [
    getFormFieldCheckboxes().defaultCheckboxItemAllOption,
    ...getFormFieldCheckboxes().defaultCheckboxItems,
  ];
};

export {
  mergeCheckboxesData,
};
