import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { logs, LogType } from 'dd-client/site/common/utils/logs/client';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Deal as EventDeal } from 'dd-client/site/deal/hooks/api/connector.types';
import { Deal, Variants } from 'dd-client/site/deal/hooks/api/types';
import { mapDealsUpdateEventToState } from 'dd-client/site/deal/hooks/api/useActiveDeals/connector';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

enum EventNames {
  DEALS_UPDATE = 'deals_update',
  AVAILABILITY_UPDATE = 'availability_update',
}

interface AvailabilityUpdateObject {
  availablePercentage: number;
  buyable: boolean;
  hasReservedProducts: boolean;
  id: number | string;
  isSoldOut: boolean;
  navisionId: number;
  sku: number;
}

interface AvailabilityUpdateData extends AvailabilityUpdateObject {
  variants: Array<AvailabilityUpdateObject>;
}

interface AvailabilityUpdateEvent {
  event: EventNames.AVAILABILITY_UPDATE;
  payload: Array<AvailabilityUpdateData>;
}

interface DealsUpdateEvent {
  event: EventNames.DEALS_UPDATE;
  payload: Array<EventDeal>;
}

type ServerSideEvent = AvailabilityUpdateEvent | DealsUpdateEvent;

const DEFAULT_AVAILABILITY = {
  availablePercentage: 100,
  buyable: true,
  hasReservedProducts: false,
  isSoldOut: false,
};

const updateVariantsAvailability = (
  activeDealVariants: Variants,
  newDealAvailability?: AvailabilityUpdateData,
): Variants => (
  activeDealVariants.map(
    variantsGroup => {
      const variants = variantsGroup.items.map(
        variant => {
          const newVariantAvailability = newDealAvailability?.variants.find(
            newAvailabilityItem => variant.id === newAvailabilityItem.id,
          );
          const { availablePercentage, buyable, hasReservedProducts, isSoldOut } = newVariantAvailability || DEFAULT_AVAILABILITY;
          const sku = newVariantAvailability?.sku || variant.sku;

          return {
            ...variant,
            availability: {
              availablePercentage, buyable, hasReservedProducts, isSoldOut,
            },
            sku,
          };
        },
      );

      return {
        ...variantsGroup,
        items: variants,
      };
    },
  )
);

const updateAvailability = (
  activeDeals?: Array<Deal>,
  availabilityDeals: Array<AvailabilityUpdateData> = [],
): Array<Deal> | undefined => (
  Array.isArray((activeDeals))
    ? activeDeals
      .map(
        activeDeal => {
          const newAvailability = availabilityDeals.find(
            newAvailabilityItem => activeDeal.id === newAvailabilityItem.id,
          );

          const { availablePercentage, buyable, hasReservedProducts, isSoldOut } = newAvailability || DEFAULT_AVAILABILITY;

          return {
            ...activeDeal,
            availability: {
              availablePercentage, buyable, hasReservedProducts, isSoldOut,
            },
            variants: updateVariantsAvailability(activeDeal.variants, newAvailability),
          };
        },
      ) : undefined
);

const updateDeals = (
  activeDeals?: Array<Deal>,
  newActiveDeals: Array<Deal> = [],
): Array<Deal> => {
  if (!activeDeals || activeDeals.length < 1) {
    return newActiveDeals;
  }

  return newActiveDeals.map(
    newActiveDeal => {
      const activeDeal = activeDeals.find(
        activeDealItem => newActiveDeal.id === activeDealItem.id,
      );

      const newDeal = {
        ...activeDeal,
        ...newActiveDeal,
      };

      if (activeDeal) {
        newDeal.availability = activeDeal.availability;
      }

      return newDeal;
    },
  );
};

const injectActiveDealsToOtherQueries = (queryClient: QueryClient, activeDeals: Array<Deal> = [], language: LanguageCode) => {
  activeDeals.forEach(activeDeal => {
    queryClient.setQueryData([QUERY.categoryDeal.queryName, language, activeDeal.category.slug], activeDeal);
    queryClient.setQueryData([QUERY.deal.queryName, language, activeDeal.slug], activeDeal);
  });
};

const useSseUpdates = (): void => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const language = i18n.language as LanguageCode;
  const tenant = getConfig('tenant');
  const activeDeals: Array<Deal> = queryClient.getQueryData([QUERY.activeDeals.queryName, language]) || [];

  injectActiveDealsToOtherQueries(queryClient, activeDeals, language);

  useEffect(
    () => {
      const url = `${getConfig('url.api.broadcast')}/multisub?tenant=${tenant}&lang=${language}`;
      const sse = new EventSource(url);

      sse.onopen = () => {
        logs.log(LogType.INFO, 'SSE open connection');
      };

      sse.onmessage = (message) => {
        let data: ServerSideEvent;

        try {
          data = JSON.parse(message.data);
          logs.log(LogType.INFO, 'SSE event received', { event: data.event });
        } catch (e) {
          return logger.error('JSON.parse error in useSSeUpdates', { error: e });
        }

        if (data.event === EventNames.DEALS_UPDATE) {
          queryClient.setQueryData(
            [QUERY.activeDeals.queryName, language],
            (activeDeals?: Array<Deal>) => {
              const updatedActiveDeals = updateDeals(activeDeals, mapDealsUpdateEventToState(data.payload as Array<EventDeal>));
              injectActiveDealsToOtherQueries(queryClient, updatedActiveDeals, language);

              return updatedActiveDeals;
            },
          );
        } else if (data.event === EventNames.AVAILABILITY_UPDATE) {
          queryClient.setQueryData(
            [QUERY.activeDeals.queryName, language],
            (activeDeals?: Array<Deal>) => {
              const updatedActiveDeals = updateAvailability(activeDeals, data.payload as Array<AvailabilityUpdateData>);
              injectActiveDealsToOtherQueries(queryClient, updatedActiveDeals, language);

              return updatedActiveDeals;
            },
          );
        }
      };

      sse.onerror = (e) => {
        console.log(`sse connection error (${new Date().toLocaleTimeString()})`, { error: e });//eslint-disable-line no-console
        logs.log(LogType.ERROR, 'SSE error connection', { error: e });
      };

      return () => {
        sse.close();
      };
    },
    [language, queryClient, tenant],
  );
};

export {
  DEFAULT_AVAILABILITY,
  EventNames,
  updateAvailability,
  updateDeals,
  useSseUpdates,
};

export type {
  AvailabilityUpdateData,
  AvailabilityUpdateEvent,
  DealsUpdateEvent,
  ServerSideEvent,
};
