import React, { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlayIcon from 'dd-client/site/common/assets/icons/play.svg';
import { Modal } from 'dd-client/site/common/components/Modal';
import { getVideoImage, getVideoLink } from 'dd-client/site/common/utils/video/video';
import { getConfig } from 'dd-client/site/config/utils/config';
import { MediaType } from 'dd-client/site/deal/hooks/api/types';
import { Gallery } from './Gallery';
import { Component, Props } from './';
import './Media.scss';

const MAX_MEDIA_COUNT = 6;
const MEDIA_THUMBNAIL_IMAGE_SIZE = {
  height: 0,
  width: 366,
};

const Media: Component = ({ media }: Props): ReactElement | null => {
  const { t } = useTranslation();
  const totalMediaCount = media.length;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(totalMediaCount > MAX_MEDIA_COUNT);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);

  const text = useMemo(
    () => ({
      gallery: t('Gallery'),
    }),
    [t],
  );

  const handleThumbnailClick = (currentItemIndex: number) => (
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (currentItemIndex === (MAX_MEDIA_COUNT - 1) && isCollapsed) {
        setIsCollapsed(false);
        return;
      }

      setCurrentItemIndex(currentItemIndex);
      setIsModalOpen(true);
    }
  );

  const handleModalClose = () => setIsModalOpen(false);

  useEffect(
    () => setIsCollapsed(totalMediaCount > MAX_MEDIA_COUNT),
    [totalMediaCount],
  );

  const getThumbnailImageSource = useCallback(
    (type: MediaType, source: string) => {
      const urlCdn = getConfig('url.cdn');

      return (
        type === MediaType.PHOTO
          ? {
            src: `${urlCdn}/${MEDIA_THUMBNAIL_IMAGE_SIZE.width}x${MEDIA_THUMBNAIL_IMAGE_SIZE.height}/${source}`,
            srcSet: `${urlCdn}/${MEDIA_THUMBNAIL_IMAGE_SIZE.width * 2}x${MEDIA_THUMBNAIL_IMAGE_SIZE.height * 2}/${source} 2x`,
          }
          : {
            src: getVideoImage(source),
          }
      );
    },
    [],
  );

  if (totalMediaCount === 0) {
    return null;
  }

  return (
    <>
      <ul className="Media">
        {media
          .slice(0, isCollapsed ? MAX_MEDIA_COUNT : totalMediaCount)
          .map((item, index) => (
            <li className="Media-Item" key={item.source}>
              <a
                className="Media-Link"
                href={
                  item.type === MediaType.PHOTO
                    ? `${getConfig('url.cdn')}/${item.source}`
                    : getVideoLink(item.source)
                }
                onClick={handleThumbnailClick(index)}
              >
                <img
                  alt="Thumbnail"
                  className="Media-Thumbnail"
                  {...getThumbnailImageSource(item.type, item.source)}
                />

                {item.type === MediaType.VIDEO && (
                  <div className="Media-Play">
                    <PlayIcon />
                  </div>
                )}

                {index === (MAX_MEDIA_COUNT - 1) && isCollapsed && (
                  <div className="Media-MoreOverlay">
                    <div className="Media-More">
                      <span>+{totalMediaCount - MAX_MEDIA_COUNT}</span>
                    </div>
                  </div>
                )}
              </a>
            </li>
          ))}
      </ul>

      <Modal
        className="Media-ModalGallery"
        ariaLabel={text.gallery}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      >
        {() => (
          <Gallery
            currentItemIndex={currentItemIndex}
            media={media}
          />
        )}
      </Modal>
    </>
  );
};

export {
  Media,
};
