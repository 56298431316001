import { getApiRequest } from 'dd-client/site/common/utils/api/api';
import { getConfig } from 'dd-client/site/config/utils/config';
import { SubmitCommentData } from './types';

const createComment = (comment: SubmitCommentData): Promise<any> => (
  getApiRequest({
    data: comment,
    method: 'post',
    url: `${getConfig('url.api.comment')}/comment`,
  })
);

export {
  createComment,
};
