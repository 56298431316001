import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CartIcon from 'dd-client/site/common/assets/icons/cart.svg';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { ProductLabels } from 'dd-client/site/common/components/ProductLabels';
import { formatPrice } from 'dd-client/site/common/utils/price/price';
import { Component, Props } from './types';
import './Item.scss';

const Item: Component = ({
  className,
  labels,
  manufacturer,
  price,
  specialPrice,
  stock,
  subtitle,
  thumbnails,
  title,
  url,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      stock: t('Stock'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'RecommendedProductItem',
    className,
  );

  return (
    <a
      className={rootClassName}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <ProductLabels
        className="RecommendedProductItem-Labels"
        labels={labels}
      />

      <img
        alt={title}
        className="RecommendedProductItem-Image"
        src={thumbnails.standard}
        srcSet={`${thumbnails.large} 2x`}
      />

      <div className="RecommendedProductItem-Manufacturer">
        {manufacturer}
      </div>

      <h3 className="RecommendedProductItem-Title">
        {title}
      </h3>

      <div className="RecommendedProductItem-Subtitle">
        {subtitle}
      </div>

      <div className="RecommendedProductItem-BottomWrapper">
        <div className="RecommendedProductItem-Stock">
          {text.stock}: {stock}
        </div>

        <div className="RecommendedProductItem-PriceWrapper">
          <div className="RecommendedProductItem-Price">
            {specialPrice ? formatPrice(specialPrice) : formatPrice(price)}
          </div>

          {specialPrice && (
            <div className="RecommendedProductItem-OldPrice">
              {formatPrice(price)}
            </div>
          )}
        </div>

        <ButtonIcon
          className="RecommendedProductItem-Button"
          icon={<CartIcon />}
          size={ButtonIcon.Size.SMALL}
        />
      </div>
    </a>
  );
};

export {
  Item,
};
