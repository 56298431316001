import React, { KeyboardEvent, MouseEvent, ReactPortal, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { capitalizeFirstLetter } from 'dd-client/site/common/utils/capitalizeFirstLetter';
import { getIsCapitalLettersFeatureActive } from 'dd-client/site/common/utils/features';
import { disableScrollLock, enableScrollLock } from 'dd-client/site/common/utils/scrollLock';
import { AnimationType } from '../';
import { Component, Props } from './';
import './Modal.scss';

const Modal: Component = ({
  animationType = AnimationType.FADE,
  ariaLabel,
  children,
  className,
  classNameOverlay,
  hasCloseButton = true,
  isOpen,
  onClose,
  setModalHtmlElement,
}: Props): ReactPortal => {
  const modalRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const overlayClassName = classNames(
    'Modal-Overlay',
    `Modal-Animation--${capitalizeFirstLetter(animationType)}`,
    {
      CapitalLetters: getIsCapitalLettersFeatureActive(),
    },
    classNameOverlay,
  );

  const modalClassName = classNames(
    'Modal',
    className,
  );

  const handleClose = useCallback(
    (e?: MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      onClose();
    },
    [onClose],
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (modalRef.current?.contains(e.target as Node)) {
        return;
      }

      onClose();
    },
    [onClose],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>): void => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(
    () => {
      if (isOpen) {
        enableScrollLock();
        closeButtonRef.current?.focus();
        setModalHtmlElement?.(modalRef.current);
        return;
      }

      disableScrollLock();
    },
    [setModalHtmlElement, isOpen],
  );

  useEffect(
    () => {
      return () => disableScrollLock();
    },
    [],
  );

  return createPortal(
    <aside
      aria-label={ariaLabel}
      aria-modal="true"
      className={overlayClassName}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      role="dialog"
      tabIndex={-1}
    >
      <div
        className={modalClassName}
        ref={modalRef}
      >
        {hasCloseButton && (
          <ButtonIcon
            aria-labelledby="close-modal"
            className="Modal-Close"
            icon={<CloseIcon />}
            onClick={handleClose}
            buttonRef={closeButtonRef}
            size={ButtonIcon.Size.SMALL}
            styleType={ButtonIcon.StyleType.GHOST}
          />
        )}

        {children(handleClose)}
      </div>
    </aside>,
    document.body,
  );
};

export {
  Modal,
};
