import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';
import classNames from 'classnames';
import Logo from 'dd-client/site/app/components/App/Header/assets/logo.svg';
import { ModalMenu } from 'dd-client/site/app/components/App/Header/ModalMenu';
import { TopHeader } from 'dd-client/site/app/components/App/Header/TopHeader';
import ArrowLeft from 'dd-client/site/common/assets/icons/arrow-left.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { Link } from 'dd-client/site/common/components/Link';
import { useCurrentTheme } from 'dd-client/site/common/hooks/useCurrentTheme';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { hasPastDeals } from 'dd-client/site/common/utils/features';
import { getConfig } from 'dd-client/site/config/utils/config';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useGoToPage } from 'dd-client/site/routes/hooks/useGoToPage';
import { useStaticPages } from 'dd-client/site/static/hooks/useStaticPages';
import BrackLogo from './assets/brack.svg';
import HamburgerIcon from './assets/hamburger.svg';
import { Component } from './types';
import './Header.scss';

const Header: Component = (): ReactElement => {
  const { i18n: { language }, t } = useTranslation();
  const currentTheme = useCurrentTheme();
  const staticPages = useStaticPages();

  const [isModalMenuVisible, setIsModalMenuVisible] = useState(false);
  const { isHomePage } = usePageMatch();
  const goToHomePage = useGoToPage();
  const { isMobile } = useDevice();
  const prevScrollY = useRef<number>(0);
  const [isStickyEnabled, setIsStickyEnabled] = useState(false);
  const refHeaderMainWrapper = useRef<HTMLDivElement | null>(null);
  const [isMainWrapperMobile, setIsMainWrapperMobile] = useState(false);

  const text = useMemo(
    () => ({
      deals: t('Deals'),
      delivery: t('Delivery'),
      login: t('Login'),
      logo: t('Logo'),
      menu: t('Menu'),
      offerFrom: t('An offer from'),
      pastDeals: t('Past deals'),
      paymentMethods: t('Payment methods'),
      register: t('Register'),
    }),
    [t],
  );

  const mainWrapperClassName = classNames(
    'Header-MainWrapper',
    {
      'Header-MainWrapper--Mobile': isMainWrapperMobile && isMobile,
    },
  );

  const linkGroup = useMemo(() => (
    [
      ...(hasPastDeals ? [
        [{
          isBlank: false,
          label: text.pastDeals,
          url: '/past-deals',
        }],
      ] : [[]]),
      [{
        isBlank: true,
        label: text.paymentMethods,
        url: language === LanguageCode.FR
          ? 'https://www.brack.ch/fr/modes-de-paiement'
          : 'https://www.brack.ch/zahlungsarten',
      },
      {
        isBlank: true,
        label: text.delivery,
        url: language === LanguageCode.FR
          ? 'https://www.brack.ch/fr/livraison-et-frais-de-port'
          : 'https://www.brack.ch/versand',
      }],
      [{
        isBlank: true,
        label: text.login,
        url: language === LanguageCode.FR
          ? 'https://www.brack.ch/fr/se-connecter'
          : 'https://www.brack.ch/anmelden',
      },
      {
        isBlank: true,
        label: text.register,
        url: language === LanguageCode.FR
          ? 'https://www.brack.ch/fr/inscription'
          : 'https://www.brack.ch/registrieren',
      }],
    ]
  ), [language, text.delivery, text.login, text.pastDeals, text.paymentMethods, text.register]);

  const headerLinks = useMemo(
    () => {
      if (staticPages.isSuccess) {
        return staticPages.data.header.map(
          staticPage => ({
            isBlank: false,
            label: staticPage.title,
            url: `/site/${staticPage.slug}`,
          }),
        );
      }

      return [];
    },
    [staticPages.data?.header, staticPages.isSuccess],
  );

  const brackLogoLink = useMemo(
    () => (
      language === LanguageCode.FR
        ? 'https://www.brack.ch/fr'
        : 'https://www.brack.ch/'
    ),
    [language],
  );

  const handleClickMenu = useCallback(
    () => {
      !isMobile && window.scrollTo(0, 0);
      setIsModalMenuVisible(!isModalMenuVisible);
    },
    [isModalMenuVisible, isMobile],
  );

  const handleScroll = useCallback(
    (): void => {
      //isModalMenuVisible is needed here for changing device orientation - when modal menu is open, sticky header shouldn't disappear
      setIsStickyEnabled(prevScrollY.current > window.scrollY || isModalMenuVisible);
      prevScrollY.current = window.scrollY;

      setIsMainWrapperMobile((refHeaderMainWrapper.current?.clientHeight || 0) < window.scrollY);
    },
    [prevScrollY, setIsStickyEnabled, isModalMenuVisible],
  );

  const handleCloseMenu = useCallback(
    () => {
      isModalMenuVisible && setIsModalMenuVisible(false);
    },
    [isModalMenuVisible],
  );

  useEffect(
    () => {
      if (isMobile) {
        window.addEventListener('scroll', handleScroll);
      }

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    },
    [handleScroll, isMobile],
  );

  return (
    <header className="Header">
      <TopHeader
        linkGroup={linkGroup}
        headerLinks={headerLinks}
      />

      <Sticky
        enableTransforms={false}
        enabled={isMobile && isStickyEnabled}
        innerClass="Header-StickyInnerWrapper"
        activeClass="Header-Sticky--Active"
      >
        <div
          className={mainWrapperClassName}
          ref={refHeaderMainWrapper}
        >
          <div className="Header-Main">
            <div className="container Header-MainContent">
              <Link
                onClick={handleCloseMenu}
                url={'/'}
              >
                {currentTheme?.logo
                  ? (
                    <img
                      className="Header-ThemeLogo"
                      src={`${getConfig('url.cdn')}/${currentTheme.logo}`}
                      alt={text.logo}
                    />
                  )
                  : <Logo className="Header-Logo"/>
                }
              </Link>

              <div className="Header-Offer">
                <span className="Header-OfferText">
                  {text.offerFrom}
                </span>

                <Link
                  url={brackLogoLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BrackLogo className="Header-BrackLogo"/>
                </Link>

                <div
                  className="Header-Hamburger"
                  onClick={handleClickMenu}
                >
                  {text.menu}
                  <HamburgerIcon />
                </div>

                <ModalMenu
                  brackLogoLink={brackLogoLink}
                  headerLinks={headerLinks}
                  isModalMenuVisible={isModalMenuVisible}
                  linkGroup={linkGroup}
                  setIsModalMenuVisible={setIsModalMenuVisible}
                />
              </div>
            </div>
          </div>

          {!isHomePage && (
            <div className="Header-BackButtonWrapper">
              <div className="container">
                <Button
                  className="Header-BackButton"
                  iconPosition={Button.IconPosition.LEFT}
                  onClick={goToHomePage}
                  size={Button.Size.SMALL}
                  styleType={Button.StyleType.GHOST}
                >
                  <ArrowLeft/>
                  {text.deals}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Sticky>
    </header>
  );
};

export {
  Header,
};
