import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Head } from 'dd-client/site/app/components/App/Head';
import { isErrorPage, userIpAddress } from 'dd-client/site/common/utils/global';
import { isServer } from 'dd-client/site/common/utils/server';
import { getConfig } from 'dd-client/site/config/utils/config';
import { imageUrl, videoUrl } from 'dd-client/site/error/components/BanPage/assets';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { Component } from './types';
import './BanPage.scss';

const domain = getConfig('url.root', isServer ? '' : window.location.hostname).replace('https://', '');

const BanPage: Component = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const de = i18n.getFixedT(LanguageCode.DE);

  const setIsErrorPage = useSetRecoilState(isErrorPage);
  const userIp = useRecoilValue(userIpAddress);

  const text = useMemo(
    () => ({
      banIpImage: t('Ban IP image'),
      blockingIP: (domain: string) => t(
        `The blocking of an IP address for comments on {domain} can have various reasons.
        In any case, however, our forum rules have been violated repeatedly or seriously.
        Possible reasons for blocking include, but are not limited to:`, {
          domain,
        },
      ),
      computerLocked: t('Computer locked for comments'),
      computerLockedDE: de('Computer locked for comments'),
      contactUs: t('Contact us, we will check the reason for the blocking.'),
      howCanI: t('How can I have the blocking lifted?'),
      ifAnIpAddress: t(
        `If an IP address is wrongly blocked, we will be happy to check the reason for the blocking and,
        if necessary, unblock the IP address. In this case, please contact us.`,
      ),
      isBlockedForComment: t('is blocked for comment abuse.'),
      listItemAdvertising: t('Advertising, including the naming of competitors.'),
      listItemDiscrimination: t('Discrimination, violations of ethics, morals and human rights;'),
      listItemExtreme: t('Extreme political and religious views;'),
      listItemGlorification: t('Glorification of violence, pornography, harmful to minors, racism;'),
      listItemObviously: t('Obviously false identities (persons or companies/institutions);'),
      listItemPersonal: t('Personal data such as addresses, telephone numbers, e-mail addresses;'),
      listItemPosting: t('Posting the same entries multiple times, even on consecutive days;'),
      listItemProfanity: t('Profanity, insults, defamation;'),
      listItemViolations: t('Violations of law and copyright;'),
      theBlockingOfIp: t('The blocking of IP addresses can have various reasons.'),
      whyIsMyIpBlocked: t('Why is my IP blocked?'),
      yourIpAddress: t('Your IP address'),
    }),
    [de, t],
  );

  useEffect(() => {
    setIsErrorPage(true);

    return () => setIsErrorPage(false);
  }, [setIsErrorPage]);

  return (
    <div className='BanPage-Container'>
      <Head
        analytics={{
          title: text.computerLockedDE,
        }}
        title={text.computerLocked}
      />

      {videoUrl && (
        <video
          autoPlay={true}
          className="BanPage-Video"
          muted={true}
          loop={true}
          poster={imageUrl}
        >
          <source src={videoUrl} type="video/webm" />
        </video>
      )}

      <div className='BanPage-Content'>
        <div className='BanPage-DescWrapper'>

          <div className='BanPage-DescMainInfoWrapper'>
            <h1 className='BanPage-DescMainInfoHeader'>
              {text.computerLocked}
            </h1>
            <p className='BanPage-DescMainInfoParagraph'>
              {text.yourIpAddress}
              {userIp && (
                <span className='BanPage-DescMainInfoIpAddress'>
                    &nbsp;{userIp}
                  </span>
              )}
              &nbsp;{text.isBlockedForComment}
            </p>
          </div>

          <h3 className='BanPage-DescHeader'>
            {text.whyIsMyIpBlocked}
          </h3>
          <p className='BanPage-DescParagraph'>
            {text.theBlockingOfIp}
          </p>
          <br />
          <p className='BanPage-DescParagraph'>
            {text.blockingIP(domain)}
          </p>
          <br />
          <ul className='BanPage-List'>
            <li>{text.listItemPosting}</li>
            <li>{text.listItemPersonal}</li>
            <li>{text.listItemObviously}</li>
            <li>{text.listItemProfanity}</li>
            <li>{text.listItemViolations}</li>
            <li>{text.listItemDiscrimination}</li>
            <li>{text.listItemGlorification}</li>
            <li>{text.listItemExtreme}</li>
            <li>{text.listItemAdvertising}</li>
          </ul>
          <h3 className='BanPage-DescHeader'>
            {text.howCanI}
          </h3>
          <p className='BanPage-DescParagraph'>
            {text.contactUs}
          </p>
          <br />
          <p className='BanPage-DescParagraph'>
            {text.ifAnIpAddress}
          </p>
        </div>
      </div>
    </div>
  );
};

export {
  BanPage,
};
