import React, { FocusEvent, useCallback, useMemo, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import localforage from 'localforage';
import { useAsyncEffect } from 'use-async-effect';
import PaperPlaneIcon from 'dd-client/site/common/assets/icons/paper-plane.svg';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { TextArea } from 'dd-client/site/common/components/Form/TextArea';
import { TextInput } from 'dd-client/site/common/components/Form/TextInput';
import { Loader } from 'dd-client/site/common/components/Loader';
import { usePrevious } from 'dd-client/site/common/hooks/usePrevious';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { EMAIL_PATTERN, emailRule, maxLengthRule } from 'dd-client/site/common/utils/validations/validationRules';
import { getConfig } from 'dd-client/site/config/utils/config';
import { DEFAULT_COMMENT_DATA, StorageKey } from 'dd-client/site/deal/components/DealPage/Comments/utils/comments';
import { clearSubmittedDataLocalForage } from 'dd-client/site/deal/components/DealPage/Comments/utils/comments';
import { Component, Props, WriteCommentFormData } from './types';
import './WriteCommentForm.scss';

enum FormField {
  TITLE = 'title',
  MESSAGE = 'message',
  MESSAGE_REPLY = 'messageReply',
  NAME = 'name',
  EMAIL = 'email',
}

const MAX_LENGTH_RULES = {
  [FormField.TITLE]: 80,
  [FormField.MESSAGE]: 2000,
  [FormField.MESSAGE_REPLY]: 2000,
  [FormField.NAME]: 40,
};

const WriteCommentForm: Component = ({
  className,
  isError,
  isPending,
  isReply = false,
  isSuccess,
  onCancel,
  onSubmit,
  replyMessageTextAreaRef,
  replyTitle,
  titleInputRef,
  writeCommentRef,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const prevIsSuccess = usePrevious(isSuccess);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const text = useMemo(
    () => ({
      and: t('and'),
      appearAfterVerification: t('Your comment has been submitted. It will appear on the page after verification.'),
      apply: t('apply'),
      cancel: t('Cancel'),
      commentTitle: t('Comment Title'),
      email: t('Email'),
      message: t('Message'),
      name: t('Name'),
      privacyPolicy: t('Privacy Policy'),
      respondTo: t('Respond to'),
      send: t('Send'),
      termsOfService: t('Terms of Service'),
      thisSiteIsProtected: t('This site is protected by reCAPTCHA and the Google'),
      tryAgain: t('We couldn’t submit your comment. Please try again later.'),
      writeComment: t('Write a comment'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'WriteCommentForm',
    className,
  );

  const handleOnCancel = useCallback(
    async () => {
      await clearSubmittedDataLocalForage(isReply);
      onCancel();
    },
    [onCancel, isReply],
  );

  const onCommentSubmit: SubmitHandler<any> = useCallback(
    async (data: WriteCommentFormData)  => {
      reCaptchaRef.current?.reset();
      const token = await reCaptchaRef.current?.executeAsync() as string | null;

      onSubmit(data, isReply, token);
    },
    [isReply, onSubmit],
  );

  const handleBlur = useCallback(
    async (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      try {
        const localForageSubmittedData: WriteCommentFormData | null = await localforage.getItem(StorageKey.SUBMITTED_DATA);
        const newLocalForageSubmittedData: WriteCommentFormData = localForageSubmittedData || DEFAULT_COMMENT_DATA;
        newLocalForageSubmittedData[e.target.name as keyof WriteCommentFormData] = e.target.value;

        await localforage.setItem(StorageKey.SUBMITTED_DATA, newLocalForageSubmittedData);
      } catch (error) {
        logger.error(error);
      }
    },
    [],
  );

  const fillFormDataFromLocalStorage = useCallback(
    async () => {
      try {
        const localForageSubmittedData: WriteCommentFormData | null = await localforage.getItem(StorageKey.SUBMITTED_DATA);
        reset(localForageSubmittedData || DEFAULT_COMMENT_DATA);
      } catch (error) {
        logger.error(error);
      }
    },
    [reset],
  );

  useAsyncEffect(
    async () => {
      await fillFormDataFromLocalStorage();
    },
    [fillFormDataFromLocalStorage],
  );

  useAsyncEffect(
    async () => {
      if (prevIsSuccess && !isSuccess) {
        await fillFormDataFromLocalStorage();
      }
    },
    [isSuccess, prevIsSuccess, fillFormDataFromLocalStorage],
  );

  if (isSuccess) {
    return (
      <Alert
        isVisible
        className="WriteCommentForm-Alert"
        styleType={Alert.StyleType.SUCCESS}
      >
        {text.appearAfterVerification}
      </Alert>
    );
  }

  return (
    <div
      className={rootClassName}
      ref={writeCommentRef}
    >
      <div className="WriteCommentForm-Title">
        {isReply
          ? `${text.respondTo} "${replyTitle || ''}"`
          : text.writeComment
        }
      </div>

      <form onSubmit={handleSubmit(onCommentSubmit)}>
        {!isReply && (
          <TextInput
            control={control}
            error={maxLengthRule(MAX_LENGTH_RULES[FormField.TITLE], errors[FormField.TITLE])}
            inputRef={titleInputRef}
            isRequired={true}
            label={text.commentTitle}
            maxLength={MAX_LENGTH_RULES[FormField.TITLE]}
            name={FormField.TITLE}
            onBlur={handleBlur}
          />
        )}

        <TextArea
          control={control}
          error={
            isReply
              ? maxLengthRule(MAX_LENGTH_RULES[FormField.MESSAGE_REPLY], errors[FormField.MESSAGE_REPLY])
              : maxLengthRule(MAX_LENGTH_RULES[FormField.MESSAGE], errors[FormField.MESSAGE])
          }
          isRequired={true}
          label={text.message}
          maxLength={isReply ? MAX_LENGTH_RULES[FormField.MESSAGE_REPLY] : MAX_LENGTH_RULES[FormField.MESSAGE]}
          name={isReply ? FormField.MESSAGE_REPLY : FormField.MESSAGE}
          onBlur={handleBlur}
          textAreaRef={replyMessageTextAreaRef}
        />

        <div className="WriteCommentForm-NameEmailWrapper">
          <TextInput
            control={control}
            error={maxLengthRule(MAX_LENGTH_RULES[FormField.NAME], errors[FormField.NAME])}
            isRequired={true}
            label={text.name}
            maxLength={MAX_LENGTH_RULES[FormField.NAME]}
            name={FormField.NAME}
            onBlur={handleBlur}
          />

          <TextInput
            control={control}
            error={emailRule(errors[FormField.EMAIL])}
            isRequired={true}
            label={text.email}
            name={FormField.EMAIL}
            onBlur={handleBlur}
            validationPattern={EMAIL_PATTERN}
          />
        </div>

        <div className="WriteCommentForm-ReCaptcha">
          <ReCAPTCHA
            badge="inline"
            ref={reCaptchaRef}
            sitekey={getConfig('reCaptcha.siteKey')}
            size="invisible"
          />

          <p className="WriteCommentForm-ReCaptchaInfo">
            {text.thisSiteIsProtected}{' '}
            <a href="https://policies.google.com/privacy">{text.privacyPolicy}</a> {text.and}{' '}
            <a href="https://policies.google.com/terms">{text.termsOfService}</a> {text.apply}.
          </p>
        </div>

        {isError && (
          <Alert
            isVisible
            className="WriteCommentForm-Alert"
            styleType={Alert.StyleType.WARNING}
          >
            {text.tryAgain}
          </Alert>
        )}

        <div className="WriteCommentForm-ButtonsWrapper">
          <Button
            iconPosition={Button.IconPosition.RIGHT}
            size={Button.Size.SMALL}
            styleType={Button.StyleType.PRIMARY}
            isDisabled={isPending}
          >
            {text.send}
            {isPending
              ? <Loader
                  className="WriteCommentForm-Loader"
                  styleType={Loader.StyleType.SMALL}
                />
              : <PaperPlaneIcon />
            }
          </Button>

          <Button
            isFormSubmitPrevented={true}
            size={Button.Size.SMALL}
            styleType={Button.StyleType.LIGHT}
            onClick={handleOnCancel}
          >
            {text.cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};

export {
  FormField,
  WriteCommentForm,
};
