import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { subscribe } from './connector';
import { SubmitNewsletterData } from './types';

const useSubmitNewsletter = (): UseMutationResult<any, AxiosError, SubmitNewsletterData> => (
  useMutation({ mutationFn: subscribe })
);

export {
  useSubmitNewsletter,
};

