import { isAfter, isBefore } from 'date-fns';
import { getConfig } from 'dd-client/site/config/utils/config';

const hasCapitalLetters = getConfig('features.capitalLetters.enabled');
const capitalLettersStartTime = getConfig('features.capitalLetters.startTime');
const capitalLettersEndTime = getConfig('features.capitalLetters.endTime');

const hasDealPageFullAnimated = getConfig('features.dealPageFullAnimated.enabled');
const dealPageFullAnimatedStartTime = getConfig('features.dealPageFullAnimated.startTime');
const dealPageFullAnimatedEndTime = getConfig('features.dealPageFullAnimated.endTime');

const hasNews = getConfig('features.news');
const hasNewsletter = getConfig('features.newsletter');
const hasPastDeals = getConfig('features.pastDeals');
const hasPromotions = getConfig('features.promotions');
const hasStaticPage = getConfig('features.staticPage');

const isInTimeFrame = (startTime: string, endTime: string): boolean => {
  const currentDateTime = new Date();

  return isAfter(currentDateTime, startTime) && isBefore(currentDateTime, endTime);
};

const getIsCapitalLettersFeatureActive = (): boolean => (
  hasCapitalLetters && isInTimeFrame(capitalLettersStartTime, capitalLettersEndTime)
);

const getIsFullAnimationFeatureActive = (): boolean => (
  hasDealPageFullAnimated && isInTimeFrame(dealPageFullAnimatedStartTime, dealPageFullAnimatedEndTime)
);

export {
  getIsCapitalLettersFeatureActive,
  getIsFullAnimationFeatureActive,
  hasNews,
  hasNewsletter,
  hasPastDeals,
  hasPromotions,
  hasStaticPage,
};
