import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isFuture, toDate } from 'date-fns';
import HourglassIcon from './assets/hourglass.svg';
import TimerIcon from './assets/timer.svg';
import { Component, Props } from './types';
import './Countdown.scss';

const Countdown: Component = ({
  className,
  endTime,
  isHighlightedLessThanHour,
  startTime,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const isStartCountdown = startTime && isFuture(toDate(startTime));
  const finalTime = isStartCountdown ? startTime : endTime;

  const [countdown, setCountdown] = useState(finalTime - Date.now());
  const hours = countdown / 1000 / 60 / 60;
  const days = Math.floor(hours / 24);
  const time = new Date(countdown);

  const rootClassName = classNames(
    'Countdown',
    {
      'Countdown--Highlighted': isHighlightedLessThanHour && hours < 1,
    },
    className,
  );

  useEffect(
    () => {
      const intervalID = setInterval(
        () => {
          const countdownValue = finalTime - Date.now();
          setCountdown(countdownValue >= 0 ? countdownValue : 0);
        },
        250,
      );
      return () => clearInterval(intervalID);
    },
    [finalTime],
  );

  return (
    <div className={rootClassName}>
      {isStartCountdown ? <HourglassIcon /> : <TimerIcon />}
      {hours >= 24 && (
        t('days', { count: days, defaultValue: '{count, plural, one {1 day} other {{count} days}}' })
      )}
      {hours < 24 && (
        <span className="Countdown-Timer" suppressHydrationWarning>
          {`0${time.getUTCHours()}`.slice(-2)}:
          {`0${time.getMinutes()}`.slice(-2)}:
          {`0${time.getSeconds()}`.slice(-2)}
        </span>
      )}
    </div>
  );
};

export {
  Countdown,
};
