import qs from 'qs';

export const stringify = (object: any): string => qs.stringify(
  object,
  {
    encodeValuesOnly: true,
    skipNulls: true,
  },
);

export const parse = (string: string = window.location.search): any => qs.parse(
  string,
  { ignoreQueryPrefix: true },
);
