import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { SubscriptionSettings } from './types';


const useSubscriptionSettings = (id: string, uid: string): UseQueryResult<SubscriptionSettings, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    queryFn: QUERY.subscriptionSettings.queryFunction,
    queryKey: [QUERY.subscriptionSettings.queryName, language, id, uid],
  });
};


export {
  useSubscriptionSettings,
};

