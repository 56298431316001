import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { submitCompetition } from './connector';
import { SubmitCompetitionData } from './types';

const useSubmitCompetition = (): UseMutationResult<any, AxiosError, SubmitCompetitionData> => (
  useMutation({ mutationFn: submitCompetition })
);

export {
  useSubmitCompetition,
};

