import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { Theme } from './types';

const useThemes = (): UseQueryResult<Array<Theme>, ApiError> => (
  useQuery({
    queryFn: QUERY.themes.queryFunction,
    queryKey: [QUERY.themes.queryName],
  })
);

export {
  useThemes,
};

