import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { useThemes } from 'dd-client/site/common/hooks/useThemes';
import { parse } from 'dd-client/site/common/utils/searchParams';
import { getTheme, QUERY_STRING_PREVIEW_TOKEN } from 'dd-client/site/common/utils/theme/theme';
import { useActiveDeals } from 'dd-client/site/deal/hooks/api/useActiveDeals';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';

const useCurrentTheme = (): Theme | undefined => {
  const activeDeals = useActiveDeals();
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;
  const themes = useThemes();
  const { search } = useLocation();
  const { dealParam, categoryParam, isHomePage } = usePageMatch();

  const previewToken = parse(search)[QUERY_STRING_PREVIEW_TOKEN];
  const [currentTheme, setCurrentTheme] = useState<Theme | undefined>(
    getTheme(themes.data, activeDeals.data, isHomePage, dealParam, categoryParam, previewToken, currentDealData?.themeId),
  );

  useEffect(
    () => {
      if (activeDeals.data?.length && themes.data?.length) {
        setCurrentTheme(getTheme(themes.data, activeDeals.data, isHomePage, dealParam, categoryParam, previewToken, currentDealData?.themeId));
      }
    },
    [activeDeals.data, themes.data, dealParam, previewToken, categoryParam, isHomePage, currentDealData?.themeId],
  );

  return currentTheme;
};

export {
  useCurrentTheme,
};
