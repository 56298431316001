import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { Checkbox } from 'dd-client/site/common/components/Form/Checkbox';
import { Loader } from 'dd-client/site/common/components/Loader';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { getChangedCheckboxes, getFormFieldCheckboxes, getSelectedCategories } from 'dd-client/site/common/utils/newsletter/newsletter';
import { NewsletterCheckboxesValue } from 'dd-client/site/common/utils/newsletter/types';
import { parse } from 'dd-client/site/common/utils/searchParams';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useSubscriptionSettings } from 'dd-client/site/newsletter/hooks/useSubscriptionSettings';
import { useUpdateSubscription } from 'dd-client/site/newsletter/hooks/useUpdateSubscription';
import { useActiveCategories } from 'dd-client/site/pastDeals/hooks/useActiveCategories';
import { SavedPreferencesInfo } from './SavedPreferencesInfo';
import { Component } from './types';
import { mergeCheckboxesData } from './utils/mergeCheckboxesData';
import './NewsletterUnsubscriptionPage.scss';

enum QueryString {
  ID = 'id',
  UID = 'uid',
}

const NewsletterUnsubscriptionPage: Component = (): ReactElement => {
  const { t, i18n: { language } } = useTranslation();
  const {
    handleSubmit,
    control,
  } = useForm();

  const { search } = useLocation();
  const id = parse(search)[QueryString.ID];
  const uid = parse(search)[QueryString.UID];

  const subscriptionSettings = useSubscriptionSettings(id, uid);
  const activeCategories = useActiveCategories();
  const updateSubscription = useUpdateSubscription();
  const [checkboxes, setCheckboxes] = useState(mergeCheckboxesData(activeCategories.data, subscriptionSettings.data));
  const [isUnsubscribedFromAll, setIsUnsubscribedFromAll] = useState(false);

  const text = useMemo(
    () => ({
      savePreferences: t('Save preferences'),
      somethingWentWrong: t('Something went wrong, please try again'),
      unsubscribeFromAll: t('Unsubscribe from all'),
      updateEmail: (email: string) => t('Update email preferences for {email}', {
        email,
      }),
      youCanUnsubscribe: t('You can unsubscribe from all newsletters or select a newsletter that you would like to continue receiving.'),
    }),
    [t],
  );

  const onSubmit = useCallback(
    (isUnsubscribeFromAll: boolean): SubmitHandler<any> => async (data: { newsletterCheckboxes: NewsletterCheckboxesValue}) => {
      try {
        await updateSubscription.mutateAsync({
          id,
          locale: language as LanguageCode,
          uid,
          ...(
            isUnsubscribeFromAll
              ? {
                categoryIds: [],
                specialCategories: {
                  ballon: 0,
                  news: 0,
                },
              }
              : getSelectedCategories(data.newsletterCheckboxes)
          ),
        });

        setIsUnsubscribedFromAll(isUnsubscribeFromAll);
        await subscriptionSettings.refetch();
      } catch (e) {
        logger.error(e);
      }
    },
    [id, language, subscriptionSettings, uid, updateSubscription],
  );

  const handleChange = useCallback(
    (index: number): void  => {
      setCheckboxes(getChangedCheckboxes(index, checkboxes));
    },
    [checkboxes],
  );

  const renderAlert = useMemo(
    () => (
      <Alert
        isVisible={true}
        styleType={Alert.StyleType.WARNING}
      >
        {text.somethingWentWrong}
      </Alert>
    ),
    [text.somethingWentWrong],
  );

  useEffect(
    () => {
      if (activeCategories.data && subscriptionSettings.data) {
        setCheckboxes(
          mergeCheckboxesData(activeCategories.data, subscriptionSettings.data),
        );
      }
    },
    [activeCategories.data, subscriptionSettings.data],
  );

  if (updateSubscription.isSuccess) {
    return <SavedPreferencesInfo isUnsubscribedFromAll={isUnsubscribedFromAll} />;
  }

  return (
    <div className="container NewsletterUnsubscriptionPage">
      <div className="NewsletterUnsubscriptionPage-Content">
        {(subscriptionSettings.isPending || activeCategories.isPending) && (
          <Loader className="NewsletterUnsubscriptionPage-Loader"/>
        )}

        {subscriptionSettings.isSuccess && activeCategories.isSuccess && (
          <>
            <h1 className="NewsletterUnsubscriptionPage-Title">
              {text.updateEmail(subscriptionSettings.data.email)}
            </h1>

            <p className="NewsletterUnsubscriptionPage-Text">
              {text.youCanUnsubscribe}
            </p>

            <form
              className="NewsletterUnsubscriptionPage-Form"
              onSubmit={handleSubmit(onSubmit(false))}
            >
              {
                checkboxes.map(
                  (checkboxItem, index) => (
                    <Checkbox
                      control={control}
                      isChecked={checkboxItem.isChecked}
                      key={checkboxItem.name}
                      label={checkboxItem.name}
                      name={`${getFormFieldCheckboxes(t).newsletterCheckboxes}.${checkboxItem.id}`}
                      onChange={() => handleChange(index)}
                    />
                  ),
                )
              }

              <div className="NewsletterUnsubscriptionPage-ButtonsWrapper">
                <Button isDisabled={updateSubscription.isPending}>
                  {text.savePreferences}
                </Button>

                <Button
                  isDisabled={updateSubscription.isPending}
                  styleType={Button.StyleType.LIGHT}
                  isFormSubmitPrevented={true}
                  onClick={onSubmit(true)}
                >
                  {text.unsubscribeFromAll}
                </Button>
              </div>
            </form>

            {updateSubscription.isError && renderAlert}
          </>
        )}

        {(subscriptionSettings.isError || activeCategories.isError) && renderAlert}
      </div>
    </div>
  );
};

export {
  NewsletterUnsubscriptionPage,
};
