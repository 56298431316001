import React, { Component } from 'react';
import { AppCrash } from 'dd-client/site/app/components/ErrorBoundary/AppCrash';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import type { Props, State } from './';

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
  };

  componentDidCatch(
    error: Error,
    errorInfo: Record<string, any> = {},
  ): void {
    logger.error(
      error.message,
      {
        error,
        ...errorInfo,
        source: 'ErrorBoundary',
      },
    );

    this.setState({
      error,
    });
  }

  render(): any {
    if (this.state.error) {
      const lang = document.documentElement.lang as LanguageCode;

      return (
        <AppCrash lang={lang}/>
      );
    }

    return this.props.children;
  }
}

export {
  ErrorBoundary,
};
