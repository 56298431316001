import { useEffect, useState } from 'react';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { getAvailabilityProps } from 'dd-client/site/common/utils/availability/availability';
import { isPastDeal } from 'dd-client/site/common/utils/pastDeal/pastDeal';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';

const useIsStickyBar = (): boolean => {
  const [isStickyBar, setIsStickyBar] = useState<boolean>(false);

  const { isHomePage } = usePageMatch();
  const { isMobile } = useDevice();

  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;

  const dealAvailability = currentDealData?.availability;

  const isSoldOut = dealAvailability ? getAvailabilityProps(dealAvailability).isSoldOut : false;

  useEffect(
    () => {
      if (
        !isSoldOut
        && !isPastDeal(currentDealData?.endDate)
        && (!isMobile || !isHomePage)
      ) {
        setIsStickyBar(true);
      } else {
        setIsStickyBar(false);
      }
    },
    [currentDealData, isHomePage, isMobile, isSoldOut],
  );

  return isStickyBar;
};

export {
  useIsStickyBar,
};
