import React, { ReactElement, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'dd-client/site/common/components/ToggleSwitch';
import { isServer } from 'dd-client/site/common/utils/server';
import CookieIcon from './assets/cookie.svg';
import { Component } from './types';
import './CookieOptOutPage.scss';

const NO_TRACKING_COOKIE_NAME = 'no-tracking';
const NO_TRACKING_COOKIE_MAX_AGE = 60 * 60 * 24 * 365;
enum NO_TRACKING_COOKIE_VALUE {
  ENABLED = '1',
  DISABLED = '0'
}

const CookieOptOutPage: Component = (): ReactElement => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([NO_TRACKING_COOKIE_NAME]);
  const noTrackingCookie = cookies[NO_TRACKING_COOKIE_NAME];
  const isTrackingActive = !noTrackingCookie || noTrackingCookie === NO_TRACKING_COOKIE_VALUE.DISABLED;

  const handleTrackingChange = useCallback(
    (isChecked: boolean) => {
      setCookie(
        NO_TRACKING_COOKIE_NAME,
        isChecked ? NO_TRACKING_COOKIE_VALUE.DISABLED : NO_TRACKING_COOKIE_VALUE.ENABLED,
        {
          maxAge: NO_TRACKING_COOKIE_MAX_AGE,
          path: '/',
        },
      );
      window.location.reload();
    },
    [setCookie],
  );

  const text = useMemo(
    () => ({
      active: t('Active'),
      cookieOptOut: t('Cookie Opt-Out'),
      inactive: t('Inactive'),
      theseTechnologies: t('These technologies help us measure results or better target our website content.'),
      weUseTechnologies: t('We use technologies such as cookies or targeting and process personal data such as IP address or browser information to personalize the ads we display.'),
      websiteTracking: t('Website Tracking is'),
      youCanChange: t('You can change/revoke this consent at any time later by changing the setting below.'),
    }),
    [t],
  );

  return (
    <div className="container CookieOptOutPage">
      <CookieIcon className="CookieOptOutPage-Icon"/>

      <h1 className="CookieOptOutPage-Title">
        {text.cookieOptOut}
      </h1>

      <div className="CookieOptOutPage-Content">
        <div className="CookieOptOutPage-Text">
          {text.weUseTechnologies}
          &nbsp;
          {text.theseTechnologies}
          <br />
          {text.youCanChange}
        </div>

        <div
          className="CookieOptOutPage-Panel"
          suppressHydrationWarning={true}
        >
          {text.websiteTracking}:
          {isServer ? null : (
            <ToggleSwitch
              isChecked={isTrackingActive}
              label={isTrackingActive ? text.active : text.inactive}
              onChange={handleTrackingChange} />
          )}
        </div>
      </div>
    </div>
  );
};

export {
  CookieOptOutPage,
  NO_TRACKING_COOKIE_NAME,
  NO_TRACKING_COOKIE_VALUE,
};
