import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'dd-client/site/common/components/Link';
import { hasNews } from 'dd-client/site/common/utils/features';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useStaticPages } from 'dd-client/site/static/hooks/useStaticPages';
import BallonIconDE from './assets/badge-ballon-DE.svg';
import BallonIconFR from './assets/badge-ballon-FR.svg';
import ProClimaIcon from './assets/pro-clima.svg';
import SwissOnlineGarantie from './assets/swiss-online-garantie.png';
import SwissOnlineGarantie2x from './assets/swiss-online-garantie@2x.png';
import { Component } from './types';
import './FooterTopLinks.scss';

const FooterTopLinks: Component = (): ReactElement => {
  const { i18n: { language }, t } = useTranslation();
  const staticPages = useStaticPages();

  const text = useMemo(
    () => ({
      delivery: t('Delivery options'),
      news: t('News'),
      payments: t('Payment methods'),
      services: t('Services'),
      swissOnlineGuarantee: t('Swiss online guarantee'),
    }),
    [t],
  );

  const footerLinks = useMemo(
    () => {
      if (staticPages.isSuccess) {
        return staticPages.data.footer.map(
          staticPage => (
            <li key={staticPage.slug}>
              <Link url={`/site/${staticPage.slug}`}>
                {staticPage.title}
              </Link>
            </li>
          ),
        );
      }

      return [];
    },
    [staticPages.data?.footer, staticPages.isSuccess],
  );

  return (
    <div className="Footer-TopWrapper">
      <ul className="Footer-TopLinkList">
        <li>
          <Link
            url={
              language === LanguageCode.FR
                ? 'https://www.brack.ch/fr/livraison-et-frais-de-port'
                : 'https://www.brack.ch/versand'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.delivery}
          </Link>
        </li>
        <li>
          <Link
            url={
              language === LanguageCode.FR
                ? 'https://www.brack.ch/fr/modes-de-paiement'
                : 'https://www.brack.ch/zahlungsarten'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.payments}
          </Link>
        </li>
        <li>
          <Link
            url={
              language === LanguageCode.FR
                ? 'https://www.brack.ch/fr/prestations-de-service'
                : 'https://www.brack.ch/dienstleistungen'
            }
            target="_blank"
            rel="noreferrer"
          >
            {text.services}
          </Link>
        </li>

        {footerLinks}

        {hasNews && (
          <li>
            <Link url="/news">
              {text.news}
            </Link>
          </li>
        )}
      </ul>

      <ul className="Footer-TopIconList">
        <li>
          <a
            href={
              language === LanguageCode.FR
                ? 'https://www.post.ch/fr/notre-profil/responsabilite/corporate-responsibility/envoi-pro-clima?shortcut=fr-entreprises-indexthematique-prestationscomplementaires-prestationcomplementaireenvoisansimpactpou'
                : 'https://www.post.ch/de/ueber-uns/verantwortung/corporate-responsibility/pro-clima-versand?shortcut=opp-de-geschaeftlich-themenaz-zusatzleistungen-zusatzleistungklimaneutralerversand-klimaneutralerver'
            }
            target="_blank"
            rel="noreferrer"
          >
            <ProClimaIcon />
          </a>
        </li>
        <li>
          <a
            href={
              language === LanguageCode.FR
                ? 'https://www.swiss-online-garantie.ch/?lang=fr'
                : 'https://www.swiss-online-garantie.ch/'
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt={text.swissOnlineGuarantee}
              src={SwissOnlineGarantie}
              srcSet={`${SwissOnlineGarantie2x} 2x`}
            />
          </a>
        </li>
        <li>
          <a
            href="https://ballon.daydeal.ch/"
            target="_blank"
            rel="noreferrer"
          >
            {
              language === LanguageCode.FR
                ? <BallonIconFR />
                : <BallonIconDE />
            }
          </a>
        </li>
      </ul>
    </div>
  );
};

export {
  FooterTopLinks,
};
