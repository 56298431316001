import React, { FocusEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Error } from 'dd-client/site/common/components/Form/common/Error';
import { Info } from 'dd-client/site/common/components/Form/common/Info';
import { Label } from 'dd-client/site/common/components/Form/common/Label';
import { useFormField } from 'dd-client/site/common/hooks/useFormField';
import { Component, Props } from './types';
import './TextInput.scss';

enum Type {
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXT = 'text',
}

const TextInput: Component = ({
  className,
  control,
  defaultValue,
  error = null,
  info = null,
  inputRef,
  isDisabled = false,
  isRequired = false,
  label,
  maxLength,
  name,
  onBlur,
  placeholder,
  type,
  validationPattern,
}: Props) => {
  const rootClassName = classNames(
    'TextInput',
    {
      'TextInput--Disabled': isDisabled,
      'TextInput--Error': error,
    },
    className,
  );

  const { field } = useFormField({ control, defaultValue, isRequired, maxLength, name, validationPattern });
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = useCallback(
    (): void => {
      setHasFocus(true);
    },
    [],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      setHasFocus(false);
      onBlur?.(e);
      field.onBlur();
    },
    [field, onBlur],
  );

  return (
    <>
      <div className={rootClassName}>
        {label && (
          <Label
            name={name}
            isRequired={isRequired}
            isAtFieldTop={hasFocus || !!field.value}
          >
            {label}
          </Label>
        )}

        <input
          {...field}
          ref={inputRef}
          className="TextInput-Input"
          disabled={isDisabled}
          id={label ? name : undefined}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          type={type}
        />

        {info && (
          <Info>
            {info}
          </Info>
        )}

        {error && (
          <Error>
            {error}
          </Error>
        )}
      </div>
    </>
  );
};

TextInput.Type = Type;

export {
  TextInput,
  Type,
};
