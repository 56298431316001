import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { hasStaticPage } from 'dd-client/site/common/utils/features';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { StaticPages } from './types';

const useStaticPages = (): UseQueryResult<StaticPages, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    enabled: hasStaticPage,
    queryFn: QUERY.staticPages.queryFunction,
    queryKey: [QUERY.staticPages.queryName, language],
  });
};


export {
  useStaticPages,
};

