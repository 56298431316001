import { MetricFunction, MetricsModuleInstance, MetricsModuleReturn } from './types';

function MetricsModule(): MetricsModuleReturn {
  let instance: MetricsModuleInstance | undefined;

  async function getModuleInstance(): Promise<MetricsModuleInstance> {
    if (!instance) {
      instance = await import('dd-server/site/metrics');
    }

    return instance;
  }

  async function run(metricFunction: MetricFunction) {
    const loadedInstance = await getModuleInstance();
    metricFunction(loadedInstance);
  }

  return {
    run,
  };
}

const metricsModule = MetricsModule();

export {
  metricsModule,
};
