import { AnalyzeAddToCartPayload } from 'dd-client/site/analytics/types';
import {
  ANALYTICS_BOOLEAN,
  ANALYTICS_CATEGORIES,
  ANALYTICS_COMPONENT_CATEGORY,
  ANALYTICS_COMPONENT_SUBCATEGORY,
  ANALYTICS_EVENT_ID,
  ANALYTICS_SERVICE,
  analyticsComponentLocation,
} from 'dd-client/site/analytics/utils/analytics';
import { logger } from 'dd-client/site/common/utils/logger/logger';

const analyzeAddToCart = (sku: number, price: string): void => {
  const payload: AnalyzeAddToCartPayload = {
    component_category: ANALYTICS_COMPONENT_CATEGORY,
    component_location: analyticsComponentLocation,
    component_subcategory: ANALYTICS_COMPONENT_SUBCATEGORY,
    prod: [{
      action: ANALYTICS_EVENT_ID.addToCart,
      categories: ANALYTICS_CATEGORIES,
      id: `${sku}`,
      price,
      purchasable: ANALYTICS_BOOLEAN.true,
      quan: '1',
      service: ANALYTICS_SERVICE,
      stock: '1',
    }],
  };

  try {
    window?.utagQ?.link(payload);
  } catch (e) {
    logger.error(e);
  }
};

export {
  analyzeAddToCart,
};
