import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LabelType } from 'dd-client/site/deal/hooks/api/types';
import { Component, Props } from './types';
import './ProductLabels.scss';

const ProductLabels: Component = ({
  className,
  labels,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      cashback: t('Cashback'),
      intropromotion: t('Intropromotion'),
      new: t('Novelty'),
      offer: t('Offer'),
      sale: t('Sale'),
      trade: t('Trade'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'ProductLabels',
    className,
  );

  return (
    <div className={rootClassName}>
      {labels.map(
        label => {
          const labelClassName = classNames(
            'ProductLabels-Label',
            {
              'ProductLabels-Label--New': label === LabelType.NEW,
            },
          );

          return (
            <span
              className={labelClassName}
              key={label}
            >
              {text[label]}
            </span>
          );
        },
      )}
    </div>
  );
};

export {
  ProductLabels,
};
