import React, { MouseEvent, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format, toDate } from 'date-fns';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import MessageIcon from 'dd-client/site/common/assets/icons/message.svg';
import { Button } from 'dd-client/site/common/components/Button';
import { getConfig } from 'dd-client/site/config/utils/config';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import LogoBrack from '../../assets/logo-brack.svg';
import { Component, Props } from './types';
import './PastDealTile.scss';

const LOCALE_DATE = {
  [LanguageCode.DE]: de,
  [LanguageCode.FR]: fr,
};

const PAST_DEAL_IMAGE_SIZE = {
  height: 0,
  width: 687,
};

const PastDealTile: Component = ({
  categoryName,
  commentsCount,
  date,
  mainImage,
  pdpUrl,
  slug,
  subtitle,
  title,
}: Props): ReactElement => {
  const { t, i18n: { language } } = useTranslation();
  const history = useHistory();

  const text = useMemo(
    () => ({
      at: t('At'),
      watch: t('Watch'),
    }),
    [t],
  );
  const urlCdn = getConfig('url.cdn');

  const handlePastDealClick = useCallback(
    (pastDealLink: string) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      history.push(pastDealLink);
    },
    [history],
  );

  const handleBrackButtonClick = useCallback(
    (pdpUrl: string) => () => {
      window.open(pdpUrl, '_blank');
    },
    [],
  );

  const pastDealLink = useMemo(
    () => `/${language}/deal/${slug}`,
    [language, slug],
  );

  return (
    <li className="PastDealTile">
      <div className="PastDealTile-Header">
        <span className="PastDealTile-Category">
          {categoryName}
        </span>
        <span>
          {format(
            toDate(Date.parse(date)),
            'd MMMM yyyy',
            // @ts-ignore
            { locale: LOCALE_DATE[language]  },
          )}
        </span>
      </div>

      <a
        className="PastDealTile-ImageLink"
        href={pastDealLink}
        onClick={handlePastDealClick(pastDealLink)}
      >
        <img
          alt={title}
          className="PastDealTile-Image"
          src={`${urlCdn}/${PAST_DEAL_IMAGE_SIZE.width}x${PAST_DEAL_IMAGE_SIZE.height}/${mainImage}`}
          //deliberately not added hardcoded width and height for retina, to not create false images (unnecessarily enlarged)
          srcSet={`${urlCdn}/${mainImage} 2x`}
        />
      </a>

      <a
        className="PastDealTile-Title"
        href={pastDealLink}
        onClick={handlePastDealClick(pastDealLink)}
      >
        {title}
      </a>

      <div className="PastDealTile-Subtitle">
        {subtitle}
      </div>

      <div className="PastDealTile-BrackButtonComments">
        {pdpUrl && (
          <Button
            className="PastDealTile-BrackButton"
            onClick={handleBrackButtonClick(pdpUrl)}
            size={Button.Size.SMALL}
          >
            {text.at}
            <LogoBrack />
            {text.watch}
          </Button>
        )}

        <a
          className="PastDealTile-Comments"
          href={pastDealLink}
          onClick={handlePastDealClick(pastDealLink)}
        >
          <MessageIcon className="PastDealTile-CommentsIcon" />
          {commentsCount}
        </a>
      </div>
    </li>
  );
};

export {
  PastDealTile,
};
