import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'dd-client/site/common/types';
import { QUERY } from 'dd-client/site/common/utils/query/query';
import { Deal } from '../types';

const useActiveDeals = (): UseQueryResult<Array<Deal>, ApiError> => {
  const { i18n: { language } } = useTranslation();

  return useQuery({
    queryFn: QUERY.activeDeals.queryFunction,
    queryKey: [QUERY.activeDeals.queryName, language],
  });
};

export {
  useActiveDeals,
};

