import React from 'react';
import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { Alert } from 'dd-client/site/common/components/Alert';
import { CompetitionForm, CompetitionFormStructure } from 'dd-client/site/common/components/CompetitionForm';
import { WebPushButton } from 'dd-client/site/common/components/WebPushButton';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import './SpecialComponents.scss';

enum htmlAttributeDataType {
  INFO_MESSAGE = 'infoMessage',
  WEB_PUSH_BUTTON = 'webPushButton',
  COMPETITION_FORM = 'competitionForm',
}

const getContentWithSpecialComponents = (content: string): string | JSX.Element | JSX.Element[] => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        switch (domNode.attribs['data-type']) {
          case htmlAttributeDataType.INFO_MESSAGE:
            return (
              <Alert
                className="SpecialComponents-InfoMessage"
                isVisible
                styleType={Alert.StyleType.INFO}
              >
                {domToReact(domNode.children as DOMNode[])}
              </Alert>
            );

          case htmlAttributeDataType.WEB_PUSH_BUTTON:
            return <WebPushButton />;

          case htmlAttributeDataType.COMPETITION_FORM: {
            const competitionHtmlContent = domToReact(domNode.children as DOMNode[]);
            let competitionFormStructure;

            try {
              competitionFormStructure = JSON.parse(competitionHtmlContent as string) as CompetitionFormStructure;
            } catch (e) {
              competitionFormStructure = undefined;
              logger.error('JSON.parse error - competition form', { error: e });
            }

            return competitionFormStructure
              ? <CompetitionForm competitionFormStructure={competitionFormStructure}/>
              : <></>;
          }
        }
      }
    },
  };

  return (
    parse(content, options)
  );
};

export {
  getContentWithSpecialComponents,
};
