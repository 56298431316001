import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Link } from 'dd-client/site/common/components/Link';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Component, Props } from './types';
import './Stickers.scss';

const Stickers: Component = ({
  stickers,
}: Props): ReactElement | null => {
  const rootClassName = classNames(
    'Stickers',
    'container',
  );

  if (stickers.length === 0) {
    return null;
  }

  return (
    <div className={rootClassName}>
      {stickers.map(sticker => (
        <div
          className="Stickers-ImageWrapper"
          key={sticker.id}
        >
          {sticker.url ? (
            <Link
              url={sticker.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt={sticker.label}
                className="Stickers-Image"
                src={`${getConfig('url.cdn')}/${sticker.image}`}
                title={sticker.label}
              />
            </Link>
          ) : (
            <img
              alt={sticker.label}
              className="Stickers-Image"
              src={`${getConfig('url.cdn')}/${sticker.image}`}
              title={sticker.label}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export {
  Stickers,
};
