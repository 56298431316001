import { kebabCase } from 'change-case';
import { Theme } from 'dd-client/site/common/hooks/useThemes';
import { Deal } from 'dd-client/site/deal/hooks/api/types';

const THEME_HTML_STYLE_TAG_ID = 'Theme';
const CSS_VAR_PREFIX = '--dds-';
const QUERY_STRING_PREVIEW_TOKEN = 'previewToken';

const cssVarNameCase = (key: string): string => (
  kebabCase(key).match(/[a-z]+|\d+/ig)?.join('-') || key
);

const convertKeyToCSSVarName = (key: string): string => (
  `${CSS_VAR_PREFIX}${cssVarNameCase(key)}`
);

const mapThemeDataToCSSVars = (
  themeData: Record<string, string>,
): string => (
  Object
    .entries(themeData)
    .map(([key, value]) => `${convertKeyToCSSVarName(key)}: ${value};`)
    .join(' ')
);

const getTheme = (
  themes: Array<Theme> = [],
  activeDeals: Array<Deal> = [],
  isHomePage = false,
  dealSlug?: string,
  categorySlug?: string,
  previewToken?: string,
  currentDealThemeId?: number | null,
): Theme | undefined => {
  if (previewToken) {
    return themes.find(
      theme => (
        theme.previewToken === previewToken
      ),
    );
  }

  let themeId: number | null | undefined;

  if (dealSlug) {
    themeId = activeDeals.find(
      activeDeal => (
        dealSlug === activeDeal.slug
      ),
    )?.themeId || currentDealThemeId;
  } else if (categorySlug) {
    themeId = activeDeals.find(
      activeDeal => (
        categorySlug === activeDeal.category.slug
      ),
    )?.themeId || currentDealThemeId;
  } else if (isHomePage) {
    themeId = activeDeals[0]?.themeId || undefined;
  }

  return themes.find(
    theme => (
      theme.id === themeId
    ),
  );
};

export {
  CSS_VAR_PREFIX,
  cssVarNameCase,
  getTheme,
  mapThemeDataToCSSVars,
  QUERY_STRING_PREVIEW_TOKEN,
  THEME_HTML_STYLE_TAG_ID,
};
