import { Availability } from 'dd-client/site/deal/hooks/api/types';
import { AvailabilityProps } from './types';

const getAvailabilityProps = (availability: Availability): AvailabilityProps => {
  const { availablePercentage, buyable, hasReservedProducts, isSoldOut } = availability;

  return {
    availablePercentage,
    isReserved: !buyable && hasReservedProducts,
    isSoldOut,
  };
};

export { getAvailabilityProps };

